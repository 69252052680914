import Konva from 'konva'
import { iFreeDragState, iItemGroup, iKonvaGroup } from '../interface/FreeDragState.i'

export function useKonvaUtils(state: iFreeDragState) {
  const imagePlaceholderProduct = '/assets/iconset/whiteboard/placeholder-item.svg'
  const imagePlaceholderMedia = '/assets/iconset/whiteboard/placeholder-media.svg'

  const findLayer = (name: string): Konva.Layer => state.stage?.findOne((node) => node.getAttr('name') === name)
  const findElementByName = (layer: Konva.Layer | Konva.Group, name: string) =>
    layer?.findOne((node) => node.getAttr('name') === name)
  const findGroupById = (layer: Konva.Layer | Konva.Group, id: number): Konva.Group =>
    <Konva.Group>layer?.findOne((node) => node instanceof Konva.Group && node.getAttr('prodId') === id)
  const findImageById = (layer: Konva.Layer | Konva.Group, id: string): Konva.Image =>
    <Konva.Image>layer?.findOne((node) => node instanceof Konva.Image && node.getAttr('name') === id)

  const getMainLayer = () => {
    let mainLayer = findLayer('mainLayer')
    if (!mainLayer) {
      mainLayer = new Konva.Layer({ name: 'mainLayer' })
      state.stage?.add(mainLayer)
    }
    return mainLayer
  }

  const getTopLayer = () => {
    let topLayer = findLayer('topLayer')
    if (!topLayer) {
      topLayer = new Konva.Layer({ name: 'topLayer' })
      state.stage?.add(topLayer)
      getSelectedGroup()
      getInfoGroup()
    }
    return topLayer
  }

  const getSelectedGroup = () => {
    const topLayer = findLayer('topLayer')
    let selectedGroup = findElementByName(topLayer, 'selectedGroup') as Konva.Group
    if (!selectedGroup) {
      selectedGroup = new Konva.Group({ name: 'selectedGroup', x: 0, y: 0, type: 'selectedGroup' })
      topLayer.add(selectedGroup)
    }
    return selectedGroup
  }

  const getInfoGroup = () => {
    const topLayer = findLayer('topLayer')
    let infoGroup = findElementByName(topLayer, 'infoGroup') as Konva.Group
    if (!infoGroup) {
      infoGroup = new Konva.Group({ name: 'infoGroup', x: 0, y: 0, type: 'infoGroup' })
      topLayer.add(infoGroup)
      infoGroup.visible(false) // deactivate
    }
    return infoGroup
  }

  const getShapesFromLayers = (layerNames: string[]) => {
    return layerNames.reduce((shapes, layerName) => {
      const layer = findLayer(layerName)
      if (layer) {
        shapes.push(
          ...layer.find(({ attrs }) => ['image-product', 'image-media', 'text', 'information'].includes(attrs.type)),
        )
      }
      return shapes
    }, [] as Konva.Node[])
  }

  const getStageOptions = () => {
    const containerTemplate = document.getElementById('whiteboardContainer')
    const width = containerTemplate?.clientWidth || 1920
    const height = (width * 9) / 16

    const scaleX = state.stage.scaleX()
    const scaleY = state.stage.scaleY()
    const defaultAttrs = state.stageDefaultAttrs

    return { width, height, scaleX, scaleY, defaultAttrs }
  }

  function applyGroupTransformations(element: Konva.Node, group: Konva.Group) {
    // Apply group scaling to the child
    element.scaleX(element.scaleX() * group.scaleX())
    element.scaleY(element.scaleY() * group.scaleY())
    element.rotation(element.rotation() + group.rotation()) // Apply group rotation to the child

    // Calculate the new position considering group scaling, translation and rotation
    const groupRotationInRadians = group.rotation() * (Math.PI / 180)
    const relativeX = element.x() * group.scaleX()
    const relativeY = element.y() * group.scaleY()
    const rotatedX = relativeX * Math.cos(groupRotationInRadians) - relativeY * Math.sin(groupRotationInRadians)
    const rotatedY = relativeX * Math.sin(groupRotationInRadians) + relativeY * Math.cos(groupRotationInRadians)

    element.x(group.x() + rotatedX)
    element.y(group.y() + rotatedY)
    element.listening(true)
  }

  const iconCache = {}
  function loadKonvaImageFromIconSet(name, options) {
    let fileName = name.replace(/^icon-/, '') + '.svg'
    if (fileName.startsWith('feature-')) {
      fileName = fileName.replace(/^feature-/, 'feature/')
    }
    const src = `/assets/iconset/${fileName}` // Using the '@' alias

    // Überprüfen Sie, ob das Bild bereits geladen wurde
    if (iconCache[src]) {
      const clonedImage = iconCache[src].clone()
      clonedImage.setAttrs(options) // Aktualisieren Sie die Optionen des geklonten Bildes
      return clonedImage
    }

    const imgElement = document.createElement('img')
    imgElement.src = src
    const imageOptions = { name, image: imgElement, src, type: 'infoIcon', listening: false, ...options }
    const konvaImage = new Konva.Image(imageOptions)

    // Speichern Sie das Bild im Cache
    imgElement.onload = () => {
      iconCache[src] = konvaImage
    }

    return konvaImage
  }

  function getDefaultInfoItems(information: Konva.Group) {
    const groupBackground = information?.findOne(
      (node) => node.getAttr('groupName') === 'groupBackground',
    ) as Konva.Rect
    const productName = information?.findOne((node) => node.getAttr('groupName') === 'productName') as Konva.Text
    const sku = information?.findOne((node) => node.getAttr('groupName') === 'sku') as Konva.Text
    const color = information?.findOne((node) => node.getAttr('groupName') === 'color') as Konva.Text
    const colorDot = information?.findOne((node) => node.getAttr('groupName') === 'colorDot') as Konva.Rect
    const whs = information?.findOne((node) => node.getAttr('groupName') === 'whs') as Konva.Text
    const rrp = information?.findOne((node) => node.getAttr('groupName') === 'rrp') as Konva.Text

    return { groupBackground, productName, sku, color, colorDot, whs, rrp }
  }

  function updateItemScale(element: iKonvaGroup['item']) {
    const { scaleX, scaleY } = getStageOptions()

    // Scale the size of the element
    element.scaleX(element.scaleX() * scaleX)
    element.scaleY(element.scaleY() * scaleY)
    // Update the item's position
    element.x(element.x() * scaleX)
    element.y(element.y() * scaleY)
  }

  function getItemType(type: 'image-media' | 'image-product' | 'selectedGroup' | 'imageInfoGroup') {
    return {
      isImageMedia: type === 'image-media',
      isImageProduct: type === 'image-product',
      isSelectedGroup: type === 'selectedGroup',
      isImageInfoGroup: type === 'imageInfoGroup',
    }
  }

  function getImageScaleCreate(item: iItemGroup, image: Konva.Image) {
    const { scaleX: stageScaleX, scaleY: stageScaleY } = getStageOptions()
    const { dw: defaultWidth, dh: defaultHeight, scaleX, scaleY } = item.attrs

    return {
      newScaleX: (defaultWidth * scaleX * stageScaleX) / image.width(),
      newScaleY: (defaultHeight * scaleY * stageScaleY) / image.height(),
    }
  }

  function getImageScaleUpdate(item: iItemGroup, image: Konva.Image) {
    const { width, height, dw: defaultWidth, dh: defaultHeight, scaleX, scaleY } = item.attrs

    return {
      newScaleX: ((width || defaultWidth) * (scaleX || 1)) / image.width(),
      newScaleY: ((height || defaultHeight) * (scaleY || 1)) / image.height(),
    }
  }

  function calcNewAttributes(newAttrs) {
    const { width, height } = getStageOptions()
    const baseScaleX = width / newAttrs?.width
    const baseScaleY = height / newAttrs?.height
    const calcScaleX = (scale?: number) => (scale ? baseScaleX * scale : baseScaleX)
    const calcScaleY = (scale?: number) => (scale ? baseScaleY * scale : baseScaleY)

    return {
      newPositionX: (newAttrs?.x || 0) * baseScaleX,
      newPositionY: (newAttrs?.y || 0) * baseScaleY,
      newScaleX: calcScaleX(newAttrs.scaleX),
      newScaleY: calcScaleY(newAttrs.scaleY),
    }
  }

  return {
    imagePlaceholderProduct,
    imagePlaceholderMedia,
    findGroupById,
    findImageById,
    getMainLayer,
    getTopLayer,
    getSelectedGroup,
    getInfoGroup,
    getShapesFromLayers,
    getStageOptions,
    applyGroupTransformations,
    loadKonvaImageFromIconSet,
    getDefaultInfoItems,
    updateItemScale,
    getItemType,
    getImageScaleCreate,
    getImageScaleUpdate,
    calcNewAttributes,
  }
}
