import { defineStore } from 'pinia'

export const useCatalogStore = defineStore('catalog', {
  state: () => ({
    usedIdsMap: {} as Record<string, boolean | number>,
    usedIdsMapOld: {} as Record<string, boolean | number>,
  }),
  actions: {
    update(payload: Record<string, boolean | number>) {
      this.usedIdsMap = payload
    },
    reset() {
      this.usedIdsMap = {}
      this.usedIdsMapOld = {}
    },
    checkEntries() {
      const entries = Object.entries(this.usedIdsMap).map(([id, value]) => {
        const isNew = !(id in this.usedIdsMapOld)
        const isChanged = id in this.usedIdsMapOld && this.usedIdsMapOld[id] !== value
        return { id, value, isNew, isChanged }
      })

      const newEntries = entries.filter((entry) => entry.isNew)
      const changedEntries = entries.filter((entry) => entry.isChanged)

      return { newEntries, changedEntries }
    },
    updateOldEntries() {
      this.usedIdsMapOld = { ...this.usedIdsMap }
    },
  },
  getters: {
    assigned: (state) => {
      return Object.values(state.usedIdsMap).filter((value) => typeof value === 'number').length
    },
    unassigned: (state) => {
      return Object.values(state.usedIdsMap).filter((value) => value === true).length
    },
  },
})
