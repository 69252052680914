<template>
<div class="overlay-content place-center absolute inset-0" :style="styles">
  <slot></slot>
</div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { hexToRgb } from '@/plugins/generalColors'

const props = defineProps({
  color: {
    type: String,
    default: '#000000'
  },
  opacity: {
    type: Number,
    default: 0.1
  }
})

const styles = computed(() => {
  return {
    '--overlay-color': hexToRgb(props.color),
    '--overlay-opacity': props.opacity
  }
})
</script>

<style scoped lang="scss">
.overlay-content {
  background: rgba(var(--overlay-color), var(--overlay-opacity));
}
</style>
