import { ref, toRef } from 'vue'
import getGridLayouts from '@/components/grid/helpers/showcaseGridLayers'

export function useShowcaseLayout(images: string[]) {
  const maxGridItemsLength = 40
  const arrItems = ref<(null | { id: number; path: string; delay: string })[]>([])
  const arrImages = toRef(images)

  const activeLayoutId = ref<number | undefined>(undefined)
  const activeLayout = ref()
  const gridImagesLayout = getGridLayouts()

  function loadLayout() {
    if (!activeLayoutId.value) {
      activeLayoutId.value = 1
    } else {
      activeLayoutId.value =
        activeLayoutId.value + 1 > Object.keys(gridImagesLayout).length ? 1 : activeLayoutId.value + 1
    }

    activeLayout.value = gridImagesLayout[activeLayoutId.value]

    // + 1 because we start our index from 1 not 0, later we remove the first
    const emptyArray = new Array(maxGridItemsLength + 1).fill(null)
    const shuffledMoodImages = arrImages.value.sort(() => 0.5 - Math.random())
    activeLayout.value.forEach((index: number, i: number) => {
      if (index <= maxGridItemsLength) {
        emptyArray[index] = { ...{ path: shuffledMoodImages[i] }, ...{ delay: `${(i + 1) * 100}ms` } }
      }
    })

    // 12 images = 1.2s delay, 0.8s animation,
    emptyArray.shift()
    arrItems.value = [...emptyArray]
    // clear array to be empty, so images get slide out
    setTimeout(() => {
      arrItems.value = new Array(maxGridItemsLength).fill(null)
    }, 6000)
  }

  function clearTimeOuts() {}

  return {
    arrItems,
    loadLayout,
    clearTimeOuts,
  }
}
