import Konva from 'konva'
import { iFreeDragState } from '../interface/FreeDragState.i'
import { useKonvaUtils } from './useKonvaUtils'
import pako from 'pako'

export function useKonvaUpdate(state: iFreeDragState) {
  const { calcNewAttributes } = useKonvaUtils(state)

  function updateCanvasBoardScale(stageCopy?: Konva.Stage): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const whiteboardContainer = document.getElementById('whiteboardContainer')
        const currentStage = stageCopy || state.stage
        if (!whiteboardContainer || !currentStage) return resolve()

        const { newPositionX, newPositionY, newScaleX, newScaleY } = calcNewAttributes(currentStage.attrs)

        const containerWidth = whiteboardContainer.clientWidth
        const containerHeight = whiteboardContainer.clientHeight
        const originalWidth = currentStage.width()
        const originalHeight = currentStage.height()

        const isZoomMode = newScaleX > 1
        const scaleX = isZoomMode ? newScaleX : containerWidth / originalWidth
        const scaleY = isZoomMode ? newScaleY : containerHeight / originalHeight
        const posX = isZoomMode ? newPositionX : currentStage.x()
        const posY = isZoomMode ? newPositionY : currentStage.y()

        state.stage.scale({ x: scaleX, y: scaleY })
        state.stage.width(containerWidth)
        state.stage.height(containerHeight)
        state.stage.x(posX)
        state.stage.y(posY)

        state.stage.batchDraw()

        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  function updateWhiteboardConfig(json: string, isAnimate: boolean = true): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (state.isAborted || !state.stage || state.isLoading) {
          return resolve()
        }

        const decompressedStageJson = typeof json === 'string' ? json : pako.inflate(json, { to: 'string' })
        const newStageAttrs = JSON.parse(decompressedStageJson)?.attrs
        if (!newStageAttrs) {
          return resolve()
        }

        state.jsonString = JSON.stringify({ ...JSON.parse(state.jsonString), attrs: newStageAttrs })

        const { newPositionX, newPositionY, newScaleX, newScaleY } = calcNewAttributes(newStageAttrs)

        let currentPos = state.stage.position()
        let currentScale = state.stage.scale()

        const imageProducts = state.stage.find((shape) => shape.attrs.type === 'image-product') as Konva.Image[]
        const infoProducts = state.stage.find((shape) => shape.attrs.type === 'information') as Konva.Group[]
        const elementCount = imageProducts.length + infoProducts.length
        const frames = elementCount < 300 ? 20 : 10

        const dx = (newPositionX - currentPos.x) / frames
        const dy = (newPositionY - currentPos.y) / frames
        const dsx = (newScaleX - currentScale.x) / frames
        const dsy = (newScaleY - currentScale.y) / frames

        state.stage.attrs = newStageAttrs

        function animateChangePosition() {
          currentPos = { x: currentPos.x + dx, y: currentPos.y + dy }
          currentScale = { x: currentScale.x + dsx, y: currentScale.y + dsy }

          if (
            Math.abs(newPositionX - currentPos.x) < 1 &&
            Math.abs(newPositionY - currentPos.y) < 1 &&
            Math.abs(newScaleX - currentScale.x) < 0.01 &&
            Math.abs(newScaleY - currentScale.y) < 0.01
          ) {
            currentPos = { x: newPositionX, y: newPositionY }
            currentScale = { x: newScaleX, y: newScaleY }
            state.stage.position(currentPos)
            state.stage.scale(currentScale)

            resolve()
          } else {
            state.stage.position(currentPos)
            state.stage.scale(currentScale)
            requestAnimationFrame(animateChangePosition)
          }
        }

        if (isAnimate) {
          animateChangePosition()
        } else {
          state.stage.position({ x: newPositionX, y: newPositionY })
          state.stage.scale({ x: newScaleX, y: newScaleY })
          resolve()
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  function updateShapeAttributes(existingShape, newShape) {
    if (!existingShape?.attrs && !newShape) return
    existingShape.attrs.rotation = newShape.attrs?.rotation
    existingShape.attrs.width = newShape.attrs?.width
    existingShape.attrs.height = newShape.attrs?.height
    existingShape.attrs.scaleX = newShape.attrs?.scaleX
    existingShape.attrs.scaleY = newShape.attrs?.scaleY
  }

  return {
    updateCanvasBoardScale,
    updateWhiteboardConfig,
    updateShapeAttributes,
  }
}
