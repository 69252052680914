<template>
  <div class="catalog flex h-100">
    <div class="left-side overflow-hidden flex-fill">
      <div ref="catalogContainerRef" class="h-100">
        <Transition name="fade-scroll" mode="out-in" appear>
          <div v-if="isRecycleScrollerActive" class="scroll-overlay">
            <div class="scroll-overlay-header"></div>
            <div class="scroll-overlay-footer"></div>
          </div>
        </Transition>
        <div v-if="itemRows.length" class="product--catalogue" :style="`--row-size: ${rowSize}`" :data-grid="zoom">
          <Transition :name="initLoad ? 'catalog-slide-up' : 'fade-component'" mode="out-in" appear>
            <RecycleScroller
              :key="viewId + String(viewportWidth)"
              v-slot="{ item, active }"
              ref="recycleScroller"
              class="product--grid scroll-container overflow-scroll-y"
              :items="itemRows"
              :buffer="800"
              :item-size="itemSize"
              key-field="id"
              @visible="scrollerMounted"
            >
              <div v-if="active" :key="item.id" class="itemRowWrapper" :class="zoom" :data-type="zoom">
                <div
                  v-for="_item in item.items"
                  :key="_item.id"
                  class="grid--item border-transparent"
                  :class="itemClass(_item)"
                  :data-item-id="_item.id"
                >
                  <ProductTile
                    :id="_item.id"
                    :size="productSize"
                    :image-src="getImagePerspective(_item)"
                    :title="_item.name"
                    :subtitle="_item.sku"
                    :whs="_item.whs"
                    :rrp="_item.rrp"
                    :tags="_item.tags"
                    :colors="_item.colors"
                    :show-counter="isViewActive && showCounter(_item.id)"
                    :counter="getCounter(_item.id)"
                    :is-selected="checkItemIsSelected(_item.id)"
                  />
                </div>
              </div>
            </RecycleScroller>
          </Transition>
        </div>
        <LayoutEmpty v-else :text="textEmpty" />
      </div>
    </div>

    <div id="right-side" class="right-side scroll-container overflow-scroll-y h-100">
      <!-- sidebar-group -->
      <TransitionGroup name="switch-component">
        <div v-if="!pinnedData" class="sidebar-group flex flex-col g-3">
          <CardGroup
            v-for="(item, idx) in catalogSidebar.items"
            :key="item.groupId || idx"
            class="sidebar-group-card"
            :title="item.title"
            :count="item.count"
            :qty="item.quantity"
            :whs="item.whs_total"
            :assigned="catalogStore.assigned"
            :unassigned="catalogStore.unassigned"
            :type="item.groupId ? 'group' : 'preselection'"
            :is-active="isGroupActive(item, idx)"
            :gallery="item.gallery"
          />
        </div>
        <!-- sidebar-list -->
        <ProductPinned
          v-else
          :title="pinnedData.title"
          :items="pinnedData.items"
          :used-ids-in-group="pinnedData.usedIdsInGroup"
          :is-scroll-active="isSidebarScrollerActive"
        />
      </TransitionGroup>
    </div>

    <TransitionGroup name="slide-up">
      <SelectedFilter
        v-if="selectedFilterData.isVisible"
        class="catalog-selected-filter"
        :filters="selectedFilterData.filters"
      />
      <SelectedCounter v-if="selections.length" class="catalog-selected-counter" :counter="selections.length" />
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
  import CardGroup from '@/components/card/CardGroup.vue'
  import ProductPinned from '@/components/content/product/ProductPinned.vue'
  import SelectedCounter from '@/components/feedback/selected/SelectedCounter.vue'
  import SelectedFilter from '@/components/feedback/selected/SelectedFilter.vue'
  import LayoutEmpty from '@/components/layout/state/LayoutState.vue'
  import { useProductCounter } from '@/composables/product/useProductCounter'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { t } from '@/utils/helpers'
  import { useCatalogMessages } from '@/views/catalog-overview/composables/useCatalogMessages'
  import { useCatalogProductImage } from '@/views/catalog-overview/composables/useCatalogProductImage'
  import { useElementSize } from '@vueuse/core'
  import { onMounted, defineProps, ref, computed, onUnmounted, watch } from 'vue'
  import ProductTile from '@/components/content/product/ProductTile.vue'
  import { useCatalogStore } from '@/stores/catalog'
  import {
    CatalogProduct,
    CatalogProductMessage,
    CatalogSidebarItem,
    CatalogZoomType,
  } from '@/views/catalog-overview/interface/CatalogOverview.interface'
  import { useCatalogProductClass } from './composables/useCatalogProductClass'

  const props = defineProps<{ message: CatalogProductMessage; actionMessage?: string }>()

  const catalogStore = useCatalogStore()
  const catalogContainerRef = ref<HTMLElement | null>(null)

  const { width: viewportWidth } = useElementSize(catalogContainerRef)
  const { itemClass } = useCatalogProductClass(props)
  const { showCounter, getCounter } = useProductCounter()
  const { perspective, getImagePerspective } = useCatalogProductImage(props)

  const initLoad = ref(true)
  const catalogSidebar = ref(props.message.content.sidebar)
  const pinnedData = ref()

  const viewId = computed(() => props.message.content?.viewId)
  const items = ref<CatalogProduct[]>(props.message.content?.items)
  const zoom = computed<CatalogZoomType>(() => {
    const zoomDefault = 'medium'
    const zoomTypes = ['small', 'medium', 'large', 'xlarge', 'single']
    const hasSelected = zoomTypes.includes(props.message.state?.zoom)
    return hasSelected ? props.message.state?.zoom : zoomDefault
  })
  const textEmpty = computed(() => {
    const { sidebar, search } = props.message.content

    if (!itemRows.value.length && search) {
      return t('no_results.search')
    }

    switch (sidebar.active.route) {
      case 'whiteboard.index':
        return t('no_results.items')
      case 'whiteboard.preselection':
        return t('no_results.preselection')
      default:
        return t('no_results.group')
    }
  })

  const {
    screenID,
    onMessage,
    scrollerMounted,
    recycleScroller,
    selections,
    checkItemIsSelected,
    rowSize,
    itemRows,
    itemSize,
    productSize,
    isRecycleScrollerActive,
    isSidebarScrollerActive,
    selectedFilterData,
  } = useCatalogMessages(props, items, viewportWidth, zoom, perspective, catalogSidebar, pinnedData)

  const isViewActive = computed(() => ['index', 'preselection'].includes(`${viewId.value}`))
  function isGroupActive(item: CatalogSidebarItem, index: number) {
    const { route } = catalogSidebar.value.active

    switch (route) {
      case 'whiteboard.index':
        return false
      case 'whiteboard.preselection':
        return index === 0
      default:
        return viewId.value === item.groupId
    }
  }

  function updateRecycleScroller(newValue: CatalogProduct[]) {
    const isItemsEqual = JSON.stringify(newValue) === JSON.stringify(items.value)
    if (isItemsEqual) return
    items.value = []
    setTimeout(() => (items.value = newValue), 10)
  }

  watch(() => props.message.content?.items, updateRecycleScroller)
  watch(() => props.message.content?.scrollPosition, scrollerMounted)

  onMounted(() => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })
    catalogStore.update(props.message.content?.usedId)
    initLoad.value = false
  })

  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID, onMessage })
  })
</script>

<style lang="scss" scoped>
  .catalog {
    aspect-ratio: 16/9;
    background-color: $white;

    .left-side {
      width: get-vw(1480);
      height: auto;
      position: relative;

      .scroll-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;

        .scroll-overlay-header,
        .scroll-overlay-footer {
          position: absolute;
          left: 0;
          right: 0;
          height: get-vw(60);
          width: 100%;
          pointer-events: none;
        }

        .scroll-overlay-header {
          top: 0;
          background: $gradient-lighten-down-80;
        }

        .scroll-overlay-footer {
          bottom: 0;
          background: $gradient-lighten-up-80;
        }
      }

      // old
      :deep(.resize-observer) {
        position: absolute;
      }

      [data-grid='small'] {
        --grid-item-aspect: 23/35;
      }
      [data-grid='medium'] {
        --grid-item-aspect: 13/18;
      }
      [data-grid='large'] {
        --grid-item-aspect: 472/677;
      }
      [data-grid='xlarge'] {
        --grid-item-aspect: 714/947;
      }
      [data-grid='single'] {
        --grid-item-aspect: 288/209;
      }

      .product--catalogue {
        --color-border-color: $gray-100;
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0 get-vw($g-5);

        .product-tile {
          width: 100%;
          height: 100%;
        }
        .product--grid {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          position: relative;
          padding: get-vw($g-5) 0;

          :deep(.vue-recycle-scroller__item-wrapper) {
            width: 100%;

            .vue-recycle-scroller__item-view {
              position: absolute;
              width: 100%;
            }
          }

          .itemRowWrapper {
            gap: get-vw($g-3);
            display: grid;
            grid-template-columns: repeat(var(--row-size), 1fr);
          }
        }
      }

      .grid--item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        border-radius: $border-sm;
        background-color: var(--grid-item-bg, $white);
        aspect-ratio: var(--grid-item-aspect);
        overflow: hidden;

        &.in_use {
          &.grayscale {
            .product--image {
              filter: grayscale(1);
              opacity: 0.2;
            }
          }
        }
      }
    }

    .right-side {
      width: get-vw(440);
      background: $gray-100;

      .sidebar-group {
        padding: get-vw($g-5);
      }
    }

    .catalog-selected-filter {
      position: absolute;
      bottom: get-vw($g-8);
      left: get-vw($g-8);
    }
    .catalog-selected-counter {
      position: absolute;
      bottom: get-vw($g-5);
      right: get-vw($g-5);
    }
  }
</style>
