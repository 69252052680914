<template>
  <div class="visual-card" :class="sizeClass">
    <ImageLoader :src="buildAssetPath({ src: imageSrc, thumb: 800 })" />
    <div class="overlay" />
    <div v-if="tag" class="header flex flex-col align-end align-content-center">
      <ChipTag class="header-tag" :size="size" color="white">{{ tag }}</ChipTag>
    </div>
    <div class="bottom flex">
      <div v-if="title" class="title text-truncate-3">{{ title }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import ImageLoader from '@/components/media/ImageLoader.vue'
  import ChipTag from '@/components/partials/chip/ChipTag.vue'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      size?: 'default' | 'medium'
      title: string
      imageSrc: string
      tag?: string
    }>(),
    {
      size: 'default',
      tag: '',
    },
  )

  const sizeClass = computed(() => `size-${props.size}`)
</script>

<style lang="scss" scoped>
  .visual-card {
    position: relative;
    overflow: hidden;
    opacity: 1;
    transition: all 0.5s ease-in-out;

    &::before {
      content: '';
      display: block;
      padding-top: 135%;
    }

    .header {
      position: absolute;
      top: 0;
      width: 100%;
      padding: get-vw($g-3);
      gap: get-vw($g-0);

      .header-tag {
        max-width: 60%;
      }
    }

    .bottom {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: flex-end;
      color: white;

      .title {
        color: $white;
        backface-visibility: hidden;
      }
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease-in-out;
    }

    .overlay {
      position: absolute;
      top: -1%;
      left: -1%;
      width: 102%;
      height: 102%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    }

    // sizes
    &.size-default {
      border-radius: get-vw($r-5);
      .title {
        @include text-body($heading-1, $font-weight-600);
      }
      .bottom {
        padding: get-vw($g-6);
      }
    }
    &.size-medium {
      border-radius: get-vw($r-6);
      .title {
        @include text-body($heading-3, $font-weight-600);
      }
      .bottom {
        padding: get-vw($g-8);
      }
    }
  }
</style>
