import { createApp } from 'vue'
import { registerPlugins } from '@/plugins'
import App from './App.vue'
import { loadConfig } from './utils/main/loadConfig'
import { setupAppErrorHandling } from './utils/main/appErrorHandling'

const app = createApp(App)

registerPlugins(app)
setupAppErrorHandling(app)

loadConfig().then(() => app.mount('#app'))
