<template>
  <div>
    <TransitionGroup
      v-if="!isChanging"
      tag="div"
      class="related-grid d-grid flex pt-8 pr-20 pb-20 pl-20"
      name="list-slide-right"
      appear
    >
      <ProductTile
        v-for="(product, index) in products"
        :id="product.id"
        :key="product.id"
        :image-src="product.image"
        :tags="product.tags"
        :show-counter="showCounter(product.id)"
        :counter="getCounter(product.id)"
        :color="product.color"
        :title="!isTypeColor ? product.name : ''"
        :subtitle="!isTypeColor ? product.sku : ''"
        :rrp="!isTypeColor ? product.rrp : 0"
        :whs="!isTypeColor ? product.whs : 0"
        :style="{ transitionDelay: index < 9 ? `${index * 100}ms` : '0ms' }"
      ></ProductTile>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import ProductTile from '@/components/content/product/ProductTile.vue'
  import { useProductCounter } from '@/composables/product/useProductCounter'

  const props = defineProps({
    scrollPercent: {
      type: Number,
      default: undefined,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  })

  const { showCounter, getCounter } = useProductCounter()

  const isChanging = ref(false)
  const activeTab = computed(() => props.data?.activeTab)
  const isTypeColor = computed(() => props.data?.tabName === 'color')

  watch(activeTab, () => {
    isChanging.value = true
    setTimeout(() => (isChanging.value = false), 200)
  })
  const products = computed(() => props.data?.items)
</script>

<style scoped lang="scss">
  .related-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: get-vw($g-4);

    :deep(.product-tile) {
      width: 100%;
      height: auto;

      .product-image {
        aspect-ratio: 1/1;
      }
    }
  }
</style>
