<template>
  <Transition :name="transition" mode="out-in" appear>
    <div
      v-if="imageState === 'loaded' && asyncImage?.src"
      :key="asyncImage?.src"
      class="bg--image"
      :class="[imageClass, imageState]"
      :style="computedStyle"
      :data-width="imageWidth"
      :data-height="imageHeight"
      :data-state="imageState"
    />
  </Transition>
</template>

<script lang="ts" setup>
  import { defineProps, computed, ref, defineEmits, onMounted } from 'vue'

  onMounted(() => fetchImage())

  const emit = defineEmits(['image-loaded'])
  const props = withDefaults(
    defineProps<{
      src: string
      imageClass?: string
      transition?: string
      backgroundSize?: string
    }>(),
    {
      src: '',
      imageClass: '',
      transition: 'fade',
      backgroundSize: 'cover',
    },
  )

  const imageWidth = ref(0)
  const imageHeight = ref(0)
  const imageState = ref<'loading' | 'loaded' | 'error'>('loading')
  const asyncImage = ref<HTMLImageElement | null>(null)

  const computedStyle = computed(() => ({
    backgroundImage: imageState.value === 'loaded' ? `url(${asyncImage.value?.src})` : '',
    backgroundSize: props.backgroundSize,
  }))

  function fetchImage() {
    if (!props.src) {
      imageState.value = 'error'
      return
    }

    const img = new Image()
    img.onload = imageOnLoad
    img.onerror = imageOnError as OnErrorEventHandler
    img.src = props.src
    imageState.value = 'loading'
    asyncImage.value = img
  }

  function imageOnLoad(this: GlobalEventHandlers) {
    if (asyncImage.value) {
      imageState.value = 'loaded'
      imageWidth.value = asyncImage.value.naturalWidth
      imageHeight.value = asyncImage.value.naturalHeight
      emit('image-loaded')
    }
  }

  function imageOnError(this: GlobalEventHandlers, event: Event | string) {
    console.error('Failed to load image:', props.src, event)
    imageState.value = 'error'
  }
</script>

<style scoped lang="scss">
  .bg--image {
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    inset: 0;
  }
  // Transition
  .fade {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.5s;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
    &-up-enter-active,
    &-up-leave-active {
      transition: all 0.5s ease-in-out;
    }
    &-up-enter-from,
    &-up-leave-to {
      opacity: 0;
      transform: rotate(20deg) translateY(200px);
    }
  }
</style>
