<template>
  <div class="statistic-item rounded-4 overflow-hidden bg-gray-700 p-1 flex-shrink-0">
    <StatisticHeader
      :title="props.data?.category"
      :text="`${props.data?.percentage}%`"
      :color="props.data?.defaultColor ? '#444444' : props.data?.color"
    ></StatisticHeader>
    <div class="statistic-footer p-3 d-flex g-6 bg-gray-800 rounded-b-4">
      <div class="icon-placeholder"></div>
      <div class="footer-content d-flex flex-fill g-6 color-white">
        <StatisticItemStats :title="props.data?.items" :subtitle="$t('common.item')"></StatisticItemStats>
        <StatisticItemStats :title="props.data?.amount" :subtitle="$t('common.quantity')"></StatisticItemStats>
        <StatisticItemStats :title="priceFormatter(props.data?.whs)" :subtitle="$t('common.whs')"></StatisticItemStats>
        <StatisticItemStats :title="priceFormatter(props.data?.rrp)" :subtitle="$t('common.rrp')"></StatisticItemStats>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import StatisticHeader from '@/components/content/statistic/StatisticHeader.vue'
  import StatisticItemStats from '@/components/content/statistic/StatisticItemStats.vue'
  import { StatisticDataSetItem } from '@/views/statistic-overview/StatisticOverview.interface'
  import { priceFormatter } from '@/utils/helpers'

  const props = defineProps<{ data: StatisticDataSetItem }>()
</script>

<style scoped lang="scss">
  .icon-placeholder {
    min-width: get-vw(36);
    flex-shrink: 0;
  }
  :deep(.footer-content) .statistic-stats:nth-of-type(-n + 2) {
    max-width: 20%;
  }
</style>
