import { defineStore } from 'pinia'

export const useIntroStore = defineStore('intro', {
  state: () => ({
    lastUpdate: '',
    items: [],
  }),
  actions: {
    setLastUpdate(lastUpdate?: string) {
      this.lastUpdate = lastUpdate || new Date().toISOString()
    },
    setItems(payload) {
      this.items = payload
    },
  },
})
