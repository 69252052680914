// Imports
import { TweenMax } from 'gsap'
import { Mesh, Clock, PlaneGeometry, ShaderMaterial, Vector3 } from 'three'
import vertexShader from '../glsl/vertex.glsl'
import fragmentShader from '../glsl/fragment.glsl'

// Item Setup
export default class extends Mesh {
  constructor({ cover, environment, item, index, infoBox, sceneWidth, modifier }) {
    super()

    this.clock = new Clock()

    this.index = index
    this.environment = environment
    this.originalEnvironment = {
      width: environment.width,
      height: environment.height,
    }
    this.item = item
    this.infoBox = infoBox
    this.sceneWidth = sceneWidth

    this.modifier = modifier

    // Set Position of Items
    this.location = environment.width * this.modifier * index
    this.position.x = this.location

    this.createGeometry(environment)
    this.createMaterial(cover)
  }

  // *************** Create Plane *************** //

  createGeometry({ width }) {
    this.height = width * this.modifier
    this.width = width * this.modifier

    this.geometry = new PlaneGeometry(this.width, this.height, 1, 1)
    this.position.z = 1
  }

  // *************** Create Material *************** //

  createMaterial(cover) {
    this.material = new ShaderMaterial({
      vertexShader,
      fragmentShader,
      depthTest: false,
      depthWrite: false,
      transparent: true,
      wireframe: false,
      uniforms: {
        distance: { value: 0 },
        iChannel0: { value: cover },
        iResolution: {
          value: new Vector3(this.environment.width * 4, this.environment.height * 4, 1),
        },
      },
    })
  }

  // Update Position
  update(scroll) {
    this.position.x = this.location + scroll
    const abs = Math.abs(0 - this.position.x) / 2 > 30 ? 30 : Math.abs(0 - this.position.x) / 2
    this.position.z = -abs * 1.5
    this.material.uniforms.distance.value = abs
    if (!this.infoBox) return

    TweenMax.set(this.infoBox, {
      x: this.position.x,
      opacity: 1 - (abs * 0.1) / 3,
    })
  }
  updateSceneWidth(width) {
    this.sceneWidth = width
  }
}
