import buildAssetPath from '@/utils/buildAssetsPath'
import { ref, computed } from 'vue'

export default () => {
  const audio = ref<any>()
  const isMuted = ref(false)
  const volume = ref(0)

  const internVolume = computed(() => volume.value)

  const calcVolume = (v) => (v > 0 ? v / 100 : 0)
  const setVolume = (v) => (audio.value.volume = calcVolume(v))

  const setMute = (args) => {
    if (audio.value) {
      audio.value.muted = args.isMuted
    }
  }

  const fadeSoundVolume = (delay = 300) => {
    if (audio.value) {
      const steps = 10

      const fadeInterval = setInterval(() => {
        audio.value.volume = Math.max(audio.value.volume - 1 / steps, 0)
        if (audio.value.volume <= 0) {
          audio.value.pause()
          audio.value.currentTime = 0
          clearInterval(fadeInterval)
        }
      }, delay / steps)
    }
  }

  const stopSound = () => {
    fadeSoundVolume()
  }

  const playSound = (filename?: string) => {
    if (!filename) return
    audio.value = new Audio(buildAssetPath({ src: filename }))
    audio.value.muted = isMuted.value
    setVolume(internVolume.value)
    if (false === isMuted.value) {
      audio.value.play()
    }
  }

  return {
    audio,
    isMuted,
    volume,
    setVolume,
    setMute,
    fadeSoundVolume,
    stopSound,
    playSound,
  }
}
