<template>
  <div
    ref="whiteboardRef"
    class="whiteboard scroll-container overflow-scroll-y h-100"
    :class="[{ dense: isDense }, screenLayout]"
  >
    <LayoutEmpty v-if="!showLoading && !groupsData.length" :text="$t('no_results.items')" />
    <div v-for="group in groupsData" :key="group.pos" data-grid="small" class="whiteboard--group">
      <TransitionGroup name="catalog-slide-up" appear>
        <HeadlineMain key="header" class="whiteboard-header" :class="{ dense: isDense }">
          {{ group.name }}
          <template v-slot:label>
            {{ $t('common.items_n', { count: group?.data?.length }) }}
          </template>
        </HeadlineMain>
        <div v-if="group?.data?.length" key="items" class="group--items" :style="`--row-size: ${rowSize}`">
          <div
            v-for="(item, j) in groupItems(group)"
            :key="item.id + '_' + (j || '0')"
            class="whiteboard-item-wrapper"
            :class="{ 'is-fake': item.fake }"
            :data-item-id="item.id"
          >
            <ProductTile
              :id="item.id"
              class="whiteboard-item"
              :size="productSize"
              :image-src="getImagePerspective(item)"
              :title="item.name"
              :subtitle="item.sku"
              :whs="item.whs"
              :rrp="item.rrp"
              :tags="item.tags"
              :colors="item.colors"
              :show-info="hasInfo"
            />
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import HeadlineMain from '@/components/content/headline/HeadlineMain.vue'
  import ProductTile from '@/components/content/product/ProductTile.vue'
  import LayoutEmpty from '@/components/layout/state/LayoutState.vue'
  import useProductDetailSocket from '@/composables/useProductDetailSocket'
  import { EmitMessage } from '@/interface/WebSocket'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { handleJumpToPercent, handleScrollPercent, handleScrollToVirtualScrollItem } from '@/utils/helpers'
  import { useCatalogProductImage } from '@/views/catalog-overview/composables/useCatalogProductImage'
  import { useCatalogProductSize } from '@/views/catalog-overview/composables/useCatalogProductSize'
  import { CatalogProduct } from '@/views/catalog-overview/interface/CatalogOverview.interface'
  import { WhiteboardGroup, WhiteboardMessage } from '@/views/catalog-overview/interface/CatalogOverviewAll.interface'
  import { defineProps, computed, onMounted, onUnmounted, ref, watchEffect } from 'vue'

  const screenID = 'Whiteboard'
  const props = defineProps<{ message: WhiteboardMessage; actionMessage?: string }>()

  const whiteboardRef = ref()
  const zoom = computed(() => props.message.state?.zoom || 'whiteboard')
  const screenLayout = computed(() => props.message.state?.layout || 'landscape')
  const isDense = computed(() => !!props.message.state?.isDense)
  const hasInfo = computed(() => !!props.message.state?.hasInfo)

  const { rowSize, productSize } = useCatalogProductSize(zoom)
  const { getImagePerspective } = useCatalogProductImage(props)
  const { setProductDetailData, closeProductDetail } = useProductDetailSocket()

  const showLoading = ref(false)
  const groupsData = ref<WhiteboardGroup[]>([])

  let timeoutTimer
  watchEffect(() => {
    const groups = props.message.content?.groups
    if (!groups.length) return

    showLoading.value = true
    groupsData.value = groups
    handleJumpToPercent(whiteboardRef.value, props.message.state?.scrollPosition)

    timeoutTimer = setTimeout(() => {
      clearTimeout(timeoutTimer)
      showLoading.value = false
    }, 500)
  })

  function groupItems(group: WhiteboardGroup) {
    const fakeItem = { id: 123456789, fake: true } as CatalogProduct
    return group.data.length > 0 ? group.data : [fakeItem]
  }

  function scrollToItemId(args) {
    document.querySelector(`.whiteboard-item-wrapper[data-item-id="${args.itemId}"`)?.scrollIntoView()
  }

  const scrollToVirtualScrollItem = (newScrollPosition: number) => {
    const header = document.querySelector('.whiteboard-header')
    const item = document.querySelector('.whiteboard-item-wrapper')
    if (!header || !item) return
    const headerHeight = header.clientHeight
    const scrollHeight = whiteboardRef.value.scrollHeight
    const newScrollTop = newScrollPosition * (scrollHeight - headerHeight) + headerHeight
    handleScrollToVirtualScrollItem(whiteboardRef.value, newScrollTop)
  }

  function beforeLeave(el) {
    const { paddingLeft, paddingTop, width, height } = window.getComputedStyle(el)
    el.style.left = `${el.offsetLeft + parseFloat(paddingLeft)}px`
    el.style.top = `${el.offsetTop + parseFloat(paddingTop)}px`
    el.style.width = width
    el.style.height = height
  }

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      beforeLeave: () => beforeLeave(args),
      scrollPercent: () => handleScrollPercent(whiteboardRef.value, args),
      jumpPercent: () => handleJumpToPercent(whiteboardRef.value, args),
      scrollToItemId: () => scrollToItemId(args),
      scrollToBottom: () => (whiteboardRef.value.scrollTop = whiteboardRef.value.scrollHeight),
      scrollToVirtualScrollItem: () => scrollToVirtualScrollItem(args),
      setProductDetailData: () => setProductDetailData(args),
      closeProductDetail: () => closeProductDetail(),
    }
    actions[method]?.()
  }

  onMounted(() => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })
    setTimeout(() => scrollToVirtualScrollItem(props.message.state?.scrollPosition), 100)
  })
  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID, onMessage })
    clearTimeout(timeoutTimer)
  })
</script>

<style scoped lang="scss">
  .whiteboard {
    background: $white;
    &.landscape {
      padding: get-vw($g-6) get-vw($g-8);
    }

    &.portrait {
      padding: 3vh 3vh 0;
    }

    .whiteboard--group {
      display: flex;
      flex-direction: column;
    }

    .group--items {
      display: grid;
      grid-template-columns: repeat(var(--row-size), minmax(0, 1fr));
      gap: get-vw($g-3);
      scroll-behavior: smooth;
    }

    .whiteboard-item-wrapper {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background-color: var(--grid-item-bg);

      .whiteboard-item {
        width: 100%;
      }

      &.is-fake {
        opacity: 0 !important;

        img {
          width: 100%;
          padding-bottom: 100%;
        }
      }

      img {
        max-width: 100%;
      }
    }

    &.dense {
      .whiteboard-item-wrapper {
        width: calc(100% / 10);
      }

      .item--info {
        .item_name {
          //font-size: 1.8vh;
          font-size: 1.6vh;
          font-weight: 600;
        }

        .item_price {
          font-weight: 200;
          margin-top: 0.5vh;
          letter-spacing: 0;
          font-size: 1.2vh;
        }
      }
    }

    &.portrait {
      .whiteboard-item-wrapper {
        width: calc(100% / 5);

        img {
          max-height: 100%;
          min-height: 100%;
        }
      }

      &.dense {
        width: calc(100% / 6);
      }
    }
  }

  .whiteboard-item-wrapper {
    transition: all 1s;
  }

  .slide-y-transition-enter-active,
  .slide-y-transition-leave-active {
    transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  }

  .slide-y-transition-leave-active {
    position: absolute;
  }

  .slide-y-transition-move {
    transition: transform 1s;
  }

  .slide-y-transition-enter-from {
    opacity: 0;
    transform: translateY(5vw);
  }

  .slide-y-transition-leave-to {
    transform: translateY(-5vw);
    opacity: 0;
  }

  .list-complete-enter-from,
  .list-complete-leave-to {
    opacity: 0;
    transform: translateY(3vh);
  }

  .list-complete-move {
    transition: transform 1s;
  }
</style>
