<template>
  <div :class="classes" :style="styles"></div>
</template>
<script setup lang="ts">
  import { useViewportCalculations } from '@/composables/useViewport'
  import { computed } from 'vue'

  const props = defineProps({
    size: {
      type: Number,
      default: 40,
    },
    border: {
      type: Number,
      default: 4,
    },
    color: {
      type: String,
      default: 'white',
    },
  })

  const { pxToVW } = useViewportCalculations()

  const classes = computed(() => ({ 'loader-spinner': true }))
  const styles = computed(() => ({
    '--spinner-width': pxToVW(props.size) + 'vw',
    '--spinner-height': pxToVW(props.size) + 'vw',
    '--spinner-border-width': pxToVW(props.border) + 'vw',
    borderColor: `${props.color} ${props.color} ${props.color} transparent`,
  }))
</script>

<style scoped lang="scss">
  .loader-spinner {
    display: inline-block;
    border-style: solid;
    border-radius: 50%;
    border-top-color: transparent;
    border-right-color: transparent;
    animation: spin 1s linear infinite;
    width: var(--spinner-width);
    height: var(--spinner-height);
    border-width: var(--spinner-border-width);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
