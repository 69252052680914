<template>
  <LayoutTextGradient type="light" :text="bgText" :font-size="bgFontSize">
    <Transition mode="out-in" name="list-slide-right" appear>
      <div class="container" :class="sizeClass">
        <Transition mode="out-in" name="fade-bg" appear>
          <div v-if="currentImage" class="image" :class="imageTypeClass">
            <div class="image-1">
              <ImageLoaderBg :key="currentImage" :src="currentImage" background-size="cover" :transition="transition" />
            </div>
            <div class="image-2">
              <ImageLoaderBg :key="currentImage" :src="currentImage" background-size="cover" :transition="transition" />
            </div>
          </div>
        </Transition>
        <div class="text">{{ text }}</div>
        <LoaderSpinner v-if="isLoading" color="black" :size="loaderSize" />
      </div>
    </Transition>
  </LayoutTextGradient>
</template>

<script setup lang="ts">
  import LoaderSpinner from '@/components/feedback/loader/LoaderSpinner.vue'
  import LayoutTextGradient from '@/components/layout/text-gradient/LayoutTextGradient.vue'
  import ImageLoaderBg from '@/components/media/ImageLoaderBg.vue'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { t } from '@/utils/helpers'
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      size?: 'default' | 'small'
      bgText?: string
      text: string
      imageSrc?: string
      imageType?: 'look' | 'product'
      transition?: string
      isLoading?: boolean
    }>(),
    {
      bgText: t('common.empty'),
      size: 'default',
      imageSrc: '',
      imageType: 'look',
      transition: 'fade',
    },
  )

  const sizeClass = computed(() => `size-${props.size}`)
  const imageTypeClass = computed(() => `type-${props.imageType}`)
  const bgFontSize = computed(() => (props.size === 'default' ? 240 : 120))
  const loaderSize = computed(() => (props.size === 'default' ? 24 : 16))
  const currentImage = computed(() => buildAssetPath({ src: props.imageSrc, thumb: 800 }))
</script>

<style lang="scss" scoped>
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .image {
      position: relative;

      &.type-product .bg--image {
        background-size: get-vw(50) !important;
      }

      .image-1,
      .image-2 {
        width: 100%;
        height: 100%;
      }

      .image-1 {
        z-index: 1;
        transform: rotate(-15deg);
        background-color: $gray-200;
      }
      .image-2 {
        top: 0;
        z-index: 2;
        position: absolute;
        transform: rotate(0deg);
        background-color: $gray-100;
      }
    }

    .text {
      color: $black;
    }

    // sizes
    &.size-default {
      gap: get-vw($g-8);

      .image {
        width: get-vw(60px);
        height: get-vw(80px);
      }
      .text {
        @include text-body($heading-5, $font-weight-600);
      }
    }
    &.size-small {
      gap: get-vw($g-6);

      .image {
        width: get-vw(30px);
        height: get-vw(40px);
      }
      .text {
        @include text-body($heading-2, $font-weight-600);
      }
    }
  }

  // Animation

  .fade-bg {
    &-enter-active,
    &-leave-active,
    &-appear-active {
      transition: opacity 0.3s ease-in-out;
    }
    &-enter-from,
    &-leave-to,
    &-appear-from {
      opacity: 0;
    }
    &-enter-to,
    &-leave-from,
    &-appear-to {
      opacity: 1;
    }
  }
</style>
