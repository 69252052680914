<template>
  <div class="banner-content d-flex rounded-6 overflow-hidden" :class="genClass">
    <div class="banner-text flex-fill d-flex align-center text-body-5 font-weight-600 px-10">
      <slot>Please use Google Chrome for a seamless and more stable experience.</slot>
    </div>
    <div class="banner-action flex-shrink-0">
      <button class="place-center" @click="onClose">
        <IconTag color="#fff" icon="icon-interface-close" :size="16"></IconTag>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { computed } from 'vue'

  type VariantType = 'default' | 'critical'
  const props = withDefaults(
    defineProps<{
      variant?: VariantType
    }>(),
    {
      variant: 'default',
    },
  )

  const emit = defineEmits(['close'])

  const genClass = computed(() => {
    return {
      [`banner-${props.variant}`]: true,
    }
  })

  function onClose() {
    emit('close')
  }
</script>

<style scoped lang="scss">
  .banner-content {
    width: 100%;
    height: get-vw(120);
    color: $white;
    backdrop-filter: blur(48px);
  }
  .banner-action {
    width: get-vw(120);
    height: get-vw(120);
    border-left: inherit;
  }
  .banner-default {
    background-color: rgba-percent($black, $opacity-300);
    border: 1px solid rgba-percent($white, $opacity-100);
  }
  .banner-critical {
    background-color: rgba-percent($critical, $opacity-200);
    border: 1px solid $critical;
  }
  button {
    cursor: pointer;
    outline: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
  }
  .banner-action button {
    & > div {
      transition: transform 0.3s ease-in-out;
    }
    &:hover {
      & > div {
        transform: rotate(90deg);
      }
    }
  }
</style>
