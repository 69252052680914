import { EmitMessage } from '@/interface/WebSocket'
import { Ref, watch } from 'vue'

/**
 * Storybook: Process the passed action message.
 * When a new action message is received, the corresponding message is processed.
 */
export function useActionMessageWatcher(options: {
  actionMessage: Ref<string | undefined>
  actions: Record<string, any>
  onMessage: (message: EmitMessage) => void
}) {
  watch(() => options.actionMessage.value, callActionMessage)

  function callActionMessage(msgKey?: string) {
    if (!msgKey) return
    const message: EmitMessage = options.actions[msgKey]
    options.onMessage(message)
  }
}
