<template>
<div class="content-feature d-flex g-9 pt-3 pb-3 g-2">
  <div class="icon bg-gray-100 rounded-4 place-center">
    <ImageLoader :src="buildAssetPath({ src: props.src })"></ImageLoader>
  </div>
  <div class="content d-flex justify-center flex-col g-2">
    <div class="headline heading-1 font-weight-600" v-text="props.headline"></div>
    <div class="caption color-gray-500 text-body-6 font-weight-600" v-text="props.caption"></div>
  </div>
</div>
</template>

<script setup lang="ts">
import buildAssetPath from '@/utils/buildAssetsPath'
import ImageLoader from '@/components/media/ImageLoader.vue'

const props = defineProps({
  caption: {
    type: String,
  },
  headline: {
    type: String
  },
  src: {
    type: String,
  }
})
</script>

<style scoped lang="scss">
  .icon {
    width: get-vw(80);
    height: get-vw(80);
    flex-shrink: 0;
  }
  :deep(img) {
    width: get-vw(36);
    height: get-vw(36);
  }
</style>
