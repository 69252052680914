import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import { supportedLanguages } from '@/utils/languageUtils'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/en',
  },
  {
    path: '/:lang',
    name: 'start',
    component: () => import('@/views/connect/ConnectStart.vue'),
  },
  {
    path: '/:lang/showroom',
    name: 'showroom',
    meta: {
      layout: EmptyLayout,
    },
    component: () => import('@/views/Showroom.vue'),
  },
  {
    path: '/:lang/sales-table',
    name: 'sales-table',
    component: () => import('@/views/SalesTable.vue'),
  },
  {
    path: '/:lang/screen',
    name: 'screen',
    meta: {
      layout: EmptyLayout,
    },
    component: () => import('@/views/Screen.vue'),
  },
  {
    path: '/:lang/launchpad',
    name: 'launchpad',
    redirect() {
      return { name: 'launchpad.client' }
    },
    component: () => import('@/views/launchpad/LaunchPad.vue'),
    children: [
      {
        path: 'client',
        name: 'launchpad.client',
        component: () => import('@/views/launchpad/LaunchPadClient.vue'),
      },
      {
        path: 'intro',
        name: 'launchpad.intro',
        component: () => import('@/views/launchpad/LaunchPadIntro.vue'),
      },
      {
        path: 'presentation',
        name: 'launchpad.presentation',
        component: () => import('@/views/launchpad/LaunchPadPresentation.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const defaultLanguage = 'en'

router.beforeEach((to, from, next) => {
  const language = to.params.lang || defaultLanguage
  if (!to.params.lang) {
    next({
      name: to.name,
      params: { ...to.params, lang: defaultLanguage },
      query: { ...to.query },
    })
  } else if (!supportedLanguages.includes(language)) {
    next({
      name: to.name,
      params: { ...to.params, lang: defaultLanguage },
      query: { ...to.query },
    })
  } else {
    next()
  }
})

// router.beforeEach((to, from, next) => {
//   if (from.name && !to.query.screen_id) {
//     const screenId = from.query.screen_id
//     const newQuery = { ...to.query, screen_id: screenId }
//     const name = to.name as string
//     next({
//       name,
//       params: to.params,
//       query: newQuery,
//       hash: to.hash
//     })
//   } else {
//     next()
//   }
// })

export default router
