import { Ref, ref } from 'vue'
import { ProductListItem, ProductTransfer } from '@/interface/messages/ProductTransfer.interface'
import { handleScrollPercent, t } from '@/utils/helpers'
import { formatDate } from '@/utils/dateUtils'

export function useTransferProducts() {
  const showTransferSidebar = ref(false)
  const transferHeadline = ref('')
  const transferItems: Ref<ProductListItem[]> = ref([])
  const selectedItemsIds: Ref<number[]> = ref([])
  const productIdsWithFilledPositions: Ref<number[]> = ref([])
  const transferMode = ref('')

  const setTransferData = (args: { content: ProductTransfer }) => {
    transferItems.value = []
    transferHeadline.value = args?.content?.title
    productIdsWithFilledPositions.value = args?.content?.productIdsWithFilledPositions
    const items = args?.content?.items
    transferItems.value = items.map((position) => {
      return {
        ...position,
        ...{
          positionAlreadySet: checkPositionAlreadySet(position.id),
          alreadySetText: getPositionAlreadySetText({ transferMode: args?.content?.transferMode, position }),
        },
      }
    })
    selectedItemsIds.value = args?.content?.usedIdsInGroup
    transferMode.value = args?.content?.transferMode
    showTransferSidebar.value = true
  }

  function getPositionAlreadySetText({ transferMode, position }) {
    switch (transferMode) {
      case 'exception_date':
        const dateExceptionDate = formatDate({ str: position.positions?.[0]?.exceptionDate })
        return `${t('position.position_transfer_already_set')} ${dateExceptionDate}`
      case 'desired_date':
        const dateRequestedDate = formatDate({ str: position.positions?.[0]?.requestedDate })
        return `${t('position.position_transfer_already_set')} ${dateRequestedDate}`
      default:
        return ''
    }
  }

  const closeTransferSidebar = () => {
    showTransferSidebar.value = false
    transferHeadline.value = ''
    transferItems.value = []
  }

  function checkProductSelectedById(productId: number) {
    return selectedItemsIds.value.includes(productId)
  }

  function checkPositionAlreadySet(productId: number) {
    return productIdsWithFilledPositions.value.includes(productId)
  }

  function scrollTransferContainerPercent(percent: number) {
    const container = document.querySelector('.product-transfer-scrollable') as HTMLElement
    if (container) {
      handleScrollPercent(container, percent)
    }
  }

  return {
    selectedItemsIds,
    showTransferSidebar,
    transferHeadline,
    transferItems,
    transferMode,
    scrollTransferContainerPercent,
    checkProductSelectedById,
    productIdsWithFilledPositions,
    setTransferData,
    closeTransferSidebar,
  }
}
