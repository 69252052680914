<template>
<div class="cart-text-icon align-center">
  <div class="icon place-center">
    <slot name="icon">
      <IconTag icon="icon-interface-placeholder"></IconTag>
    </slot>
  </div>
  <div class="content font-weight-600 text-body-4 text-truncate-1 color-gray-500">
    {{ props.title }}
  </div>
</div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import IconTag from '@/components/partials/icon/IconTag.vue'

const props = defineProps({
  title: {
    type: String
  },
})

</script>

<style scoped lang="scss">
.cart-text-icon {
  display: grid;
  grid-template-columns: fit-content(get-vw(20px)) 1fr ;
  gap: get-vw($g-4);
  grid-template-areas:
    "icon content";
  padding-left: get-vw($g-7);
  padding-right: get-vw($g-5);
}
.icon { grid-area: icon; }
.content { grid-area: content; }
</style>
