import { GridItems, GridTemplateType } from '@/views/collections/overview-grid/OverviewGrid.interface'

class OverviewGridHelper {
  private items
  private _gridItems
  constructor() {
    this.items = []
    this._gridItems = []
  }

  setItems(items) {
    this.items = JSON.parse(JSON.stringify(items))
  }

  get gridItems() {
    return this._gridItems
  }

  initGrid(): Promise<GridItems> {
    return new Promise((resolve) => {
      const gridTemplate = this.gridTemplate()[this.items.length]
      const gridItems: any = []
      if (gridTemplate) {
        gridTemplate.forEach((value) => {
          const itemsCount = value.int
          gridItems.push({ type: value.type, items: this.items.splice(0, itemsCount) })
        })
      }
      this._gridItems = gridItems
      resolve(gridItems)
    })
  }

  gridTemplate(): GridTemplateType {
    return {
      1: [{ int: 1, type: 'center' }],
      2: [{ int: 2, type: 'center' }],
      3: [{ int: 3, type: 'center' }],
      4: [{ int: 4, type: 'center' }],
      5: [
        { int: 3, type: 'center' },
        { int: 2, type: 'center' },
      ],
      6: [
        { int: 3, type: 'left' },
        { int: 3, type: 'right' },
      ],
      7: [
        { int: 4, type: 'center' },
        { int: 3, type: 'center' },
      ],
      8: [
        { int: 4, type: 'left' },
        { int: 4, type: 'right' },
      ],
      9: [
        { int: 3, type: 'left' },
        { int: 3, type: 'right' },
        { int: 3, type: 'left' },
      ],
      10: [
        { int: 3, type: 'center' },
        { int: 4, type: 'center' },
        { int: 3, type: 'center' },
      ],
      11: [
        { int: 4, type: 'center' },
        { int: 3, type: 'center' },
        { int: 4, type: 'center' },
      ],
      12: [
        { int: 4, type: 'left' },
        { int: 4, type: 'right' },
        { int: 4, type: 'left' },
      ],
      13: [
        { int: 3, type: 'center' },
        { int: 4, type: 'center' },
        { int: 3, type: 'left' },
        { int: 3, type: 'right' },
      ],
      14: [
        { int: 4, type: 'center' },
        { int: 3, type: 'center' },
        { int: 4, type: 'center' },
        { int: 3, type: 'center' },
      ],
      15: [
        { int: 4, type: 'left' },
        { int: 4, type: 'right' },
        { int: 4, type: 'left' },
        { int: 3, type: 'center' },
      ],
      16: [
        { int: 4, type: 'left' },
        { int: 4, type: 'right' },
        { int: 4, type: 'left' },
        { int: 4, type: 'right' },
      ],
      17: [
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
      ],
      18: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      19: [
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 3,
          type: 'center',
        },
      ],
      20: [
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      21: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
      ],
      22: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      23: [
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 3,
          type: 'center',
        },
      ],
      24: [
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      25: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      26: [
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      27: [
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      28: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
      ],
      29: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      30: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      31: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      32: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      33: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      34: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      35: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      36: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      37: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      38: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      39: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      40: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      41: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      42: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      43: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      44: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      45: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      46: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      47: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      48: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      49: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      50: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      51: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      52: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      53: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      54: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      55: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      56: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      57: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      58: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      59: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      60: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      61: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      62: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      63: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      64: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      65: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      66: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      67: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      68: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      69: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      70: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      71: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      72: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      73: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      74: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      75: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      76: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      77: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      78: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      79: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      80: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      81: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      82: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      83: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      84: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      85: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      86: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      87: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      88: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      89: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      90: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      91: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      92: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      93: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      94: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      95: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
      ],
      96: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
      97: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      98: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      99: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 3,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
        {
          int: 4,
          type: 'right',
        },
        {
          int: 4,
          type: 'left',
        },
      ],
      100: [
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
        {
          int: 4,
          type: 'center',
        },
      ],
    }
  }
}

export default OverviewGridHelper
