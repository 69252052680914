<template>
  <SalesTableContainer>
    <template v-slot:left>
      <Transition name="fade-image" mode="out-in" appear>
        <LayoutSide
          :key="item.id"
          :caption="item.caption?.toUpperCase()"
          :title="title"
          :subtitle="item.subtitle"
          :images="images"
          :tag="customTitle"
          :show-content="!!(title || customTitle)"
        />
      </Transition>
    </template>

    <GridMood v-if="items.length" :key="item.id" grid-layout="landscape" :items-total="items.length" :is-ready="true">
      <template v-slot="{ index }">
        <div class="product-grid-wrapper">
          <Transition name="overview-slide-up" mode="out-in" appear>
            <ProductTile
              :id="items[index].id"
              :key="items[index].id"
              class="product-grid"
              :size="productSize"
              :image-src="items[index].image"
              :title="items[index].name"
              :subtitle="items[index].sku"
              :whs="items[index].whs"
              :rrp="items[index].rrp"
              :color="items[index].color"
              :tags="items[index].tags"
              :show-header="showDetails"
              :show-info="showDetails"
              :show-color="showDetails"
              :show-counter="showCounter(items[index].id)"
              :counter="getCounter(items[index].id)"
              :is-selected="checkItemIsSelected(items[index].id)"
            />
          </Transition>
        </div>
      </template>
    </GridMood>
    <LayoutState v-else :image-src="images?.[0]" :text="textEmpty" />
  </SalesTableContainer>
</template>

<script lang="ts" setup>
  import SalesTableContainer from '@/components/container/SalesTableContainer.vue'
  import GridMood from '@/components/grid/GridMood.vue'
  import LayoutState from '@/components/layout/state/LayoutState.vue'
  import LayoutSide from '@/components/layout/side/LayoutSide.vue'
  import ProductTile from '@/components/content/product/ProductTile.vue'
  import { useProductCounter } from '@/composables/product/useProductCounter'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { useDetailGridContent } from '@/views/collections/detail-grid/composables/useDetailGridContent'
  import { useDetailGridMessages } from '@/views/collections/detail-grid/composables/useDetailGridMessages'
  import { useProductSize } from './composables/useProductSize'
  import { defineProps, onMounted, onUnmounted } from 'vue'
  import { DetailGridMessage } from '@/views/collections/detail-grid/DetailGrid.interface'

  const screenID = 'DetailGrid'
  const props = defineProps<{ message: DetailGridMessage; actionMessage?: string }>()

  const { item, items, images, showDetails, textEmpty, title, customTitle } = useDetailGridContent(props)
  const { showCounter, getCounter } = useProductCounter()
  const { setProductSize, productSize } = useProductSize(items)
  const { onMessage, checkItemIsSelected } = useDetailGridMessages(props)

  onMounted(() => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })
    setProductSize()
  })
  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID, onMessage })
  })
</script>

<style lang="scss" scoped>
  .product-grid-wrapper {
    display: flex;
    height: 100%;

    .product-grid {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .fade-image {
    &-enter-active,
    &-leave-active {
      transition:
        opacity 0.3s ease-in-out,
        transform 0s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-to,
    &-leave-from {
      opacity: 1;
    }
  }
</style>
