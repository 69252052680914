<template>
  <div class="content-heading d-flex g-5" :class="genClass">
    <div class="content-heading--icon rounded-4 place-center flex-shrink-0 bg-gray-100">
      <slot name="icon">
        <IconTag :icon="props.icon"></IconTag>
      </slot>
    </div>
    <div class="content-heading--content d-flex align-start flex-col justify-center flex-fill g-1">
      <div class="headline heading-1 font-weight-600">
        <div class="headline_area">
          <slot name="headline">{{ headline }}</slot>
        </div>
        <div class="action_area">
          <slot name="action"></slot>
        </div>
      </div>
      <div class="caption heading-1 font-weight-600 d-inline-grid text-truncate-1">
        <slot name="caption">{{ caption }}</slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import IconTag from '@/components/partials/icon/IconTag.vue'
import { computed } from 'vue'

const props = defineProps({
  headline: {
    type: String,
    default: ''
  },
  caption: {
    type: String,
    default: ''
  },
  theme: {
    type: String,
    default: 'light'
  },
  icon: {
    type: String,
    default: 'icon-interface-placeholder'
  }
})

const genClass = computed(() => {
  return {
    [`theme-${props.theme}`]: true
  }
})
</script>

<style scoped lang="scss">
  .theme-light {
    --typo-color: #{extract-rgb($black)};
  }
  .theme-dark {
    --typo-color: #{extract-rgb($white)};
  }
  .content-heading--icon {
    width: get-vw(72px);
    height: get-vw(72px);
    font-size: get-vw(24);
  }
  .headline {
    display: grid;
    grid-template-areas: 'headline action';
    grid-template-columns: 1fr fit-content(get-vw(120px));
    width: 100%;
    align-items: center;
    color: rgb(var(--typo-color));
  }
  .caption {
    color: rgba(var(--typo-color), 0.5);
  }
  .headline_area {
    grid-area: headline;
  }
  .action_area {
    grid-area: action;
  }
</style>
