<template>
  <div class="layout-text-gradient flex align-center justify-center" :class="typeClass">
    <Transition mode="out-in" name="fade-bg" appear>
      <div ref="bgTextRef" class="bg-text" :class="bgTextClass">
        {{ text }}
      </div>
    </Transition>
    <div v-if="$slots.default" class="bg-content flex justify-center flex-col">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useDynamicFontSize } from '@/components/layout/text-gradient/composables/useDynamicFontSize'
  import { computed, onMounted } from 'vue'

  const props = withDefaults(
    defineProps<{
      type: 'light' | 'dark'
      text: string
      fontSize?: number
      isDynamicText?: boolean
    }>(),
    {
      fontSize: 320,
      isDynamicText: false,
    },
  )

  const { bgTextRef, adjustFontSize } = useDynamicFontSize(props)

  const typeClass = computed(() => `type-${props.type}`)
  const bgTextClass = computed(() => {
    const isDynamicTexClass = props.isDynamicText ? 'is-dynamic-text' : ''
    return [isDynamicTexClass]
  })

  onMounted(() => adjustFontSize())
</script>

<style lang="scss" scoped>
  .layout-text-gradient {
    position: relative;
    background: transparent;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .bg-content {
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .bg-text {
      position: absolute;
      z-index: 1;
      @include text-body(320, $font-weight-600);
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;

      &.is-dynamic-text {
        display: inline-block;
      }
      &:not(.is-dynamic-text) {
        display: flex;
        justify-content: center;
      }
    }

    // type
    &.type-dark {
      background: linear-gradient(180deg, $black 0%, $gray-700 100%);

      .bg-text {
        background: $gradient-darken-up-40;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &.type-light {
      background: $white;

      .bg-text {
        background: linear-gradient(180deg, $gray-200 0%, $white 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .fade-bg {
    &-enter-active,
    &-leave-active,
    &-appear-active {
      transition: opacity 0.5s ease-in-out;
    }
    &-enter-from,
    &-leave-to,
    &-appear-from {
      opacity: 0;
    }
    &-enter-to,
    &-leave-from,
    &-appear-to {
      opacity: 1;
    }
  }
</style>
