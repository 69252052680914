<template>
  <div class="feedback-selected-filter flex flex-col g-4">
    <div class="header flex align-center g-4">
      <IconTag icon="icon-interface-filter" :size="16" />
      <div class="flex align-end">
        {{ $t('filter.selected_filters') }}
        <ChipState class="counter" type="counter" :counter="filters.length" :counter-max="1000" />
      </div>
    </div>
    <div class="filter-tags flex g-2">
      <TransitionGroup name="slide-x-transition">
        <ChipTag v-for="(filter, index) in visibleFilters" :key="index" size="medium" :is-uppercase="false">
          {{ filter }}
        </ChipTag>
        <ChipTag v-if="hiddenFiltersCount > 0" key="more" color="black" size="medium">
          +{{ hiddenFiltersCount }}
        </ChipTag>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import ChipState from '@/components/partials/chip/ChipState.vue'
  import ChipTag from '@/components/partials/chip/ChipTag.vue'
  import IconTag from '@/components/partials/icon/IconTag.vue'

  const props = defineProps<{ filters: string[] }>()

  const MAX_VISIBLE_FILTERS = 10

  const visibleFilters = computed(() => props.filters.slice(0, MAX_VISIBLE_FILTERS))
  const hiddenFiltersCount = computed(() => Math.max(0, props.filters.length - MAX_VISIBLE_FILTERS))
</script>

<style lang="scss" scoped>
  .feedback-selected-filter {
    background: $white;
    width: get-vw(360);
    padding: get-vw($g-6);
    border-radius: get-vw($r-4);
    box-shadow: $shadow-bottom-800;

    .header {
      @include text-body($text-body-6, $font-weight-600);

      .counter {
        color: $gray-400;
        background: transparent;
        margin-left: get-vw($g-1);

        :deep(.right) {
          @include text-body($text-body-6, $font-weight-600);
          justify-content: left;
        }
      }
    }

    .filter-tags {
      display: inline-flex;
      flex-wrap: wrap;
      gap: get-vw($g-2);

      .filter-tag {
        @include text-body($text-body-3, $font-weight-600);
        border-radius: get-vw(50);
        background: $gray-100;
        height: get-vw(24);
        padding: 0 get-vw($g-3);
        white-space: nowrap;
        flex: 0 0 auto;
      }
    }
  }

  .slide-x-transition {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.3s;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateX(-15px);
    }

    &-enter-active,
    &-leave-active {
      transition-property: transform, opacity !important;
    }
  }
</style>
