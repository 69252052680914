<template>
  <div class="line-height-0 d-flex align-center" :style="style">
    <span class="v-icon" :class="icon" />
  </div>
</template>

<script setup lang="ts">
  import { useViewportCalculations } from '@/composables/useViewport'
  import { computed } from 'vue'
  import { getThemeColorByName, isCssColor } from '@/composables/useColorUtils'

  const props = defineProps<{
    icon: string
    size?: number
    color?: string
  }>()

  const { pxToVW } = useViewportCalculations()

  const getColor = computed(() => {
    return isCssColor(props.color) ? props.color : getThemeColorByName(props.color)
  })

  const style = computed(() => ({
    '--icon-size': props.size ? `${pxToVW(props.size)}vw` : '',
    '--icon-color': props.color ? getColor.value : undefined,
  }))
</script>

<style lang="scss" scoped>
  .v-icon {
    display: contents;
    font-size: var(--icon-size);
    color: var(--icon-color);
  }
</style>
