<template>
  <div class="chip-color" :class="[sizeClass]">
    <span class="chip-color-tag" :class="{ 'w-100': !$slots['default'] }" :style="colorStyle">
      {{ code }}
    </span>

    <span v-if="!!$slots['default']" class="chip-color-text text-truncate-1">
      <slot></slot>
    </span>
  </div>
</template>

<script lang="ts" setup>
  import { GeneralSize } from '@/interface/ComponentSizes'
  import { computed } from 'vue'
  import { getBrightness, getRGBAClass } from '@/plugins/generalColors'

  const props = withDefaults(
    defineProps<{
      hex?: string
      code?: string
      size?: GeneralSize
    }>(),
    {
      hex: '',
      code: '',
      size: 'default',
    },
  )

  const sizeClass = computed(() => (props.size ? `size-${props.size}` : ''))

  const colorStyle = computed(() => {
    const rgbaColor = getRGBAClass(props.hex)
    const defaultColor = getBrightness({ rgba: rgbaColor }) < 200 ? 'white' : 'black'

    const styles = {
      default: {
        backgroundStyle: `background: ${rgbaColor};`,
        colorTextStyle: `color: ${getRGBAClass(defaultColor)};`,
      },
    }

    const { colorTextStyle = '', backgroundStyle = '' } = styles.default

    return [colorTextStyle, backgroundStyle]
  })
</script>

<style lang="scss" scoped>
  @mixin chip-color-size($tag-fs, $tag-padding, $text-fs, $height, $min-width, $border-radius) {
    .chip-color-tag {
      @include text-body($tag-fs, $font-weight-600);
      height: get-vw($height);
      line-height: normal;
      min-width: get-vw($min-width);
      border: get-vw(1px) solid rgba-percent($black, $opacity-100);
      border-radius: get-vw($border-radius);
      padding: $tag-padding;
    }
    .chip-color-text {
      font-size: get-vw($text-fs);
      font-weight: $font-weight-600;
      line-height: normal;
    }
  }

  .chip-color {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: get-vw($g-2);
    width: 100%;

    .chip-color-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      filter: saturate(0.5);
    }

    // sizes
    &.size-large {
      $tag-pa: 0 get-vw($g-2);
      gap: get-vw($g-3);
      @include chip-color-size($text-body-6, $tag-pa, $heading-2, 32, 56, 10);
    }
    &.size-medium {
      $tag-pa: 0 get-vw($g-2);
      gap: get-vw($g-3);
      @include chip-color-size($text-body-4, $tag-pa, $heading-1, 24, 44, $r-2);
    }
    &.size-default {
      $tag-pa: 0 get-vw($g-2);
      gap: get-vw($g-2);
      @include chip-color-size($text-body-3, $tag-pa, $text-body-4, 20, 40, 6);
    }
    &.size-small {
      $tag-pa: 0 get-vw($g-1);
      gap: get-vw($g-2);
      @include chip-color-size($text-body-2, $tag-pa, $text-body-2, 16, 32, $r-1);
    }
  }
</style>
