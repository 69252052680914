import { useActionMessageWatcher } from '@/composables/storybook/useActionMessageWatcher'
import { EmitMessage } from '@/interface/WebSocket'
import { overviewSliderActions } from '@/views/collections/overview-slider/OverviewSlider.actions'
import { OverviewSliderMessage } from '@/views/collections/overview-slider/OverviewSlider.interface'
import { computed } from 'vue'

export function useOverviewSliderMessages(
  props: { message: OverviewSliderMessage; actionMessage?: string },
  state: {
    sliderPosition: number
    containerWidth: number
    dragIsActive: boolean
  },
) {
  const actionMessage = computed(() => props.actionMessage)
  useActionMessageWatcher({ actionMessage, actions: overviewSliderActions, onMessage })

  function onMessage(message: EmitMessage) {
    if (message.event === 'emit' && message.data.emit) {
      const { method, args } = message.data.emit

      const actions: Record<string, () => void> = {
        dragStart: () => (state.dragIsActive = true),
        dragProgress: () => dragProgress(args),
        dragEnd: () => (state.dragIsActive = false),
      }
      actions[method]?.()
    }
  }

  function dragProgress(args: { value: number; containerWidth: number }) {
    state.sliderPosition = (state.containerWidth / args.containerWidth) * args.value
  }

  return {
    onMessage,
    dragProgress,
  }
}
