<template>
  <div class="chip-tag" :class="classes" :style="colorStyle">
    <div class="chip-tag-content">
      <icon-tag v-if="icon" :icon="icon" />
      <template v-if="!!$slots['default']">
        <span class="content text-truncate-1">
          <slot></slot>
        </span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { useViewportCalculations } from '@/composables/useViewport'
  import { computed, useSlots } from 'vue'
  import { getBrightness, getRGBAClass } from '@/plugins/generalColors'

  const slot = useSlots()

  const props = withDefaults(
    defineProps<{
      size?: 'x-small' | 'small' | 'default' | 'medium' | 'large'
      variant?: 'default' | 'outlined' | 'tonal' | 'text' | 'plain'
      icon?: string
      color?: string
      isUppercase?: boolean
    }>(),
    {
      size: 'default',
      variant: 'default',
      icon: '',
      color: 'gray-100',
      isUppercase: true,
    },
  )

  const hasDefaultSlot = computed(() => !!slot['default'])

  const classes = computed(() => {
    return {
      'icon-only': props.icon && !hasDefaultSlot.value,
      'is-uppercase': props.isUppercase,
      [`variant-${props.variant}`]: true,
      [`size-${props.size}`]: true,
    }
  })

  const colorStyle = computed(() => {
    const { color, variant } = props
    const { pxToVW } = useViewportCalculations()
    const getSizeVW = (fontSize: number) => pxToVW(fontSize) + 'vw'

    const rgbaColor = getRGBAClass(color)
    const borderStyleVW = `border: ${getSizeVW(1)} solid ${rgbaColor};`
    const defaultColor = getBrightness({ rgba: rgbaColor }) < 128 ? 'gray-100' : 'gray-600'

    const styles = {
      default: {
        colorTextStyle: `color: ${getRGBAClass(defaultColor)};`,
        backgroundStyle: `background: ${rgbaColor};`,
        borderStyle: borderStyleVW,
      },
      outlined: {
        colorTextStyle: `color: ${rgbaColor};`,
        backgroundStyle: '',
        borderStyle: borderStyleVW,
      },
      tonal: {
        colorTextStyle: `color: ${rgbaColor};`,
        backgroundStyle: `background: ${getRGBAClass(color, 0.1)};`,
        borderStyle: borderStyleVW,
      },
      text: {
        colorTextStyle: `color: ${rgbaColor};`,
        backgroundStyle: '',
        borderStyle: '',
      },
      plain: {
        colorTextStyle: `color: ${getRGBAClass(color, 0.75)};`,
        backgroundStyle: '',
        borderStyle: '',
      },
    }

    const { colorTextStyle, backgroundStyle, borderStyle } = styles[variant]

    return [colorTextStyle, backgroundStyle, borderStyle]
  })
</script>

<style lang="scss" scoped>
  @mixin chip-tag-size($height, $gap, $font-size, $icon-size, $border-radius, $pa-vh, $pa-vw) {
    @include text-body($font-size, $font-weight-600);
    height: get-vw($height);
    border-radius: get-vw($border-radius);
    padding: get-vw($pa-vh) get-vw($pa-vw);
    gap: get-vw($gap);

    &.icon-only {
      width: get-vw($height);
      padding: get-vw($pa-vh);
    }

    :deep(.v-icon) {
      font-size: get-vw($icon-size);
    }
  }

  .chip-tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;

    .chip-tag-content {
      display: flex;
      gap: get-vw($g-2);

      .content {
        line-height: normal;
      }
    }

    &.icon-only {
      display: flex;
      padding: get-vw($g-3) get-vw($g-3);
    }

    &.is-uppercase {
      text-transform: uppercase;
    }

    // sizes
    &.size-large {
      @include chip-tag-size(36px, $g-3, $text-body-4, 16px, $r-5, $g-2, $g-5);
    }
    &.size-medium {
      @include chip-tag-size(32px, $g-3, $text-body-3, 14px, $r-4, $g-2, $g-4);
    }
    &.size-default {
      @include chip-tag-size(24px, $g-2, $text-body-2, 12px, $r-3, $g-1, $g-3);
    }
    &.size-small {
      @include chip-tag-size(20px, $g-2, $text-body-2, 10px, $r-3, $g-2, $g-2);
    }
    &.size-x-small {
      @include chip-tag-size(16px, $g-2, $text-body-2, 10px, $r-3, $g-0, $g-2);
    }
  }
</style>
