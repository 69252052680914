import { useGeneralStore } from '@/stores/general'
import { useSettingsStore } from '@/stores/settings'
import { Socket, io } from 'socket.io-client'
import logger from '@/service/Logger'
import { serializeError } from 'serialize-error'
import { getScreenIdFromUrl } from '@/utils/helpers'
import router from '@/router'
import i18n from '@/plugins/i18n'

class ShowroomScreen {
  private static instance: ShowroomScreen | null = null
  public id: number | null
  private observers: any[]
  private ws: Socket | null

  constructor(id = null) {
    this.id = getScreenIdFromUrl()
    this.ws = null
    this.observers = []
  }

  public static getInstance(): ShowroomScreen {
    return (ShowroomScreen.instance ||= new ShowroomScreen())
  }

  addObserver(observer) {
    this.observers.push(observer)
  }

  removeObserver(observer) {
    const index = this.observers.findIndex((item) => item.id === observer.id)
    if (index !== -1) {
      this.observers.splice(index, 1)
    }
  }

  log(obj) {
    if (obj?.event === 'emit') {
      console.log(
        `%c ${obj?.data?.screen_id} %c ${obj?.data?.emit?.method}`,
        'background: #000; color: #ff0; font-weight: bold;',
        'background: #ff0; color: #000; font-weight: bold;',
        obj,
      )
    } else {
      console.log(
        `%c ${obj?.data?.screen_id} %c ${obj?.data?.view}`,
        'background: #000; color: #0f0; font-weight: bold;',
        'background: #0f0; color: #000; font-weight: bold;',
        obj,
      )
    }
  }

  disconnectWS() {
    if (this.ws) {
      this.ws.disconnect()
    }
  }

  connectWS(role = 'showroom_screen', id: number | undefined = undefined) {
    const { screenType } = useSettingsStore()
    const generalStore = useGeneralStore()
    const { config } = useGeneralStore()
    const socket = io(config?.node, { autoConnect: false, transports: ['websocket'] })

    const eventColor = {
      update: 'background-color: #0f0; color: #000; font-weight: bold;',
      emit: 'background-color: #ff0; color: #000; font-weight: bold;',
      register: 'background-color: magenta; color: #000; font-weight: bold;',
    }

    // log every message to the console
    socket.onAny((event, ...args) => {
      console.log(`⬇️ %c ${event.toUpperCase()} `, eventColor[event], ...args)
    })

    socket.onAnyOutgoing((event, ...args) => {
      console.log(`⬆️ %c ${event.toUpperCase()} `, eventColor[event], ...args)
    })

    socket.on('update', (data) => {
      this.observers.forEach((observer) => {
        observer.onMessage({ event: 'update', data })
      })
    })

    socket.on('emit', (data) => {
      this.observers.forEach((observer) => {
        observer.onMessage({ event: 'emit', data })
      })
    })

    socket.on('connect', () => {
      const screenId = id || this.id
      const showroomId = screenId?.toString().slice(0, -1)

      console.log(`🤝🏻 Socket ${socket.id} connection is listening`)
      socket.emit('register', { screenType, screenId, showroomId, role, token: config?.token })
      logger.log('showroom-status', {
        message: 'Showroom is now open for business! 🚗🛒💫 #ShowroomConnected',
        error: 'Our showroom just put on its party hat and joined the digital shindig! 🚗🥳🔌 #ShowroomPartyMode',
      })
    })

    socket.on('disconnect', (reason) => {
      console.log(`✋ Socket ${socket.id} connection is closed: ${reason}`)
      logger.log('showroom-status', {
        message: 'Showroom has temporarily closed its doors. 🚗🔒😴 #ShowroomDisconnected',
        error: serializeError(reason),
      })
    })

    socket.on('connect_error', (error) => {
      console.log('🚨 Socket error occurred', error)
      logger.log('showroom-error', {
        message: "Oops! Something went 'vroom' in the showroom. 🚗🔧💥 #ShowroomError",
        error: serializeError(error),
      })
    })

    socket.on('ping', () => {
      console.log('ping')
    })

    socket.on('screen:reload', () => {
      window.location.reload()
    })

    socket.on('screen:connect', (args) => {
      generalStore.setMeet(args.content)
    })
    socket.on('screen:disconnect', () => {
      this.disconnectWS()
      generalStore.resetMeet()
      router.push({ name: 'start', params: { lang: i18n.global.locale } })
    })

    this.ws = socket
    socket.connect()
  }
}

export default ShowroomScreen.getInstance()
