import { detailHeroContentExample2 } from '@/views/collections/detail-hero/DetailHero.example'

const createEmitAction = (method: string, args = {}) => ({
  event: 'emit',
  data: { screen_id: 90001, emit: { method, args } },
})

export const detailHeroActions = {
  // playSound: createEmitAction('playSound', {}),
  // stopSound: createEmitAction('stopSound'),
  // setMute: createEmitAction('setMute', { muted: true }),
  animDetailIn: createEmitAction('animDetailIn'),
  animDetailOut: createEmitAction('animDetailOut'),
  detailOut: createEmitAction('detailOut'),
  closeBackground: createEmitAction('closeBackground'),
  changeDetail: createEmitAction('changeDetail', detailHeroContentExample2),
}
