<template>
  <span>{{ formattedNumber }}</span>
</template>

<script>
  import { ref, watch, onMounted } from 'vue'
  import { priceFormatter } from '@/utils/helpers'

  export default {
    props: {
      start: {
        type: Number,
        default: 0,
      },
      end: {
        type: Number,
        required: true,
      },
      duration: {
        type: Number,
        default: 1000, // in milliseconds
      },
      currency: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const lastValue = ref(props.start)
      const formattedNumber = ref(formatNumber(lastValue.value, props.currency))
      const nextValue = ref(null)
      const animating = ref(false)

      const animateNumber = (newEnd) => {
        const duration = props.duration
        const range = newEnd - lastValue.value
        const startTime = Date.now()
        animating.value = true

        const updateNumber = () => {
          const currentTime = Date.now()
          const elapsedTime = currentTime - startTime
          if (elapsedTime < duration) {
            const currentValue = lastValue.value + (range * elapsedTime) / duration
            formattedNumber.value = formatNumber(currentValue, props.currency)
            requestAnimationFrame(updateNumber)
          } else {
            formattedNumber.value = formatNumber(newEnd, props.currency)
            lastValue.value = newEnd
            animating.value = false
            if (nextValue.value !== null) {
              const nextEnd = nextValue.value
              nextValue.value = null
              animateNumber(nextEnd)
            }
          }
        }

        requestAnimationFrame(updateNumber)
      }

      onMounted(() => {
        animateNumber(props.end)
      })

      watch(
        () => props.end,
        (newEnd) => {
          if (animating.value) {
            nextValue.value = newEnd
          } else {
            animateNumber(newEnd)
          }
        },
      )

      return {
        formattedNumber,
      }
    },
  }

  function formatNumber(number, currency) {
    if (currency) {
      return priceFormatter(number)
    } else {
      return parseInt(number, 10)
    }
  }
</script>

<style scoped>
  /* Add your component styles here */
</style>
