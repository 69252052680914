import { ref } from 'vue'
import { hexToRgb, themes } from '@/plugins/generalColors'

export function useColor(color: string, type: 'text' | 'background') {
  const colorClasses = ref<string[]>([])
  const colorStyles = ref<Record<string, string>>({})

  if (type === 'background') {
    if (isCssColor(color)) {
      colorStyles.value.backgroundColor = color
    } else {
      colorClasses.value.push(`bg-${color}`)
    }
  }

  if (type === 'text') {
    if (isCssColor(color)) {
      colorStyles.value.color = color
    } else {
      colorClasses.value.push(`color-${color}`)
    }
  }

  return {
    colorClasses: colorClasses.value,
    colorStyles: colorStyles.value,
  }
}

export function getThemeColorByName(name, theme = 'light') {
  const colors = themes[theme].colors
  return colors[name]
}

export function getColorRGBA(color, theme = 'light') {
  return isCssColor(color) ? color : hexToRgb(getThemeColorByName(color, theme), 1)
}

export function isCssColor(color) {
  return !!color && /^(#|var\(--|(rgb|hsl)a?\()/.test(color)
}
