<template>
  <BannerNavigation :system="getSystem"></BannerNavigation>
  <slot></slot>
  <div class="banner-container d-flex flex-col align-center g-4">
    <BannerContent v-if="showChromeBanner" variant="critical" @close="showChromeBanner = !showChromeBanner">
      {{ $t('connect.banner.chrome') }}
    </BannerContent>
    <BannerContent v-if="showFullscreenBanner" @close="showFullscreenBanner = !showFullscreenBanner">
      <div class="d-flex flex-fill align-center justify-between">
        <span class="d-block">{{ $t('connect.banner.fullscreen') }}</span>
        <button class="btn btn-white d-flex g-4 align-center" @click="toggleFullscreen">
          <IconTag :size="16" icon="icon-interface-enter-fullscreen" />
          <span class="d-block font-weight-600 text-body-5">{{ $t('connect.fullscreen') }}</span>
        </button>
      </div>
    </BannerContent>
  </div>
</template>

<script setup lang="ts">
  import BannerContent from '@/components/content/banner/BannerContent.vue'
  import BannerNavigation from '@/components/content/banner/BannerNavigation.vue'
  import { computed, onMounted, ref, watch } from 'vue'
  import { useFullscreen } from '@/composables/useFullscreen'
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { useGeneralStore } from '@/stores/general'
  const { isFullscreen, toggleFullscreen } = useFullscreen()

  const showFullscreenBanner = ref(false)
  const showChromeBanner = ref(false)
  const generalStore = useGeneralStore()

  const getSystem = computed(() => generalStore.config?.system)
  function checkInfoBanners() {
    if (!isFullscreen.value) {
      showFullscreenBanner.value = true
    }
  }

  function checkInfoChrome() {
    if (!navigator.userAgent.includes('Chrome')) {
      showChromeBanner.value = true
    }
  }

  watch(isFullscreen, (value) => {
    showFullscreenBanner.value = !value
  })

  onMounted(() => {
    setTimeout(() => {
      checkInfoBanners()
    }, 15 * 1000)
    setTimeout(() => {
      checkInfoChrome()
    }, 5 * 1000)
  })
</script>

<style lang="scss" scoped>
  .banner-container {
    position: fixed;
    bottom: get-vw(80);
    width: clamp(get-vw(960), get-vw(960), 80vw);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
</style>
