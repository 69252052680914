import { useColorVariant } from '@/composables/socket/useColorVariant'
import { useMultipleSelection } from '@/composables/socket/useMultipleSelection'
import { useActionMessageWatcher } from '@/composables/storybook/useActionMessageWatcher'
import { EmitMessage } from '@/interface/WebSocket'
import { detailGridActions } from '@/views/collections/detail-grid/DetailGrid.actions'
import { DetailGridMessage } from '@/views/collections/detail-grid/DetailGrid.interface'
import { computed } from 'vue'

export function useDetailGridMessages(props: { message: DetailGridMessage; actionMessage?: string }) {
  const { setMultipleSelection, checkItemIsSelected } = useMultipleSelection()
  const { showColorVariant, colorVariantData, setColorVariantData, closeColorVariant } = useColorVariant()

  /** Storybook actions helper  */
  const actionMessage = computed(() => props.actionMessage)
  useActionMessageWatcher({ actionMessage, actions: detailGridActions, onMessage })
  /* ---------------------------------------------------------------------------------- */

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      setColorVariantData: () => setColorVariantData(args),
      closeColorVariant: () => closeColorVariant(),
      setMultipleSelection: () => setMultipleSelection(args),
    }

    actions[method]?.()
  }

  return {
    onMessage,
    checkItemIsSelected,
    showColorVariant,
    colorVariantData,
  }
}
