<template>
<div class="size-headline d-flex g-4 pt-7 pb-4">
  <IconTag icon="icon-interface-sizegrid"></IconTag>
  <div class="text-body-5 font-weight-600">{{ props.headline }}</div>
</div>
</template>

<script setup lang="ts">
import IconTag from '@/components/partials/icon/IconTag.vue'
const props = defineProps({
  headline: {
    type: String
  }
})
</script>

<style scoped lang="scss">

</style>
