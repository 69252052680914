<template>
  <ProductDetailContainer full-view>
    <template v-slot:image>
      <div class="p-16 d-grid h-100 relative">
        <ProductDetailMainImage :src="product.image"></ProductDetailMainImage>
        <ContentHeading
          icon="icon-interface-color"
          :caption="$t('common.color')"
          :headline="$t('common.variations')"
          class="align-self-start"
        />

        <Transition name="slide-down" mode="out-in">
          <ProductHeader
            :title="product.name"
            :rrp="product.rrp"
            :whs="product.whs"
            :color="product.color"
            :subtitle="product.sku"
            :category="product.category"
            :tags="product.tags"
            class="product-header align-self-end justify-self-start"
          />
        </Transition>
      </div>
    </template>
    <div class="content-inner p-6 w-100 h-100">
      <div class="rounded-6 bg-white overflow-hidden h-100 d-flex flex-col h-100">
        <div class="content-stats h-100">
          <GridMood
            v-if="items.length"
            :key="product.id"
            grid-layout="cross-selling"
            :items-total="items.length"
            :is-ready="true"
          >
            <template v-slot="{ index }">
              <div class="product-grid-wrapper">
                <Transition name="overview-slide-up" mode="out-in" appear>
                  <ProductTile
                    :id="items[index].id"
                    :key="items[index].id"
                    :type="showDetails ? 'default' : 'absolute'"
                    :size="showDetails ? 'default' : 'small'"
                    class="product-grid"
                    :class="{ 'show-color-name': showColorName }"
                    :image-src="items[index].image"
                    :title="items[index].name"
                    :subtitle="items[index].sku"
                    :whs="items[index].whs"
                    :rrp="items[index].rrp"
                    :color="items[index].color"
                    :tags="items[index].tags"
                    :show-header="showDetails"
                    :show-info="false"
                    :show-color="showDetails"
                    :show-counter="showCounter(items[index].id)"
                    :counter="getCounter(items[index].id)"
                    :is-selected="checkItemIsSelected(items[index].id)"
                  />
                </Transition>
              </div>
            </template>
          </GridMood>
        </div>
      </div>
    </div>
  </ProductDetailContainer>
</template>

<script setup lang="ts">
  import ProductDetailMainImage from '@/components/_common/product/ProductDetailMainImage.vue'
  import ProductDetailContainer from '@/components/container/ProductDetailContainer.vue'
  import ContentHeading from '@/components/content/ContentHeading.vue'
  import ProductHeader from '@/components/content/product/ProductHeader.vue'
  import ProductTile from '@/components/content/product/ProductTile.vue'
  import GridMood from '@/components/grid/GridMood.vue'
  import { useProductCounter } from '@/composables/product/useProductCounter'
  import { useMultipleSelection } from '@/composables/socket/useMultipleSelection'
  import { ProductCrossSellingMessage } from '@/views/product-cross-selling/ProductCrossSelling.interface'
  import { computed } from 'vue'

  const props = defineProps<{ message: ProductCrossSellingMessage }>()

  const product = computed(() => props.message.content.product)
  const items = computed(() => props.message.content.items)

  const showColorName = computed(() => items.value.length <= 9)
  const showDetails = computed(() => items.value.length <= 16)

  const { showCounter, getCounter } = useProductCounter()
  const { checkItemIsSelected } = useMultipleSelection()
</script>

<style lang="scss" scoped>
  .product-header {
    width: get-vw(572);
  }

  :deep(.grid-mood-content) {
    padding: get-vw($g-6);
    .product-grid-wrapper {
      display: flex;
      height: 100%;

      .product-grid {
        width: 100% !important;
        height: 100% !important;

        &:not(.show-color-name) .chip-color-text {
          display: none;
        }

        .product-header {
          margin-top: get-vw($g-4);
          padding: 0 get-vw($g-4);
        }

        .product-info {
          padding: 0 get-vw($g-4) get-vw($g-4) get-vw($g-4);
        }
      }
    }
  }
</style>
