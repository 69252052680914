<template>
  <div class="color-dots" :class="sizeClass">
    <span
      v-for="(color, index) in genColors"
      :key="`${color}_${index}`"
      class="dot"
      :style="{ color, zIndex: genColors.length - index }"
      :class="{ '--has-border': color.toLowerCase() === '#ffffff' }"
    />
    <icon-tag v-if="hasMoreColors" icon="icon-interface-plus" />
  </div>
</template>

<script lang="ts" setup>
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { GeneralSize } from '@/interface/ComponentSizes'
  import { computed, defineProps, withDefaults } from 'vue'

  const props = withDefaults(
    defineProps<{
      colors: string[]
      size?: GeneralSize
    }>(),
    {
      size: 'default',
    },
  )

  const maxColors = computed(() => (props.size !== 'small' ? 5 : 3))
  const sizeClass = computed(() => `size-${props.size}`)
  const genColors = computed(() => props.colors.slice(0, maxColors.value))
  const hasMoreColors = computed(() => props.colors.length > maxColors.value)
</script>

<style lang="scss" scoped>
  @mixin size-mixin($dot-size, $dot-border, $dot-mr, $icon-size) {
    .dot {
      width: get-vw($dot-size);
      height: get-vw($dot-size);
      margin-right: get-vw($dot-mr);
      border: get-vw($dot-border) solid $gray-100;
    }

    :deep(.v-icon) {
      height: get-vw($dot-size);
      font-size: get-vw($icon-size);

      &:before {
        margin-right: get-vw($dot-mr + 1);
      }
    }
  }

  .color-dots {
    display: flex;
    align-items: center;

    .dot {
      margin: 0;
      border: get-vw(2px) solid $gray-100;
      border-radius: 100%;
      background-color: currentColor;
      position: relative;

      &.--has-border {
        box-shadow: inset 0 0 0 get-vw(1px) $gray-200;
      }
    }

    &.size-large {
      @include size-mixin(20, 4, -8, 16);
    }
    &.size-medium {
      @include size-mixin(18, 3, -7, 14);
    }
    &.size-default {
      @include size-mixin(16, 3, -6, 12);
    }
    &.size-small {
      @include size-mixin(14, 2.5, -5, 10);
    }
  }
</style>
