<template>
  <div class="fullscreen">
    <video
      :key="video"
      ref="playerRef"
      crossorigin="use-credentials"
      class="fullscreen"
      :poster="poster"
      v-bind="videoAttributes"
    >
      <source :key="video" :src="buildAssetPath({ src: video })" />
    </video>
  </div>
</template>

<script lang="ts" setup>
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { useHandleVideoMessages } from '@/views/fullscreen-video/composables/useHandleVideoMessages'
  import { FullscreenVideoMessage } from '@/views/fullscreen-video/FullscreenVideo.interface'
  import { computed, onMounted, onUnmounted, ref } from 'vue'
  import buildAssetPath from '@/utils/buildAssetsPath'

  const screenID = 'FullscreenVideo'
  const props = defineProps<{ message: FullscreenVideoMessage; actionMessage?: string }>()

  const video = computed(() => props.message.content?.video)
  const poster = computed(() => buildAssetPath({ src: props.message.content?.thumb }))

  const playerRef = ref<HTMLElement | null>(null)
  const { onMessage, videoAttributes } = useHandleVideoMessages(props, playerRef)

  onMounted(() => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })
  })

  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID, onMessage })
  })
</script>

<style scoped lang="scss">
  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
