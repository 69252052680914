<template>
  <div class="whiteboard-canvas w-100 h-100">
    <div id="whiteboardContainer" ref="containerRef" class="whiteboard-canvas__wrapper">
      <div class="whiteboard-canvas__inner-wrapper" role="presentation">
        <Transition name="fade-component" mode="out-in" appear>
          <LayoutState
            v-if="isLoadingState"
            image-type="product"
            :image-src="state.isPrepare ? '' : state.lastImgLoaded"
            :bg-text="loadingBgText"
            :text="loadingText"
            :is-loading="!state.isPrepare"
            transition=""
          />
        </Transition>

        <div v-show="!isLoadingState" class="whiteboard-canvas__board">
          <div v-show="false" id="canvasBoardCopy" />
          <div id="canvasBoard" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import LayoutState from '@/components/layout/state/LayoutState.vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { t } from '@/utils/helpers'
  import { WhiteboardCanvasInterface } from '@/views/whiteboard-canvas/interface/WhiteboardCanvas.interface'
  import { useKonvaMessage } from '@/views/whiteboard-canvas/use/useKonvaMessage'
  import { onMounted, onUnmounted, reactive, ref, computed } from 'vue'
  import { iFreeDragState } from './interface/FreeDragState.i'

  const screenID = 'WhiteboardFreeDrag'
  const props = defineProps<{ message: WhiteboardCanvasInterface }>()
  const containerRef = ref<HTMLElement | null>(null)

  const state = reactive<iFreeDragState>({
    isPrepare: true,
    isLoading: false,
    isUpdating: false,
    lastImgLoaded: '',
    productTotal: 0,
    productCountLoaded: 0,
    isLoadingCopy: false,
    stage: null,
    stageCopy: null,
    imageSize: 920, // 920 or 400
    stageDefaultAttrs: undefined,
    isAborted: false,
    jsonString: '',
    pendingUpdates: [] as { method: string; args: any }[],
  })

  const { onMessage, prepareData } = useKonvaMessage(state, screenID, containerRef)

  const percentLoaded = computed(() => {
    if (state.productTotal <= 0 || state.productCountLoaded <= 0) return '0 %'
    const percent = (state.productCountLoaded / state.productTotal) * 100
    const percentLoaded = Math.min(100, Math.max(0, Number(percent.toFixed(0))))
    return percentLoaded + '%'
  })

  const isLoadingState = computed(() => state.isPrepare || state.isLoading)
  const loadingBgText = computed(() => (state.isPrepare ? t('whiteboard.starting') : percentLoaded.value))
  const loadingText = computed(() => (state.isPrepare ? t('whiteboard.waiting') : t('whiteboard.loader_text')))

  onMounted(async () => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })
    await prepareData(props.message.content?.jsonStructure)
  })

  onUnmounted(() => {
    state.isPrepare = false
    ShowroomScreen.removeObserver({ id: screenID, onMessage })
  })
</script>

<style lang="scss" scoped>
  .whiteboard-canvas {
    background: $white;
    position: relative;

    #canvasBoard,
    #canvasBoardCopy {
      height: 100%;
    }

    &__wrapper,
    &__inner-wrapper,
    &__board {
      width: 100%;
      height: 100%;
    }
  }
</style>
