import { UpdateMessage } from '@/interface/WebSocket'
import { defineStore } from 'pinia'
import { MeetSocketData } from '@/interface/MeetSocketData'

export const useGeneralStore = defineStore('general', {
  state: () => ({
    config: {} as Record<string, any>,
    screen: {
      screen_id: 0,
      view: '',
      content: {},
      state: {},
    } as UpdateMessage['data'],
    salesTableCode: undefined,
    screens: {
      mainScreenId: undefined,
      leftScreenId: undefined,
      rightScreenId: undefined,
    },
    meet: {
      connected: false,
      type: 'sales-table',
    } as MeetSocketData,
  }),
  actions: {
    setConfig(payload) {
      this.config = payload
    },
    setScreens(payload) {
      this.screens = payload
    },
    setMeet(payload: MeetSocketData) {
      this.meet = payload
    },
    resetMeet() {
      this.meet = {
        connected: false,
        type: 'sales-table',
      }
    },
    setSalesTableCode(payload) {
      this.salesTableCode = payload
    },
  },
})
