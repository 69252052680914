<template>
  <div class="checkout-content h-100 flex px-17 py-20">
    <div class="checkout-inner w-100 mt-auto color-white">
      <div class="headline">
        <Transition name="slide-up-headline" mode="out-in" appear>
          <div :style="{ 'transition-delay': '400ms' }" class="font-weight-600">{{ $t('checkout.thank_you') }}</div>
        </Transition>
      </div>
      <div class="content flex flex-col g-3">
        <div class="pt-3 heading-3">{{ subTitle }}</div>
        <div class="heading-6 sub-content text-white-600">{{ title }}</div>
      </div>
      <div class="advertising flex align-end">
        <slot name="advertising"></slot>
      </div>
      <div class="stats">
        <div class="stats-grid">
          <div class="top-left">
            <CardStats
              size="small"
              :label="$t('checkout.stats.items')"
              icon="icon-interface-grid-plus"
              :style="{ 'transition-delay': '200ms' }"
            >
              <CounterUp :end="stats.articles"></CounterUp>
            </CardStats>
          </div>
          <div class="top-right">
            <CardStats
              size="small"
              :label="$t('checkout.stats.quantity')"
              icon="icon-interface-stacks"
              :style="{ 'transition-delay': '400ms' }"
            >
              <CounterUp :end="stats.quantity"></CounterUp>
            </CardStats>
          </div>
          <div class="bottom-left">
            <CardStats
              size="small"
              :label="$t('checkout.stats.whs')"
              icon="icon-interface-basket"
              :style="{ 'transition-delay': '600ms' }"
            >
              <CounterUp :end="stats.whs" currency></CounterUp>
            </CardStats>
          </div>
          <div class="bottom-right">
            <CardStats
              size="small"
              :label="$t('checkout.stats.rrp')"
              icon="icon-interface-price"
              :style="{ 'transition-delay': '800ms' }"
            >
              <CounterUp :end="stats.rrp" currency></CounterUp>
            </CardStats>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import CardStats from '@/components/card/CardStats.vue'
  import { CheckoutContentStats } from '@/views/checkout-success/CheckoutSuccess.interface'
  import { toRef } from 'vue'
  import CounterUp from '@/components/partials/counter/CounterUp.vue'
  interface CheckoutContentProps {
    data: {
      title: string | undefined
      subTitle: string | undefined
      stats: CheckoutContentStats | undefined
    }
  }

  const defaultProps = {
    data: {
      title: 'Default Title',
      subTitle: 'Default Subtitle',
      stats: {
        items: 0,
        quantity: 0,
        whs: '',
        rrp: '',
      },
    },
  }
  const props = defineProps<CheckoutContentProps>()
  const title = toRef(props.data, 'title')
  const subTitle = toRef(props.data, 'subTitle')
  const stats = toRef(props.data, 'stats')
</script>
<style scoped lang="scss">
  .checkout-inner {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: auto auto;
    grid-template-columns: 47.92vw 1fr;
    gap: get-vw($g-12) get-vw($g-20);
    grid-template-areas:
      'headline content'
      'advertising stats';
    border-top: 1px solid rgba($white, 0.1);
    padding-top: get-vw($g-10);
    padding-bottom: get-vw($g-10);
  }

  .headline {
    font-size: get-vw(140px);
    grid-area: headline;
    line-height: 120%;
  }
  .content {
    grid-area: content;
  }
  .advertising {
    grid-area: advertising;
  }
  .stats {
    grid-area: stats;
  }

  .text-white-600 {
    color: rgba($white, 0.6);
  }
  :deep(.visual-box-side) {
    min-height: calc(160 * 100vw / var(--page-width));
  }

  .stats-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: $g-4;
    grid-template-areas:
      'top_left top_right'
      'bottom_left bottom_right';
  }

  .top-left {
    grid-area: top_left;
  }
  .top-right {
    grid-area: top_right;
  }
  .bottom-left {
    grid-area: bottom_left;
  }
  .bottom-right {
    grid-area: bottom_right;
  }

  .fade-enter-active {
    transition: all 0.5s ease-in-out;
  }
  .fade-enter-from {
    opacity: 0;
  }
  .fade-enter-to,
  .fade-enter-leave-to {
    opacity: 1;
  }
  .slide-up-headline {
    &-enter-active,
    &-leave-active {
      transition:
        opacity 1.8s cubic-bezier(0.25, 0.8, 0.25, 1),
        transform 2.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    &-enter-from {
      opacity: 0;
      transform: translateY(10vh);
    }
    &-leave-to {
      opacity: 0;
      transform: translateY(10vh);
    }
    &-enter-to,
    &-leave-from {
      opacity: 1;
      transform: translateY(0);
    }
  }
</style>
