<template>
  <div class="showcase-grid relative p-3 g-3">
    <div v-for="item in arrItems" class="grid-item">
      <Transition name="slide-from-left" mode="out-in" appear>
        <image-loader-bg
          v-if="item && item.path"
          :src="buildAssetPath({ src: item.path, thumb: 800 })"
          :style="{ transitionDelay: item.delay }"
          background-size="cover"
        />
      </Transition>
    </div>
    <div class="backdrop absolute inset-0 z-0"></div>
    <div v-if="$slots['default']" class="content absolute inset-0 z-1">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, onUnmounted, ref } from 'vue'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import ImageLoaderBg from '@/components/media/ImageLoaderBg.vue'
  import { useShowcaseLayout } from '@/components/grid/composables/useShowcaseLayout'

  let intervalHandler: ReturnType<typeof setInterval> | undefined
  const props = defineProps<{ images: string[] }>()
  const { arrItems, loadLayout, clearTimeOuts } = useShowcaseLayout(props.images)

  onMounted(() => {
    loadLayout()
    intervalHandler = setInterval(() => loadLayout(), 7400)
  })

  onUnmounted(() => {
    if (intervalHandler) {
      clearInterval(intervalHandler)
      intervalHandler = undefined
    }
    clearTimeOuts()
  })
</script>

<style lang="scss" scoped>
  .showcase-grid {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: repeat(4, 1fr);
    aspect-ratio: 16/9;
    background: linear-gradient(180deg, #0c0c0c 0%, #262626 100%);
  }
  .grid-item {
    aspect-ratio: 179/255;
    position: relative;
    overflow: hidden;
    will-change: opacity, transform;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .backdrop {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 100%);
  }
</style>
