<template>
  <div class="card-group" :class="[{ 'is-active': isActive }, { 'is-empty': isEmpty }]">
    <Transition name="fade-selected" mode="out-in" appear>
      <div v-if="wasProductAdded" class="overlay-selected" />
    </Transition>
    <div class="card-group-wrapper flex flex-column g-8">
      <!-- header -->
      <div class="header flex align-center justify-between w-100 g-4">
        <div class="flex align-center text-truncate-1">
          <IconTag class="header-icon" icon="icon-filled-arrow-circle-left" :size="16" />
          <span class="header-title text-truncate-1">{{ titleComputed }}</span>
        </div>
        <Transition name="fade-icon" mode="out-in" appear>
          <IconTag v-if="!wasProductAdded" :icon="headerIconRight" :size="16" />
        </Transition>
        <Transition name="fade-checkmark" mode="in-out" appear>
          <IconCheckmark v-if="wasProductAdded" class="header-checkmark" :size="16" />
        </Transition>
      </div>
      <!-- content -->
      <div class="content">
        <div v-if="!isEmpty" class="statistics flex align-start g-3">
          <div v-for="statistic in statistics" :key="statistic.title" class="flex flex-column g-2 text-truncate-1">
            <span class="title">{{ statistic.title }}</span>
            <span class="count text-truncate-1">
              <CounterUp :key="statistic.title" :end="statistic.count" :currency="statistic.type === 'currency'" />
            </span>
          </div>
        </div>
        <div v-else class="placeholder flex align-center justify-center g-4 h-100">
          <IconTag icon="icon-interface-frame" :size="16" />
          <span class="placeholder-text">{{ noResultsComputed }}</span>
        </div>
      </div>
      <!-- gallery -->
      <div class="gallery w-100">
        <GridContainer class="gallery-grid" :columns="galleryMax" :gap="1">
          <template v-for="(item, index) in galleryItems" :key="item.id || `empty-${index}`">
            <ProductColor
              :src="item.image"
              variant="image"
              class="card-group-product"
              :class="{ 'empty-item': !item.image }"
              :style="calculateGradient(index, !item.image)"
            >
              <OverlayContent v-if="showOverflow && index === galleryMax - 1" class="gallery-item-overlay">
                <span class="color-white text-body-2 font-weight-600"> +{{ overflowCount }} </span>
              </OverlayContent>
            </ProductColor>
          </template>
        </GridContainer>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import ProductColor from '@/components/content/product/ProductColor.vue'
  import GridContainer from '@/components/grid/GridContainer.vue'
  import CounterUp from '@/components/partials/counter/CounterUp.vue'
  import IconCheckmark from '@/components/partials/icon/IconCheckmark.vue'
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import OverlayContent from '@/components/partials/overlay/OverlayContent.vue'
  import { useCatalogStore } from '@/stores/catalog'
  import { t } from '@/utils/helpers'
  import { computed, onMounted, ref, watch } from 'vue'

  const props = withDefaults(
    defineProps<{
      type: 'group' | 'preselection'
      isActive: boolean
      title?: string
      count: number
      qty?: number
      whs?: number
      assigned?: number
      unassigned?: number
      gallery: { id: number; image: string }[]
    }>(),
    {
      title: '',
      qty: 0,
      whs: 0,
      assigned: 0,
      unassigned: 0,
    },
  )
  const catalogStore = useCatalogStore()

  const galleryMax = 10
  const isEmpty = computed(() => !props.gallery.length)
  const isPreselection = computed(() => props.type === 'preselection')
  const titleComputed = computed(() => (isPreselection.value ? t('notification.preselection') : props.title))
  const noResultsComputed = computed(() => t(isPreselection.value ? 'no_results.preselection' : 'no_results.group'))
  const headerIconRight = computed(() => (isPreselection.value ? 'icon-interface-heart' : 'icon-interface-grid'))

  const statistics = computed(() =>
    isPreselection.value
      ? [
          { title: t('common.item'), count: props.count, type: 'number' },
          { title: t('common.assigned'), count: props.assigned, type: 'number' },
          { title: t('common.unassigned'), count: props.unassigned, type: 'number' },
        ]
      : [
          { title: t('common.item'), count: props.count, type: 'number' },
          { title: t('common.quantity'), count: props.qty, type: 'number' },
          { title: t('common.whs'), count: props.whs, type: 'currency' },
        ],
  )

  const displayedGallery = ref<{ id: number; image: string }[]>([])
  const showOverflow = computed(() => props.gallery.length > galleryMax)
  const overflowCount = computed(() => props.count - galleryMax)
  const galleryItems = computed(() => {
    const items = props.gallery.slice(0, galleryMax)
    while (items.length < galleryMax) items.push({ id: -1, image: '' })
    return items
  })

  const wasProductAdded = ref(false)
  watch(() => props.count, checkWasProductAdded)
  function checkWasProductAdded(newValue: number, oldValue: number) {
    if (oldValue >= newValue) return

    if (props.type === 'preselection') {
      const { newEntries } = catalogStore.checkEntries()
      // check: has non-numeric new entry
      wasProductAdded.value = newEntries.some((entry) => typeof entry.value !== 'number')
      catalogStore.updateOldEntries()
    } else {
      wasProductAdded.value = true
    }
    setTimeout(() => (wasProductAdded.value = false), 3000)
  }

  watch(() => props.gallery, updateDisplayedGallery)
  function updateDisplayedGallery(newGallery: { id: number; image: string }[]) {
    displayedGallery.value = newGallery.slice(0, galleryMax)
  }

  const calculateGradient = (index: number, isEmptyItem: boolean) => {
    if (!isEmptyItem) return {}
    const emptyIndex = index - 1
    const total = galleryMax - 1
    const start = 1 - emptyIndex / total
    const end = 1 - (emptyIndex + 1) / total
    return {
      '--start': start.toFixed(2),
      '--end': end.toFixed(2),
    }
  }
  onMounted(() => {
    catalogStore.updateOldEntries()
    updateDisplayedGallery(props.gallery)
  })
</script>

<style lang="scss" scoped>
  $br: get-vw($r-4);

  .card-group {
    background: $white;
    position: relative;
    overflow: hidden;
    box-shadow: 0 get-vw(0.5) get-vw(1) 0 rgba-percent(#000, $opacity-100);
    border-radius: $br;

    .card-group-wrapper {
      padding: get-vw($g-6);
    }

    &.is-empty {
      .header-title {
        color: $gray-400;
      }
    }

    &.is-active {
      .header {
        .header-icon {
          opacity: 1;
          transition: opacity 0.5s linear;
        }

        .header-title {
          $header-title-margin: get-vw(30);
          width: calc(100% - $header-title-margin);
          transform: translateX($header-title-margin);
          transition: transform 0.5s;
        }
      }
    }

    .overlay-selected {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: get-vw($r-2) solid rgba-percent($emphasis, $opacity-100);
        border-radius: $br;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: get-vw($border-sm) solid rgba-percent($emphasis, $opacity-200);
        border-radius: $br;
      }
    }

    .header {
      position: relative;

      .header-icon {
        opacity: 0;
        position: absolute;
        min-width: get-vw(25);
        transition: opacity 0.2s linear;
      }
      .header-title {
        @include text-body($text-body-6, $font-weight-600);
        will-change: transform;
        transition: transform 0.5s;
      }

      .header-checkmark {
        background: $white;
        position: absolute;
        right: 0;
      }
    }

    .content {
      height: get-vw(50);

      .statistics {
        .title {
          @include text-body($text-body-2);
        }
        .count {
          @include text-body($text-body-6, $font-weight-600);
        }

        .flex-column:not(:last-child) {
          max-width: 25%;
        }
      }
      // content-placeholder
      .placeholder-icon {
        width: get-vw(16px);
        height: get-vw(16px);
      }
      .placeholder-text {
        @include text-body($text-body-5, $font-weight-600);
      }
    }

    .gallery {
      .gallery-item-overlay,
      .card-group-product {
        border-radius: get-vw(6);
      }
      .empty-item {
        background: linear-gradient(to right, rgba(246, 246, 246, var(--start)), rgba(246, 246, 246, var(--end)));
      }
      .card-group-product {
        position: relative;

        :deep(.image-content) {
          padding: get-vw(2);
        }
      }
    }
  }

  // animation
  .fade-selected {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.3s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-to,
    &-leave-from {
      opacity: 1;
    }
  }
  .fade-icon {
    &-enter-active {
      transition: opacity 1s ease-in-out;
    }
    &-leave-active {
      transition: opacity 0s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-to,
    &-leave-from {
      opacity: 1;
    }
  }
  .fade-checkmark {
    &-enter-active {
      transition: opacity 0s ease-in-out;
    }
    &-leave-active {
      transition: opacity 0.3s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-to,
    &-leave-from {
      opacity: 1;
    }
  }
</style>
