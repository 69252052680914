<template>
  <div class="fill-height-center">
    <div class="flex flex-col align-center g-12">
      <LoaderSpinner :color="color"></LoaderSpinner>
      <ChipTag variant="outlined" size="large" :color="color">
        <slot></slot>
      </ChipTag>
    </div>
  </div>
</template>
<script setup lang="ts">
  import ChipTag from '@/components/partials/chip/ChipTag.vue'
  import LoaderSpinner from '@/components/feedback/loader/LoaderSpinner.vue'
  import { computed } from 'vue'

  const props = defineProps({
    theme: {
      type: String,
      default: 'dark',
    },
  })

  const color = computed(() => (props.theme === 'light' ? 'white' : 'black'))
</script>
<style scoped lang="scss">
  .fill-height-center {
    display: grid;
    height: 100%;
    place-items: center;
  }
</style>
