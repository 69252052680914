<template>
  <div
    class="banner-navigation px-12 py-6 d-flex"
    :class="{ 'is-visible': isHomeNavigationAndBannerAlwaysVisible || isBannerNavigationVisible }"
  >
    <div class="left flex-1 d-flex align-center g-4">
      <div class="logo">
        <svg width="48" height="20" viewBox="0 0 48 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M39.7333 19.998C34.5867 19.998 31.4987 17.0634 31.4987 12.0963V7.93284C31.4987 2.96574 34.5867 0 39.7333 0C44.88 0 48 2.96574 48 7.93284V11.697H36.912V12.0963C36.912 14.3984 37.872 15.4975 39.8347 15.4975C41.7013 15.482 43.5307 14.9998 45.1467 14.0976L45.3813 13.9991L47.2747 17.6648L47.0773 17.7996C44.9333 19.2721 42.3627 20.0447 39.7387 19.998H39.7333ZM42.5867 8.30096V7.96913C42.5867 5.63594 41.6587 4.50046 39.7333 4.50046C37.808 4.50046 36.8107 5.66705 36.8107 7.96913V8.30096H42.5867ZM19.7867 19.6662V0.368125H24.4693L24.9013 2.16727C26.3573 0.886611 28.2187 0.124437 30.176 0H30.4107L30.9067 5.69816H30.608C28.6613 5.67224 26.7573 6.2685 25.1947 7.3988L25.1307 19.6662H19.7867ZM13.5787 0.368125H18.3253V19.6351H13.5787V0.368125ZM0 19.6662V0.368125H12.0853V4.59897H4.74667V8.46688H10.8213V12.6977H4.74667V19.6662H0Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="divider"></div>
      <div class="sales-table text-body-4 font-weight-600">Sales Table</div>
      <ChipTag v-if="hasSystemTag" :color="systemTag?.color" variant="tonal" size="large">{{
        systemTag?.text || ''
      }}</ChipTag>
    </div>
    <div class="middle">
      <CustomButton v-if="isSalesTable" :active="isSalesTable" class="btn-disconnect px-5" @click="closeSalesTable">
        <IconTag color="white" :size="16" icon="icon-interface-arrow-left"></IconTag>
        <span class="d-block font-weight-600 color-white">{{ $t('connect.back_home') }}</span>
      </CustomButton>
    </div>
    <div class="right g-4 flex-1 d-flex align-center justify-end">
      <LanguageSwitcher v-if="!isSalesTable"></LanguageSwitcher>
      <a
        v-if="!isSalesTable"
        class="website g-3 text-decoration-none"
        href="https://www.fire-digital.com"
        target="_blank"
      >
        <span class="text-uppercase text-body font-weight-600">www.fire-digital.com</span>
        <IconTag color="#fff" :size="16" icon="icon-interface-link"></IconTag>
      </a>
      <CustomButton v-if="isSalesTable" :active="isSalesTable" type="div" class="g-4 code-info px-5">
        <IconTag icon="icon-interface-connect"></IconTag>
        <span class="font-weight-600">{{ salesTableCode }}</span>
        <span class="code-color"></span>
      </CustomButton>
      <CustomButton class="fullscreen" :active="isSalesTable" @click="toggleFullscreen">
        <IconTag color="#fff" :size="16" :icon="genFullscreenIcon"></IconTag>
      </CustomButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { useFullscreen } from '@/composables/useFullscreen'
  import ChipTag from '@/components/partials/chip/ChipTag.vue'
  import { computed, onMounted, watch } from 'vue'
  import { useBannerNavigation } from '@/composables/useBannerNavigation'
  import { useRoute, useRouter } from 'vue-router'
  import LanguageSwitcher from '@/components/_common/LanguageSwitcher.vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import i18n from '@/plugins/i18n'
  import { useGeneralStore } from '@/stores/general'
  import CustomButton from '@/components/_common/button/CustomButton.vue'

  const route = useRoute()
  const router = useRouter()
  const props = withDefaults(
    defineProps<{
      system?: object | undefined
    }>(),
    {
      system: undefined,
    },
  )

  const generalStore = useGeneralStore()

  const { isFullscreen, toggleFullscreen } = useFullscreen()
  const isHomeNavigationAndBannerAlwaysVisible = computed(() => route.name === 'start')

  const isSalesTable = computed(() => route.name === 'sales-table')
  const hasSystemTag = computed(() => props.system)

  const systemTag = computed(() => {
    if (hasSystemTag.value) {
      return {
        text: props.system.label,
        color: props.system.color,
      }
    }
    return {}
  })

  const { isBannerNavigationVisible, activateMouseTrigger, deactivateMouseTrigger } = useBannerNavigation()
  const salesTableCode = computed(() => route.query?.screen_id)

  const genFullscreenIcon = computed(() =>
    isFullscreen.value ? 'icon-interface-exit-fullscreen' : 'icon-interface-enter-fullscreen',
  )

  function closeSalesTable() {
    ShowroomScreen.disconnectWS()
    generalStore.salesTableCode = undefined
    generalStore.resetMeet()
    router.push({ name: 'start', params: { lang: i18n.global.locale } })
  }

  watch(route, (value) => {
    if (value.name === 'sales-table') {
      isBannerNavigationVisible.value = false
      activateMouseTrigger()
    } else {
      deactivateMouseTrigger()
    }
  })

  onMounted(() => {
    if (route.name === 'sales-table') {
      activateMouseTrigger()
    }
  })
</script>

<style scoped lang="scss">
  .banner-navigation {
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    transition: top 0.6s ease-in-out;
    z-index: 10;
    background-image: $gradient-darken-down-60;
    &.is-visible {
      top: 0;
    }
  }
  .divider {
    height: get-vw(20);
    width: get-vw(1);
    background-color: rgba-percent($white, $opacity-100);
  }
  .sales-table {
    color: rgba-percent($white, $opacity-400);
  }

  .website {
    display: flex;
    flex-wrap: nowrap;
    span {
      line-height: 2;
      color: rgba-percent($white, $opacity-500);
    }
  }
  .code-info {
    width: auto;
    color: $white;
    height: get-vw(52);
    background-color: rgba-percent($black, $opacity-500);
    backdrop-filter: blur(16px);
  }
  .code-color {
    background-color: #129448;
    width: get-vw(12);
    height: get-vw(12);
    border-radius: 100vmax;
    display: block;
  }
  .btn-disconnect {
    position: fixed;
    bottom: 2vh;
    left: 2vh;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .is-visible {
    .btn-disconnect {
      opacity: 1;
    }
  }
</style>
