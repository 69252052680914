import { useActionMessageWatcher } from '@/composables/storybook/useActionMessageWatcher'
import { EmitMessage } from '@/interface/WebSocket'
import { fullscreenVideoActions } from '@/views/fullscreen-video/FullscreenVideo.actions'
import { FullscreenVideoMessage } from '@/views/fullscreen-video/FullscreenVideo.interface'
import { computed, nextTick, Ref } from 'vue'

export function useHandleVideoMessages(
  props: { message: FullscreenVideoMessage; actionMessage?: string },
  playerRef: Ref<any>,
) {
  const videoAttributes = computed(() => {
    const { loop, autoplay, muted, volume } = props.message.state
    return { loop, autoplay, volume: volume ? volume / 100 : 0, muted: !autoplay || muted }
  })

  const actionMessage = computed(() => props.actionMessage)
  useActionMessageWatcher({ actionMessage, actions: fullscreenVideoActions, onMessage })

  const isVideoMuted = computed(() => props.message.state?.videoIsMuted)

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      setMute: () => setMute(args.muted),
      setVolume: () => setVolume(args),
      pauseVideo: () => pauseVideo(),
      playVideo: () => playVideo(args?.currentTime),
      setTime: () => setTime(args?.currentTime),
    }

    actions[method]?.()
  }

  function setMute(isMuted: boolean) {
    if (!isVideoMuted.value) {
      playerRef.value.muted = isMuted
    }
  }
  function setVolume(v: number) {
    if (!isVideoMuted.value) {
      playerRef.value.volume = v > 0 ? v / 100 : 0
    }
  }

  async function playVideo(currentTime?: number) {
    setTime(currentTime)
    // It helps to bypass warnings when playing videos with sound
    playerRef.value.muted = true
    playerRef.value?.play()
    await nextTick()
    playerRef.value.muted = props.message.state.muted
  }
  function pauseVideo() {
    playerRef.value?.pause()
  }

  function setTime(currentTime?: number) {
    if (typeof currentTime === 'number' && playerRef.value) {
      playerRef.value.currentTime = currentTime
    }
  }

  return {
    onMessage,
    videoAttributes,
  }
}
