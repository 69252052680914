import { GeneralSize } from '@/interface/ComponentSizes'
import { Product } from '@/interface/messages/Product.interface'
import { Ref, ref, watch } from 'vue'

export function useProductSize(items: Ref<Product[]>) {
  const productSize = ref<GeneralSize>('medium')

  watch(() => items.value.length, setProductSize)

  function setProductSize(total = items.value.length) {
    productSize.value = total <= 3 ? 'medium' : total <= 8 ? 'default' : 'small'
  }

  return {
    setProductSize,
    productSize,
  }
}
