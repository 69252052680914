import { ref } from 'vue'

export function useCartDetailTransition() {

  const positionChanging = ref(false)
  function setPositionChanging() {
    positionChanging.value = true
    setTimeout(() => positionChanging.value = false, 200)
  }

  return {
    positionChanging,
    setPositionChanging
  }
}
