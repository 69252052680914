import { onBeforeUnmount, ref } from 'vue'
export function useBannerNavigation() {
  const isBannerNavigationVisible = ref(true)

  const mouseMoveTime = ref(null)

  function startCallbackTimer() {
    stopCallbackTimer()
    mouseMoveTime.value = setTimeout(() => {
      isBannerNavigationVisible.value = false
    }, 3000)
  }

  function stopCallbackTimer() {
    if (mouseMoveTime.value) {
      clearTimeout(mouseMoveTime.value)
      mouseMoveTime.value = null
    }
  }

  function onMouseMove() {
    isBannerNavigationVisible.value = true
    startCallbackTimer()
  }

  function activateMouseTrigger() {
    window.addEventListener('mousemove', onMouseMove)
    startCallbackTimer()
  }
  function deactivateMouseTrigger() {
    stopCallbackTimer()
    window.removeEventListener('mousemove', onMouseMove)
  }

  onBeforeUnmount(() => stopCallbackTimer())

  return {
    isBannerNavigationVisible,
    activateMouseTrigger,
    deactivateMouseTrigger,
  }
}
