<template>
  <component
    :is="isComponentType"
    class="custom-button rounded-3 overflow-hidden d-flex g-3 align-center justify-center"
    :class="genClass"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  type ButtonType = 'button' | 'div'

  const props = withDefaults(
    defineProps<{
      type?: ButtonType
      active?: boolean
    }>(),
    {
      type: 'button' as ButtonType,
      active: false,
    },
  )

  const isComponentType = computed(() => props.type)
  const genClass = computed(() => {
    return {
      'btn-active': props.active,
    }
  })
</script>

<style scoped lang="scss">
  .custom-button {
    min-width: get-vw(52);
    height: get-vw(52);
    backdrop-filter: blur(16px);
    background: rgba-percent($white, $opacity-100);
    &.btn-active {
      background: rgba-percent($black, $opacity-500);
    }
  }
</style>
