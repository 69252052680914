<template>
  <div class="carousel">
    <Transition name="fade-transition" mode="out-in" appear>
      <div v-if="isLoading" key="loader" class="carousel-loader flex justify-center align-center flex-column">
        <LoaderSpinner :size="40" color="black" />
      </div>
    </Transition>

    <Transition name="fade-carousel" mode="out-in" appear>
      <div key="carousel-container" class="carousel-container">
        <div class="canvas-container ratio-container">
          <div ref="canvasContainerRef" class="ratio-container--inner">
            <canvas id="scene" ref="canvasRef" />
          </div>
          <div class="item--info--container">
            <slot name="info" />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
  import LoaderSpinner from '@/components/feedback/loader/LoaderSpinner.vue'
  import eventHub from '@/utils/EventHub'
  import { DetailCarouselItem } from '@/views/collections/detail-carousel/DetailCarousel.interface'
  import { defineProps, defineEmits, onMounted, onBeforeUnmount, ref } from 'vue'
  import { Clock, TextureLoader, LinearFilter, WebGLRenderer, Scene, PerspectiveCamera, MathUtils } from 'three'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import Items from './service/Items.js'

  const emit = defineEmits(['ready', 'update:modelValue'])

  const props = defineProps<{ products: DetailCarouselItem[] }>()

  let renderer: WebGLRenderer
  let scene: Scene
  let camera: PerspectiveCamera

  const clock = ref<Clock>()
  const covers = ref<any[]>([])
  const infoBoxes = ref<any[]>([])
  const environment = ref<{ height: number; width: number }>()
  const height = ref(0)
  const width = ref(0)
  const slides = ref()
  const initRender = ref(false)
  const product = ref()
  const isLoading = ref(true)
  const canvasContainerRef = ref<HTMLElement | null>(null)
  const canvasRef = ref<HTMLCanvasElement>()
  const speed = ref(0)

  const loadTextures = async (): Promise<void> => {
    const loader = new TextureLoader()
    loader.crossOrigin = 'use-credentials'

    const promises = props.products.map((product, index) => {
      const imageSrc = product.image ? buildAssetPath({ src: product.image, thumb: 1000 }) : ''
      return loadTexture(loader, imageSrc, index)
    })

    try {
      const loadedTextures: any[] = await Promise.all(promises)
      loadedTextures.forEach(({ texture }) => covers.value.push(texture))
    } catch (error) {
      console.error('Error loading textures:', error)
    }
  }

  const loadTexture = (loader, url, index) => {
    return new Promise((resolve, reject) => {
      if (!url) {
        resolve({ texture: null, index })
        return
      }

      // load a resource
      loader.load(
        url,
        (texture) => {
          texture.generateMipmaps = false
          texture.minFilter = LinearFilter
          texture.magFilter = LinearFilter
          renderer.initTexture(texture)
          resolve({ texture, index })
        },
        undefined,
        (error) => {
          console.error('Error: Can not load texture.', error)
          reject(error)
        },
      )
    })
  }

  const createRenderer = () => {
    const boundingRect = canvasContainerRef.value?.getBoundingClientRect()
    if (!boundingRect) return

    width.value = boundingRect.width
    height.value = boundingRect.height

    renderer = new WebGLRenderer({ alpha: true, antialias: true, canvas: canvasRef.value })
    renderer.setSize(width.value, height.value)
    renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1)
  }

  const createScene = () => {
    scene = new Scene()

    camera = new PerspectiveCamera(45, width.value / height.value, 1, 800)
    camera.position.z = 150

    // Calculate Width/Height
    const fov = MathUtils.degToRad(camera.fov)
    const heightTemp = 2 * Math.tan(fov / 2) * camera.position.z
    const widthTemp = heightTemp * camera.aspect * 1.5

    environment.value = { height: heightTemp, width: widthTemp }
  }

  const resize = () => {
    const container = canvasContainerRef.value
    if (!container) return

    const boundingRect = container.getBoundingClientRect()

    width.value = boundingRect.width
    height.value = boundingRect.height

    slides.value.updateWidth(width.value)

    renderer.setSize(width.value, height.value)
    camera.aspect = width.value / height.value

    camera.updateProjectionMatrix()

    renderer.render(scene, camera)
  }

  const update = () => {
    initRender.value = true

    const time = clock.value?.getElapsedTime()

    if (slides.value) {
      slides.value.update(time, speed.value)
    }

    renderer.render(scene, camera)

    window.requestAnimationFrame(update)
  }

  const setActiveProduct = (index) => {
    product.value = props.products[index]
    emit('update:modelValue', product.value)
  }

  onMounted(() => {
    clock.value = new Clock()

    setTimeout(async () => {
      props.products.forEach((product) => {
        const productSelector = document.querySelector(`#product-${product.id}`)
        infoBoxes.value.push(productSelector)
      })

      createRenderer()
      createScene()
      window.addEventListener('resize', resize)

      await loadTextures()

      slides.value = new Items({
        covers: covers.value,
        environment: environment.value,
        items: props.products,
        scene: scene,
        infoBoxes: infoBoxes.value,
        width: width.value,
        setActiveProduct,
      })

      renderer.compile(scene, camera)
      update()

      if (props.products.length > 0) {
        product.value = props.products[0]
      }

      emit('ready')
      isLoading.value = false
    }, 10)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', resize)
    eventHub.off('topic-slider-update')
    renderer.dispose()
  })
</script>

<style lang="scss" scoped>
  .carousel {
    position: relative;
    overflow: hidden;

    .carousel-loader {
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .progress {
      width: 25vw;
    }
    .carousel-container {
      height: 100vh;
      display: flex;
      flex-direction: column;

      #scene {
        transform: scale(1.2) translateY(get-vw(-60px));
      }
    }

    .ratio-container {
      position: relative;
      margin-top: auto;
      height: 100%;
      &--inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .carousel-item {
      img {
        display: none;
      }
    }

    .item--info--container {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .categories-container {
      position: relative;

      > div {
        position: absolute;
        color: rgba(0, 0, 0, 0.05);
        font-size: 7vw;
        font-family: ivymode, sans-serif;
        font-weight: 600;
      }
    }

    .heading_category {
      font-weight: 200;
    }

    .heading span {
      font-weight: 100;
    }
  }

  .fade-carousel {
    &-enter-active,
    &-leave-active {
      transition: all 2s ease-in-out;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-to,
    &-leave-from {
      opacity: 1;
    }
  }
</style>
