import { useKonvaUpdate } from '@/views/whiteboard-canvas/use/useKonvaUpdate'
import { useKonvaUtils } from '@/views/whiteboard-canvas/use/useKonvaUtils'
import Konva from 'konva'
import { nextTick, unref } from 'vue'
import { iFreeDragState } from '../interface/FreeDragState.i'
import pako from 'pako'
import { useLoadImages } from './useLoadImages'

export function useKonvaCreate(state: iFreeDragState) {
  const { loadImages } = useLoadImages(state)
  const { updateWhiteboardConfig, updateCanvasBoardScale, updateShapeAttributes } = useKonvaUpdate(state)
  const { getMainLayer, getInfoGroup, updateItemScale } = useKonvaUtils(state)

  async function createWhiteboard(json: string | Record<string, any>) {
    state.isPrepare = false
    if (state.isAborted || state.isLoading) return

    state.isLoading = true
    state.jsonString = typeof json === 'string' ? json : JSON.stringify(json)

    const stage = Konva.Node.create(state.jsonString, 'canvasBoard') as Konva.Stage
    const mainLayer = stage?.findOne((node) => node.getAttr('name') === 'mainLayer') as Konva.Layer

    await loadAndPrepareLayer(mainLayer, 'load')

    if (state.stage) state.stage.destroy()

    state.stage = stage
    state.stage.container().style.backgroundColor = 'white'

    const infoGroup = getInfoGroup()
    const infoGroupElements = infoGroup.find((shape) => shape.attrs.type === 'information') as Konva.Group[]
    infoGroupElements.forEach(updateItemScale)

    await updateCanvasBoardScale()

    state.stageDefaultAttrs = state.stage.attrs
    state.isLoading = false

    await nextTick()
    await updateWhiteboard(state.jsonString)
  }

  async function updateWhiteboard(json: string | Record<string, any>) {
    state.isPrepare = false
    if (!state.stage || state.isAborted || state.isLoading || state.isUpdating) return
    if (state.stageCopy) state.stageCopy.destroy()

    state.isUpdating = true
    state.jsonString = typeof json === 'string' ? json : pako.inflate(json, { to: 'string' })
    state.stageCopy = Konva.Node.create(state.jsonString, 'canvasBoardCopy') as Konva.Stage

    const tempLayer = new Konva.Layer()
    tempLayer.add(...state.stageCopy?.getChildren().flatMap((child) => child?.getChildren()))

    await loadAndPrepareLayer(tempLayer, 'update')

    const mainLayer = getMainLayer()
    if (mainLayer) {
      mainLayer.removeChildren()
      mainLayer.add(...tempLayer.getChildren())
      await updateWhiteboardConfig(state.jsonString, false)
    }

    state.isUpdating = false
  }

  async function loadAndPrepareLayer(newLayer: Konva.Layer, type: 'load' | 'update') {
    const infoIcons = newLayer.find((shape) => shape.attrs.type === 'infoIcon') as Konva.Image[]
    // Remove unnecessary elements and update text elements
    newLayer
      .find((shape) => ['Transformer', 'multiSelectionBox'].includes(shape.attrs.name))
      ?.forEach((shape) => shape.remove())

    // Check Textarea style
    newLayer.children?.forEach((child) => {
      const { type } = child.attrs
      if (child instanceof Konva.Text && type === 'text') {
        child.sceneFunc(function (context, shape) {
          context.fillStyle = shape.attrs.backgroundFill
          context.fillRect(0, 0, shape.width(), shape.height())
          ;(shape as Konva.Text)._sceneFunc(context)
        })
      }
    })

    if (type === 'load') {
      const imageProducts = newLayer.find((shape) => shape.attrs.type === 'image-product') as Konva.Image[]
      const imageMedias = newLayer.find((shape) => shape.attrs.type === 'image-media') as Konva.Image[]
      await loadImages(imageProducts, true)
      await loadImages(imageMedias, false)
      await loadImages(infoIcons, false)
    } else if (type === 'update') {
      const newImagesToLoad: Konva.Image[] = []

      newLayer.getChildren().forEach((newShape) => {
        const isTypeProductOrMedia = ['image-product', 'image-media'].includes(newShape.attrs.type)
        const existingShape = state.stage?.findOne((oldShape) => oldShape.attrs.name === newShape.attrs.name)

        if (existingShape) {
          const wasImagePathEdit = newShape.attrs.path && existingShape.attrs.path !== newShape.attrs.path
          if (wasImagePathEdit) newImagesToLoad.push(newShape as Konva.Image)
          updateShapeAttributes(existingShape, newShape)
        } else {
          if (isTypeProductOrMedia) {
            newImagesToLoad.push(newShape as Konva.Image)
          }
        }
      })

      const newImageProducts = newImagesToLoad.filter((img) => img.attrs.type === 'image-product')
      const newImageMedias = newImagesToLoad.filter((img) => img.attrs.type === 'image-media')

      await loadImages(newImageProducts, true)
      await loadImages(newImageMedias, false)
      await loadImages(infoIcons, false)

      // Update the newLayer shapes rather than the state.stage shapes
      await updateStageImages(newLayer)
    }

    newLayer.listening(false)
  }

  async function updateStageImages(newLayer: Konva.Layer) {
    const relevantShapeTypes = ['image-product', 'image-media', 'text']
    const currentShapes = state.stage?.find(({ attrs }) => relevantShapeTypes.includes(attrs.type)) as Konva.Shape[]
    const newShapes = newLayer.find(({ attrs }) => relevantShapeTypes.includes(attrs.type)) as Konva.Shape[]

    for (let i = currentShapes.length - 1; i >= 0; i--) {
      const current = unref(currentShapes[i])
      const newShape = newShapes.find((s) => s.attrs.name === current.attrs.name)
      if (newShape) {
        const isImageProduct = newShape.attrs.type === 'image-product'
        const isImageMedia = newShape.attrs.type === 'image-media'

        if (isImageProduct) {
          const isWidthEqual = current.width() === newShape.width()
          const isHeightEqual = current.height() === newShape.height()
          const width = isWidthEqual ? current.width() : newShape.attrs.dw
          const height = isHeightEqual ? current.height() : newShape.attrs.dh
          current.setAttrs({ ...newShape.attrs, width, height })
        } else if (isImageMedia) {
          const imageSize = current.attrs.imageSize
          const height = current.height() || 200
          const width = (height / imageSize.height) * imageSize.width
          current.setAttrs({ ...newShape.attrs, width, height })
        } else {
          current.setAttrs({ ...newShape.attrs })
        }

        newShape.setAttrs({ ...current.attrs }) // Update the newShape with the current attributes
      } else {
        currentShapes.splice(i, 1)
      }
    }
  }

  return {
    createWhiteboard,
    updateWhiteboard,
  }
}
