import { useActionMessageWatcher } from '@/composables/storybook/useActionMessageWatcher'
import useAudioData from '@/composables/useAudioData'
import { EmitMessage } from '@/interface/WebSocket'
import { useDetailAnimation } from '@/views/collections/detail-hero/composables/useDetailAnimation'
import { detailHeroActions } from '@/views/collections/detail-hero/DetailHero.actions'
import { DetailHeroMessage } from '@/views/collections/detail-hero/DetailHero.interface'
import { computed, ref, watch } from 'vue'

export function useDetailHeroMessages(props: { message: DetailHeroMessage; actionMessage?: string }) {
  const item = ref(props.message.content?.item)

  const actionMessage = computed(() => props.actionMessage)
  useActionMessageWatcher({ actionMessage, actions: detailHeroActions, onMessage })

  const { setMute, stopSound, playSound } = useAudioData()
  const { animDetailIn, animDetailOut, closeAnimBackground, changeDetail } = useDetailAnimation(item)
  function onMessage(message: EmitMessage) {
    if (message.event === 'emit' && message.data.emit) {
      const { method, args } = message.data.emit

      const actions: Record<string, () => void> = {
        changeDetail: () => changeDetail(args),
        detailOut: () => animDetailOut(),
        playSound: () => playSound(args),
        stopSound: () => stopSound(),
        animDetailIn: () => animDetailIn(),
        animDetailOut: () => animDetailOut(),
        closeBackground: () => closeAnimBackground(),
        setMute: () => setMute(args),
      }
      actions[method]?.()
    }
  }

  watch(
    () => props.message.content?.item,
    (newItem) => (item.value = newItem),
  )

  return {
    item,
    onMessage,
    animDetailIn,
  }
}
