import { computed, ref } from 'vue'
import { useProductSizes } from '@/composables/sizes/useProductSizes'
import { Product } from '@/interface/messages/Product.interface'

export function useProductDetail(props) {
  const { createProductSizesSortedByGroup, groupedSizeGrid } = useProductSizes()

  const isEmpty = ref(false)
  const scrollPercent = ref(0)
  const itemSizes = computed(() => props.message?.content?.item?.sizes)
  const viewType = ref('product')
  const viewData = ref()
  const nextAnimation = ref(undefined)
  const isAnimating = ref(false)

  const updateView = (args) => {
    if (isAnimating.value) {
      nextAnimation.value = args
      return
    }
    isEmpty.value = false
    viewType.value = args.view
    scrollPercent.value = 0

    switch (args.view) {
      case 'information':
        setInformationData(args.information)
        break
      case 'feature':
        setFeatureData(args.features)
        break
      case 'size':
        setSizeData()
        break
      case 'related':
        setRelatedData(args)
        break
      default:
        console.log('default')
    }
  }

  function setInformationData(data) {
    viewData.value = data
    if (!data || data.length === 0) {
      isEmpty.value = true
    }
  }

  function setFeatureData(data) {
    viewData.value = data
    if (!data || data.length === 0) {
      isEmpty.value = true
    }
  }

  function setSizeData() {
    createProductSizesSortedByGroup(itemSizes)
    const groupedValue = groupedSizeGrid.value
    viewData.value = groupedValue
    if (!groupedValue || groupedValue.length === 0) {
      isEmpty.value = true
    }
  }

  function setRelatedData(args: { activeTab: number; tabName: string; items: Product[] }) {
    viewData.value = {
      activeTab: args.activeTab || 0,
      tabName: args.tabName,
      items: args.items,
    }
    if (!args.items || args.items.length === 0) {
      isEmpty.value = true
    }
  }

  return {
    isEmpty,
    scrollPercent,
    viewType,
    viewData,
    isAnimating,
    updateView,
  }
}
