const createEmitAction = (method, args = {}) => ({
  event: 'emit',
  data: { screen_id: 90001, emit: { method, args } },
})

export const catalogActions = {
  setMultipleSelection_all: createEmitAction('setMultipleSelection', [12345, 123456]),
  setMultipleSelection_clear: createEmitAction('setMultipleSelection', []),
  setPerspective_front: createEmitAction('setPerspective', 'front'),
  setPerspective_back: createEmitAction('setPerspective', 'back'),
  scrollToVirtualScrollItem_5: createEmitAction('scrollToVirtualScrollItem', 5),
  scrollToBottom: createEmitAction('scrollToBottom'),
  scrollPercent_10: createEmitAction('scrollPercent', 10),
  jumpToPercent_10: createEmitAction('jumpToPercent', 10),
  jumpToVirtualScrollItem_5: createEmitAction('jumpToVirtualScrollItem', 5),
}
