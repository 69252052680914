import buildAssetPath from '@/utils/buildAssetsPath'
import { computed, ref, watch } from 'vue'

export function useImageSlider(props: { images: string[]; showImageSlider: boolean }) {
  const intervalId = ref()
  const currentImageIndex = ref(0)

  const images = computed(() => props.images.filter(Boolean))
  const currentImage = computed(() => images.value[currentImageIndex.value])
  const currentImagePath = computed(() => buildAssetPath({ src: currentImage.value, thumb: 2000 }))

  watch(() => props.showImageSlider, updateSliderState)

  function updateSliderState() {
    const isSliderActive = props.showImageSlider && images.value.length > 1

    if (isSliderActive) {
      intervalId.value = setInterval(() => {
        currentImageIndex.value = (currentImageIndex.value + 1) % images.value.length
      }, 10000)
    } else {
      if (intervalId.value) {
        clearInterval(intervalId.value)
        intervalId.value = null
      }
      currentImageIndex.value = 0
    }
  }

  function clearSliderInterval() {
    if (!intervalId.value) return
    clearInterval(intervalId.value)
  }

  return {
    currentImagePath,
    updateSliderState,
    clearSliderInterval,
  }
}
