import { sizesEnum } from '@/views/cart-detail/cartDetail.enum'

export function useSizesGridOptions() {

  const options = [
    { maxItems: 12, grid: 4, gap: 4, size: sizesEnum.LARGE },
    { maxItems: 15, grid: 5, gap: 3, size: sizesEnum.MEDIUM },
    { maxItems: 24, grid: 6, gap: 2, size: sizesEnum.DEFAULT },
    { maxItems: 35, grid: 7, gap: 2, size: sizesEnum.SMALL },
  ]

  function getOption(itemsLength: number) {
    for (const option of options) {
      if (itemsLength <= option.maxItems) {
        return option
      }
    }
    return { grid: 8, gap: 1, size: sizesEnum.SMALL }
  }

  function getMultiGridsDefault() {
    return { grid: 8, gap: 1, size: sizesEnum.SMALL }
  }

  return {
    getMultiGridsDefault,
    getOption
  }
}
