import { computed, ComputedRef } from 'vue'
import { useSettingsStore } from '@/stores/settings'

export function usePositionDates(position: ComputedRef<{ exceptionDate?: string, requestedDate?: string }>) {

  const { extendedDates }: { extendedDates: string[]} = useSettingsStore()

  const DATES_TYPES: string[] = ['exceptionDate', 'desiredDate', 'requestedDate']
  const secondDateSpecificKey = computed(() => {
    return DATES_TYPES.find((dateKey) => extendedDates.includes(dateKey))
  })

  // requestedDate = desiredDate, on config its desiredDate and we use requestedDate app
  const secondDateField = computed(() => {
    const dates = {
      'exceptionDate':  {
        icon: 'icon-interface-exception-date',
        text: position.value?.exceptionDate
      },
      'desiredDate': {
        icon: 'icon-interface-desired-date',
        text: position.value?.requestedDate
      }
    }
    return secondDateSpecificKey.value ? dates[secondDateSpecificKey.value] : false
  })

  return {
    secondDateField
  }
}
