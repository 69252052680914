import { t } from '@/utils/helpers'
import { DetailGridMessage } from '@/views/collections/detail-grid/DetailGrid.interface'
import { computed } from 'vue'

export function useDetailGridContent(props: { message: DetailGridMessage; actionMessage?: string }) {
  const item = computed(() => content.value.item)
  const items = computed(() => content.value.items)
  const content = computed(() => props.message.content)
  const images = computed(() => item.value.images.filter(Boolean))
  const showDetails = computed(() => content.value?.showDetails === 1 && items.value.length <= 8)
  const textEmpty = computed(() => (content.value.type === 'looks' ? t('no_results.look') : t('no_results.capsule')))

  const title = computed(() => (!item.value?.hideTitle && !item.value?.useCustomTitle ? item.value.title : ''))
  const customTitle = computed(() =>
    !item.value?.hideTitle && item.value?.useCustomTitle ? item.value.custom_title : '',
  )

  return {
    item,
    items,
    content,
    images,
    showDetails,
    textEmpty,
    title,
    customTitle,
  }
}
