import buildAssetPath from '@/utils/buildAssetsPath'
import Konva from 'konva'
import { iFreeDragState } from '../interface/FreeDragState.i'
import { useKonvaUtils } from './useKonvaUtils'

export function useLoadImages(state: iFreeDragState) {
  const { imagePlaceholderProduct, imagePlaceholderMedia, loadKonvaImageFromIconSet, getItemType } =
    useKonvaUtils(state)

  async function loadImages(children: Konva.Image[], showLoader: boolean) {
    if (showLoader) {
      state.productTotal = children.length
      state.productCountLoaded = 0
      state.imageSize = state.productTotal < 100 ? 920 : 400
    }

    for (const el of children) {
      if (state.isAborted) return

      const { type, width, height, dw, dh, scaleX = 1, scaleY = 1 } = el.attrs

      const { isImageProduct, isImageMedia } = getItemType(type)
      const isInstanceImage = el instanceof Konva.Image

      if (!isInstanceImage) continue

      const image = el as Konva.Image // Type assertion, telling TypeScript that el is an instance of Konva.Image
      const itemSrc = image.getAttr('path') || image.getAttr('src') || image.getAttr('imagePath')

      if (type === 'infoIcon') {
        const name = image.getAttr('name')
        const options = image.attrs // You might need to specify other relevant attributes
        const konvaImage = loadKonvaImageFromIconSet(name, options)
        image.image(konvaImage.image())
        image.getLayer()?.batchDraw()
        continue
      }

      const src = buildAssetPath({ src: itemSrc, thumb: state.imageSize }) // 920 | 400

      if (!src) {
        console.warn('Image node with no src attribute:', el)
        continue
      }

      let hasImageError
      await new Promise<void>((resolve, reject) => {
        const imageObj = new Image()

        imageObj.onload = () => {
          image.image(imageObj)
          if (isImageProduct) {
            const defWidth = width || dw
            const defHeight = height || dh
            const scaleFactoX = (defWidth * scaleX) / imageObj.width
            const scaleFactoY = (defHeight * scaleY) / imageObj.height
            image.scaleX(scaleFactoX)
            image.scaleY(scaleFactoY)
          } else if (isImageMedia) {
            image.attrs.state = 'update-without-scale'
            // set width & height
            const mediaSize = height || 200
            const newWidth = (mediaSize / imageObj.height) * imageObj.width
            image.attrs.imageSize = { width: imageObj.width, height: imageObj.height }
            image.width(newWidth)
            image.height(mediaSize)
          }

          image.getLayer()?.batchDraw()
          resolve()
        }

        imageObj.onerror = (error) => {
          hasImageError = true
          const placeholderImg = new Image()
          if (isImageProduct) {
            placeholderImg.src = imagePlaceholderProduct
            const defWidth = width || dw
            const defHeight = height || dh
            const scaleFactoX = (defWidth * scaleX) / defWidth
            const scaleFactoY = (defHeight * scaleY) / defHeight
            image.scaleX(scaleFactoX)
            image.scaleY(scaleFactoY)
            image.width(dw)
            image.height(dh)
          } else if (isImageMedia) {
            placeholderImg.src = imagePlaceholderMedia
            image.attrs.state = 'update-without-scale'
            image.width(200)
            image.height(200)
          }

          placeholderImg.onload = () => {
            image.image(placeholderImg)
            resolve()
          }

          placeholderImg.onerror = () => {
            reject(new Error('Failed to load both the image and the placeholder.'))
          }
        }

        imageObj.src = src
      })

      if (showLoader) {
        state.productCountLoaded++

        if ((state.productCountLoaded === 1 || state.productCountLoaded % 10 === 0) && !hasImageError) {
          state.lastImgLoaded = src
        }
      }
    }
  }

  return {
    loadImages,
  }
}
