<template>
  <div class="banner-notification" :class="[msgTypeClass, variantClass]">
    <div class="container">
      <div class="preview">
        <IconTag v-if="variant === 'stacked'" icon="icon-interface-select" />
        <ImageLoader v-else :src="productImage" />
      </div>

      <div class="content flex flex-col justify-center g-3">
        <div class="content-header flex g-4">
          <div class="title text-truncate-1">{{ title }}</div>
          <IconCheckmark v-if="msgType === 'added'" :size="20" />
          <IconTag v-else-if="msgType === 'removed'" class="title-icon" icon="icon-interface-delete" :size="20" />
          <IconTag v-else-if="msgType === 'already-added'" class="title-icon" icon="icon-interface-empty" :size="20" />
        </div>
        <!-- added/removed to/from Group/Preselection -->
        <div v-if="variant !== 'empty'" class="content-text flex g-2 text-truncate-1">
          <i18n-t :keypath="notification.keyPath">
            <template v-slot:icon>
              <IconTag class="content-text-icon" :icon="notification.icon" />
            </template>
            <template v-slot:name>
              <strong class="content-text-group text-truncate-1">{{ notification.name }}</strong>
            </template>
          </i18n-t>
        </div>
      </div>
    </div>
    <template v-if="variant === 'stacked'">
      <div class="stacked-shadow-1"></div>
      <div class="stacked-shadow-2"></div>
    </template>
  </div>
</template>

<script lang="ts" setup>
  import { FeedbackNotificationInterface } from '@/components/feedback/notification/FeedbackNotification.interface'
  import ImageLoader from '@/components/media/ImageLoader.vue'
  import IconCheckmark from '@/components/partials/icon/IconCheckmark.vue'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { t } from '@/utils/helpers'
  import { computed } from 'vue'
  import IconTag from '@/components/partials/icon/IconTag.vue'

  // const emit = defineEmits(['close-toast'])
  const props = withDefaults(defineProps<FeedbackNotificationInterface>(), {
    variant: 'default',
    title: '',
    groupName: '',
    imageSrc: '',
  })

  const msgTypes = {
    added: 'notification.item_added_to',
    removed: 'notification.item_removed_from',
    'already-added': 'notification.item_already_added_to',
  }

  const msgTypeClass = computed(() => `msg-type-${props.msgType}`)
  const variantClass = computed(() => `variant-${props.variant}`)
  const productImage = computed(() => buildAssetPath({ src: props.imageSrc, thumb: 400 }))

  const notification = computed(() => {
    const keyPath = msgTypes[props.msgType]
    const group = { keyPath, icon: 'icon-interface-grid', name: props.groupName }
    const preselection = { keyPath, icon: 'icon-interface-heart', name: t('notification.preselection') }
    return props.type === 'group' ? group : preselection
  })
</script>

<style lang="scss" scoped>
  .banner-notification {
    position: relative;
    width: get-vw(480);
    height: auto;

    .container {
      display: flex;
      padding: get-vw($g-3) get-vw($g-7) get-vw($g-3) get-vw($g-3);
      gap: get-vw($g-6);
      border-radius: get-vw($r-5);
      background: $white;
      box-shadow: $shadow-bottom-500;
      overflow: hidden;
      position: relative;
      z-index: 3;
    }

    .preview {
      display: flex;
      place-items: center;
      align-self: stretch;
      justify-content: center;
      max-width: get-vw(80);
      min-width: get-vw(80);
      height: get-vw(80);
      border-radius: get-vw($r-3);
      background: $gray-100;
      padding: get-vw($g-1);
      color: $black;

      img {
        border-radius: get-vw($r-3);
        width: 100%;
        height: auto;
      }

      :deep(.v-icon) {
        font-size: get-vw(24);
      }
    }

    .content {
      width: get-vw(336);
      gap: get-vw($g-3);

      .content-header {
        width: 100%;
        align-self: stretch;
        grid-auto-flow: column;
        align-items: center;
        flex: initial;

        .title {
          color: $black;
          width: 100%;
          @include text-body($text-body-6, $font-weight-600);
        }
        .title-icon {
          color: $black;
        }
      }

      .content-text {
        width: 100%;
        @include text-body($text-body-4, $font-weight-600);
        color: $gray-400;
        align-items: center;
        flex: initial;
      }
    }

    // variants
    &.variant-empty {
      .title {
        font-size: get-vw($text-body-4) !important;
      }
    }
    &.variant-stacked {
      margin-bottom: get-vw($r-5);
      .stacked-shadow-1,
      .stacked-shadow-2 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: get-vw(80);
        background: $white;
        box-shadow: $shadow-bottom-500;
        border-bottom-left-radius: get-vw($r-3);
        border-bottom-right-radius: get-vw($r-3);
      }

      .stacked-shadow-1 {
        width: get-vw(460);
        bottom: get-vw(-10);
        z-index: 2;
      }

      .stacked-shadow-2 {
        width: get-vw(440);
        bottom: get-vw(-20);
        z-index: 1;
      }
    }

    // msg types
    &.msg-type-added {
      .content-text-icon,
      .content-text-group {
        color: $black;
      }
    }
    &:not(.msg-type-added) {
      .content-text-icon,
      .content-text-group {
        color: $warning;
      }
    }
  }
</style>
