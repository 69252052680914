<template>
  <showcase-grid :images="gridImages">
    <Transition name="slide-down-start" mode="out-in" appear>
      <LoaderChip v-if="isLoadingState" theme="light">Sending Order</LoaderChip>
      <CheckoutSuccessContent v-else :data="contentData">
        <template v-slot:advertising>
          <img class="logo" :src="logo" alt="" />
        </template>
      </CheckoutSuccessContent>
    </Transition>
  </showcase-grid>
</template>
<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { CheckoutSuccessMessage } from '@/views/checkout-success/CheckoutSuccess.interface'
  import ShowcaseGrid from '@/components/grid/GridShowcase.vue'
  import CheckoutSuccessContent from '@/components/content/checkout/CheckoutSuccessContent.vue'
  import LoaderChip from '@/components/feedback/loader/LoaderChip.vue'
  import buildAssetPath from '@/utils/buildAssetsPath'

  const props = defineProps<{ message: CheckoutSuccessMessage }>()

  const gridImages = ref<string[]>([])

  const logo = computed(() => buildAssetPath({ src: props.message.content?.logo }))

  const moodImages = computed(() => props.message?.content?.moodImages)
  watch(
    moodImages,
    (value) => {
      if (value && value.length) {
        gridImages.value = value
      }
    },
    {
      immediate: true,
    },
  )
  const contentData = computed(() => {
    return {
      title: props.message?.content?.title,
      subTitle: props.message?.content?.subTitle,
      stats: props.message?.content?.stats,
    }
  })
  const isLoadingState = computed(() => props.message?.state?.loading)
</script>
<style lang="scss" scoped>
  .logo {
    height: get-vw(20);
    width: auto;
  }
</style>
