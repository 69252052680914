import { nextTick, Ref } from 'vue'

export function useOverviewSliderHandler(
  scrollContainerRef: Ref<HTMLElement | null>,
  state: {
    sliderPosition: number
    containerWidth: number
    dragIsActive: boolean
  },
) {
  async function resetSliderPosition() {
    state.sliderPosition = 0
    await updateContainerWidth()
  }

  function updateContainerTransformStyle(newSliderPosition: number) {
    if (scrollContainerRef.value) {
      scrollContainerRef.value.style.transform = `translate3d(${newSliderPosition}px, 0, 0)`
    }
  }

  async function updateContainerWidth() {
    await nextTick()
    state.containerWidth = scrollContainerRef.value?.getBoundingClientRect().width || 0
  }

  return {
    resetSliderPosition,
    updateContainerTransformStyle,
    updateContainerWidth,
  }
}
