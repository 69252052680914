import { ProductTagStyle } from '@/interface/ProductTagStyle.interface'
import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    screenType: '' as 'showroom' | 'salesTable',
    currency: null,
    language: null,
    dateFormat: null,
    priceFormat: null,
    showTypeWhs: null,
    showTypeRrp: null,
    hideLooksTitle: null,
    productTagStyles: [] as ProductTagStyle[],
    extendedDates: [],
  }),
  actions: {
    setSettings({
      screenType,
      currency,
      language,
      dateFormat,
      priceFormat,
      showTypeRrp,
      showTypeWhs,
      hideLooksTitle,
      productTagStyles,
      extendedDates,
    }) {
      this.screenType = screenType
      this.currency = currency
      this.language = language
      this.dateFormat = dateFormat
      this.priceFormat = priceFormat
      this.showTypeRrp = showTypeRrp
      this.showTypeWhs = showTypeWhs
      this.hideLooksTitle = hideLooksTitle
      this.productTagStyles = productTagStyles
      this.extendedDates = extendedDates
    },
  },
})
