<template>
<div class="missing-sizes mt-6 d-grid g-1">
  <div class="text-body-1 color-600">{{ $t('common.not_available') }}</div>
  <div class="text-body-3 font-weight-600 text-truncate-1">
    {{ missingSizes }}
  </div>
</div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  data: {
    type: Array,
    default: () => ([])
  }
})
const missingSizes = computed(() => props.data?.join(', '))
</script>

<style scoped lang="scss">

</style>
