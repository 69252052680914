import useAudioData from '@/composables/useAudioData'
import { DetailHeroMessage } from '@/views/collections/detail-hero/DetailHero.interface'
import { gsap } from 'gsap'
import { Ref } from 'vue'
export function useDetailAnimation(item: Ref<DetailHeroMessage['content']['item']>) {
  const { isMuted, volume, playSound } = useAudioData()

  const DOM = {
    topic_list: null,
    details_image: null,
    details_content: null,
    items: {},
    el: null as Element | null,
  }

  const tween = {
    sliderGapBetween: null,
    firstInit: false,
    isAnimating: false,
    firstItem: {
      width: null,
      height: null,
    },
    target: {
      width: null,
      height: null,
      locationX: null,
      locationY: null,
      centerX: null,
      centerY: null,
      scale: 1.4,
    },
    items: [],
  }

  const timelines = {
    gridToSlider: null,
    slideOutSlider: null,
    sliderToDetail: null,
    nextTopic: null as any,
    sliderDetail: null as any,
  }

  const animDetailIn = () => {
    if (!DOM.el) {
      DOM.el = document.querySelector('.detail-hero')
    }

    tween.firstInit = true
    timelines.sliderDetail = gsap
      .timeline({
        onComplete: () => {
          tween.firstInit = false
          tween.isAnimating = false
        },
      })
      .timeScale(1.5)

    // let imageBg = document.querySelector('.details--background');
    const imageBg = document.querySelector('.detail__section-image-background')
    const contentBg = document.querySelector('.detail__section-content-background')
    const currentImage = DOM.el?.querySelector('.topic--detail--image')
    const contentTitle = DOM.el?.querySelector('.content--title')
    const contentText = DOM.el?.querySelector('.content--description')
    const contentImageFirst = DOM.el?.querySelector('.content__image--first')
    const contentImageSecond = DOM.el?.querySelector('.content__image--second')
    const contentImageThird = DOM.el?.querySelector('.content__image--third')
    const revealImage = DOM.el?.querySelector('.content__image--reveal')

    const delay = 0
    if (tween.firstInit) {
      timelines.sliderDetail
        .to(imageBg, { duration: 0, ease: 'power3.inOut', height: '100%' }, 0)
        .to(contentBg, { duration: 0, ease: 'power3.inOut', height: '100%' }, 0)
    }

    timelines.sliderDetail
      .set(contentImageFirst, { opacity: 1, zIndex: 10 }, 0)
      .to(currentImage, { duration: 1.5, ease: 'power3.inOut', top: '0', opacity: 1 }, delay)
      .to(revealImage, { duration: 1, ease: 'power3.inOut', x: '-100%' }, delay)
      .to(contentImageSecond, { duration: 1, ease: 'power3.inOut', x: '-11vw', y: '3vh', opacity: 1 }, delay + 0.75)
      .to(
        contentImageThird,
        {
          duration: 1,
          ease: 'power3.inOut',
          x: '10vw',
          y: '18vh',
          opacity: 1,
        },
        delay + 0.75,
      )
      .to(
        contentTitle,
        {
          duration: 1,
          ease: 'power3.inOut',
          opacity: 1,
          y: -25,
        },
        delay,
      )
      .to(
        contentText,
        {
          duration: 1,
          ease: 'power3.inOut',
          opacity: 1,
          y: -25,
        },
        delay + 0.25,
      )
  }
  const animDetailOut = () => {
    if (tween.isAnimating) return

    tween.isAnimating = true

    const currentImage = DOM.el?.querySelector('.topic--detail--image') as any
    const contentTitle = DOM.el?.querySelector('.content--title')
    const contentText = DOM.el?.querySelector('.content--description')
    const contentImageScale = DOM.el?.querySelector('.content__image--first .v-image__image')
    const contentImageSecond = DOM.el?.querySelector('.content__image--second')
    const contentImageThird = DOM.el?.querySelector('.content__image--third')
    const revealImage = DOM.el?.querySelector('.content__image--reveal')

    timelines.nextTopic = gsap.timeline().timeScale(2)

    timelines.nextTopic
      .to(
        currentImage,
        {
          duration: 1,
          ease: 'power3.inOut',
          y: 0,
          scale: 1.1,
          opacity: 0,
          onComplete: function () {
            gsap.set(currentImage, { clearProps: 'all' })
          },
        },
        0,
      )
      .set(
        revealImage,
        {
          x: '+100%',
        },
        0,
      )
      .to(
        contentText,
        {
          duration: 0.5,
          ease: 'power3.inOut',
          opacity: 0,
          y: -50,
        },
        0.25,
      )
      .to(
        contentTitle,
        {
          duration: 0.5,
          ease: 'power3.inOut',
          opacity: 0,
          y: -50,
        },
        0,
      )
      .to(
        contentImageSecond,
        {
          duration: 1,
          ease: 'power3.inOut',
          x: 0,
          y: 0,
          opacity: 0,
        },
        0,
      )
      .to(
        contentImageThird,
        {
          duration: 1,
          ease: 'power3.inOut',
          x: 0,
          y: 0,
          opacity: 0,
        },
        0,
      )
      .to(
        contentImageScale,
        {
          duration: 1,
          scale: 1.2,
        },
        0,
      )
      .to(
        revealImage,
        {
          duration: 1,
          ease: 'power3.inOut',
          x: '0%',
        },
        0.5,
      )
  }
  const closeAnimBackground = () => {
    const imageBg = document.querySelector('.detail__section-image-background')
    const contentBg = document.querySelector('.detail__section-content-background')

    const contentImageScale = DOM.el?.querySelector('.content__image--first .topic--detail_image')
    const contentImageFirst = DOM.el?.querySelector('.content__image--first')
    const contentImageSecond = DOM.el?.querySelector('.content__image--second')
    const contentImageThird = DOM.el?.querySelector('.content__image--third')

    const closeTimeline = gsap.timeline({
      onComplete: () => {
        tween.firstInit = true
        tween.isAnimating = false
      },
    })
    closeTimeline
      .set([contentImageScale, contentImageFirst, contentImageSecond, contentImageThird], { opacity: 0 }, 0)
      .to(imageBg, { duration: 0.5, ease: 'power3.inOut', height: '0%' }, 0)
      .to(contentBg, { duration: 0.5, ease: 'power3.inOut', height: '0%' }, 0)
  }

  function changeDetail(args: any) {
    item.value = args.item
    isMuted.value = args.isMuted
    volume.value = args.volume

    animDetailOut()

    setTimeout(() => {
      // if (item.value.music) playSound(item.value.music)
      animDetailIn()
    }, 50)
  }

  return {
    item,
    animDetailIn,
    animDetailOut,
    closeAnimBackground,
    changeDetail,
  }
}
