import { useViewportCalculations } from '@/composables/useViewport'
import { ProductTag } from '@/interface/messages/Product.interface'
import { hexToRgba } from '@/plugins/generalColors'
import { useSettingsStore } from '@/stores/settings'

export function getProductTagStyle(args: { tags: ProductTag[]; type?: 'default' | 'small' }) {
  const { tags } = args
  const _styles = useSettingsStore().productTagStyles
  const { pxToVW } = useViewportCalculations()

  if (!tags.length) return ''

  const styles = _styles
    .filter((s) => tags?.filter((t) => t.id === s.tagId).length > 0)
    .sort((a, b) => a.hierarchy - b.hierarchy)

  if (!styles.length) return ''
  const style = styles[0]

  if (style.type === 'hatched' && style.color1 && style.color2) {
    const size = '0.6vw'
    const size2 = '0.5%'
    const borderSize = pxToVW(1) + 'vw'
    const borderColor = hexToRgba(style.color1, 0.5)

    return {
      backgroundImage: `linear-gradient(135deg, ${style.color1} ${size2}, ${style.color2} ${size2}, ${style.color2} 50%, ${style.color1} 50%, ${style.color1} 51%, ${style.color2} 51%, ${style.color2} 100%)`,
      backgroundSize: `${size} ${size}`,
      backgroundRepeat: 'repeat',
      border: `${borderSize} solid ${borderColor}`,
    }
  }
}
