<template>
  <div class="visual-box-side" :class="[typeClass, sizeClass]">
    <div class="header">
      <span class="header-label text-truncate-1">{{ label }}</span>
      <icon-tag class="header-icon" :icon="icon" />
    </div>
    <div class="title text-truncate-1">
      <slot v-if="$slots.default" />
      <CounterUp v-else :end="parseFloat(count)" :currency="currency" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import CounterUp from '@/components/partials/counter/CounterUp.vue'
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      type?: 'solid' | 'transparent'
      size?: 'small' | 'default'
      label: string
      icon?: string
      count?: number | string
      currency?: boolean
    }>(),
    {
      type: 'transparent',
      size: 'default',
      icon: '',
      count: 0,
      currency: false,
    },
  )

  const typeClass = computed(() => `type-${props.type}`)
  const sizeClass = computed(() => `size-${props.size}`)
</script>

<style lang="scss" scoped>
  @mixin size-mixin($size-height, $label-size, $icon-size, $text-size) {
    height: get-vw($size-height);

    .header-label {
      @include text-body($label-size, $font-weight-600);
    }
    .header-icon {
      font-size: get-vw($icon-size);
    }
    .title {
      @include text-body($text-size, $font-weight-600);
    }
  }

  .visual-box-side {
    display: flex;
    padding: get-vw($g-8);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: get-vw($r-4);
    border: $border-none solid rgba-percent($white, $opacity-50);
    color: $white;
    transition-delay: 800ms;

    .header {
      display: flex;
      padding: get-vw($g-0);
      align-items: center;
      gap: get-vw($g-4);
      align-self: stretch;
    }

    .header-label {
      flex: 1 0 0;
      color: rgba-percent($white, $opacity-500);
    }

    .title {
      width: 100%;
    }

    // types
    &.type-solid {
      background: $gray-800;
    }
    &.type-transparent {
      background: rgba-percent($black, $opacity-200);
      /* ▨ Backdrop-Blur/600 */
      backdrop-filter: blur(32px);
    }

    // sizes
    &.size-default {
      @include size-mixin(249px, $heading-2, 28px, $heading-5);
    }
    &.size-small {
      @include size-mixin(160px, $heading-1, 24px, $heading-3);
    }
  }
</style>
