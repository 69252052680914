export const themes = {
  light: {
    dark: false,
    colors: {
      white: '#ffffff',
      black: '#101010',
      'gray-100': '#f6f6f6',
      'gray-200': '#eeeeee',
      'gray-300': '#d8d8d8',
      'gray-400': '#949494',
      'gray-500': '#5e5e5e',
      'gray-600': '#444444',
      'gray-700': '#282828',
      'gray-800': '#202020',
      emphasis: '#3d475e',
      info: '#4254de',
      critical: '#dc245b',
      success: '#538959',
      warning: '#d89026',
      'chart-100': '#83a2c9',
      'chart-200': '#323c56',
      'chart-300': '#e4fca2',
      'chart-400': '#26585a',
      'chart-500': '#f8f8f2',
      'chart-600': '#282828',
    },
  },
  dark: {
    dark: true,
    colors: {},
  },
}

// color helper functions
export const colorKeys = Object.keys(themes.light.colors)
export const getColorHex = (keyColor: string) => themes.light.colors[keyColor] || keyColor

export const getRGBAClass = (keyColor: string, opacity = 1) => {
  const colorHex = getColorHex(keyColor)
  const rgbaColor = hexToRgb(colorHex)
  return `rgba(${rgbaColor}, ${opacity})`
}

export function hexToRgb(hex: string) {
  let r = 0,
    g = 0,
    b = 0
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }
  return `${r}, ${g}, ${b}`
}

export function hexToRgba(hex: string, opacity: number): string {
  hex = hex.replace(/^#/, '')

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('')
  }

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export function getBrightness(color: { hex?: string; rgb?: string; rgba?: string }) {
  let r: number, g: number, b: number

  if (color.hex) {
    const rgb = parseInt(color.hex.slice(1), 16)
    if (!rgb && rgb !== 0) return 0

    r = (rgb >> 16) & 0xff
    g = (rgb >> 8) & 0xff
    b = rgb & 0xff
  } else if (color.rgb || color.rgba) {
    const rgbString = color.rgb || color.rgba
    const rgbMatch = rgbString!.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*\d*\.?\d+)?\)$/)
    if (!rgbMatch) return 0

    r = parseInt(rgbMatch[1], 10)
    g = parseInt(rgbMatch[2], 10)
    b = parseInt(rgbMatch[3], 10)
  } else {
    return 0
  }

  return (r * 299 + g * 587 + b * 114) / 1000
}
