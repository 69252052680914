export function useViewportCalculations() {
  const root = document.documentElement
  const rootPageWidth = parseFloat(getComputedStyle(root).getPropertyValue('--page-width')) || 1920
  const rootPageHeight = parseFloat(getComputedStyle(root).getPropertyValue('--page-height')) || 1080

  const pxToVW = (px: number, width?: number) => (px ? (px * 100) / (width || rootPageWidth) : px)
  const pxToVH = (px: number, height?: number) => (px ? (px * 100) / (height || rootPageHeight) : px)

  const percentToVW = (percent: number, width?: number) => {
    const viewportWidth = width || rootPageWidth || window.innerWidth
    return (percent * viewportWidth) / 100
  }

  const percentToVH = (percent: number, height?: number) => {
    const viewportHeight = height || rootPageHeight || window.innerHeight
    return (percent * viewportHeight) / 100
  }

  const pxToRelative = (px: number) => {
    const referenceWidth = 1920
    const ratio = window.innerWidth / referenceWidth
    return px * ratio
  }

  return {
    pxToVW,
    pxToVH,
    percentToVW,
    percentToVH,
    pxToRelative,
  }
}
