<template>
  <div class="layout-side" :class="[typeClass]">
    <Transition name="layout-fade-image">
      <ImageLoaderBg :key="currentImagePath" :src="currentImagePath" />
    </Transition>
    <div class="layout-side-content">
      <TransitionGroup name="slide-down" class="content-info" tag="div">
        <CardCaption v-if="showDefault" :tag="tag" :caption="caption" :title="title" :subtitle="subtitle" />
      </TransitionGroup>

      <TransitionGroup key="statistics" name="list-slide-right" tag="div" class="content-statistics" appear>
        <template v-if="showStatistic">
          <CardStats
            v-for="(item, index) of statistics"
            :key="index"
            :label="item.label"
            :icon="item.icon"
            :count="item.count"
            :currency="item.currency"
            :style="{ transitionDelay: `${index * 100}ms` }"
          />
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useImageSlider } from '@/components/layout/side/composables/useImageSlider'
  import { t } from '@/utils/helpers'
  import { onMounted, onUnmounted, computed, watch, ref } from 'vue'
  import ImageLoaderBg from '@/components/media/ImageLoaderBg.vue'
  import CardStats from '@/components/card/CardStats.vue'
  import CardCaption from '@/components/card/CardCaption.vue'

  type ComponentType = 'default' | 'statistics'
  const props = withDefaults(
    defineProps<{
      type?: ComponentType
      showImageSlider?: boolean
      showContent?: boolean
      caption?: string
      title?: string
      subtitle?: string
      tag?: string
      items?: number
      quantity?: number
      whs?: number
      rrp?: number
      images: string[]
    }>(),
    {
      type: 'default',
      showContent: true,
      showImageSlider: true,
      caption: '',
      title: '',
      subtitle: '',
      tag: '',
      items: 0,
      quantity: 0,
      whs: 0,
      rrp: 0,
    },
  )

  const { currentImagePath, updateSliderState, clearSliderInterval } = useImageSlider(props)

  const switchDuration = ref(0)
  const typeClass = computed(() => `type-${props.type}`)
  const showDefault = computed(() => props.showContent && props.type === 'default' && switchDuration.value === 0)
  const showStatistic = computed(() => props.showContent && props.type === 'statistics' && switchDuration.value === 0)

  const statistics = computed(() => [
    { label: t('common.item'), icon: 'icon-interface-grid-plus', count: props.items },
    { label: t('common.quantity'), icon: 'icon-interface-stacks', count: props.quantity },
    { label: t('common.whs'), icon: 'icon-interface-basket', count: props.whs, currency: true },
    { label: t('common.rrp'), icon: 'icon-interface-price', count: props.rrp, currency: true },
  ])

  let switchTimeoutId
  watch(() => props.type, updateSwitchDuration)

  function updateSwitchDuration() {
    // Clear any existing timeout
    if (switchTimeoutId !== null) {
      clearTimeout(switchTimeoutId)
    }

    switchDuration.value = props.type === 'default' ? 800 : 200
    switchTimeoutId = setTimeout(() => {
      switchDuration.value = 0
      switchTimeoutId = null
    }, switchDuration.value)
  }

  onMounted(updateSliderState)
  onUnmounted(clearSliderInterval)
</script>

<style lang="scss" scoped>
  .layout-side {
    position: relative;
    padding: get-vw($g-10);
    height: 100%;
    overflow: hidden;
    background-color: $gray-300;

    .layout-side-content {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .content-info {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      gap: get-vw($g-3);
      flex-shrink: 0;
    }

    .content-statistics {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      gap: get-vw($g-3);
      flex: 1 0 0;
      align-self: stretch;
      flex-direction: column;
    }
  }
  // Animation
  .layout-fade-image {
    &-enter-active,
    &-leave-active {
      transition: opacity 2s ease-in-out;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
    &-enter-to,
    &-leave-from {
      opacity: 1;
    }
  }
</style>
