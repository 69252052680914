import { ref, watchEffect } from 'vue'

export function useImageZoom() {
  const imageContainerRef = ref<HTMLElement | null>(null)
  const zoomLevel = ref(1)
  const dragX = ref(0)
  const dragY = ref(0)

  watchEffect(() => {
    document.documentElement.style.setProperty('--zoom', zoomLevel.value + '')
    document.documentElement.style.setProperty('--moveX', dragX.value + 'px')
    document.documentElement.style.setProperty('--moveY', dragY.value + 'px')
  })

  const zoomImg = (args: { level: number; width: number; height: number; x: number; y: number }) => {
    if (!imageContainerRef.value) return
    const { level, width, height } = args

    const containerWidth = imageContainerRef.value.clientWidth
    const containerHeight = imageContainerRef.value.clientHeight
    const isMainContainerSmaller = width < containerWidth

    // calculate ratio
    const widthRatio = isMainContainerSmaller ? containerWidth / width : width / containerWidth
    const heightRatio = isMainContainerSmaller ? containerHeight / height : height / containerHeight
    const ratio = Math.min(widthRatio, heightRatio)

    const calculatedZoomLevel = isMainContainerSmaller ? level * ratio : level / ratio
    zoomLevel.value = Math.max(calculatedZoomLevel, 1)

    dragImg(args)
  }

  const dragImg = (args: { level: number; x: number; y: number; width: number; height: number }) => {
    if (!imageContainerRef.value) return
    const { x, y, width, height } = args

    const containerWidth = imageContainerRef.value.clientWidth
    const containerHeight = imageContainerRef.value.clientHeight
    dragX.value = (x * (containerWidth / width)) / zoomLevel.value
    dragY.value = (y * (containerHeight / height)) / zoomLevel.value
  }

  const zoomDragReset = () => {
    zoomLevel.value = 1
    dragX.value = 0
    dragY.value = 0
  }

  return {
    imageContainerRef,
    zoomImg,
    dragImg,
    zoomDragReset,
  }
}
