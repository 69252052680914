<template>
  <div class="content-color bg-gray-100" :class="genClass" :style="tagStyle">
    <div v-if="showCounter" class="counter-content">
      <ChipState :type="counter === 0 ? 'icon' : 'counter'" :counter="counter" />
    </div>
    <div class="image-content">
      <div class="image-inner">
        <ImageLoader :key="productImg" :src="productImg"></ImageLoader>
      </div>
    </div>
    <div v-if="hasInfoAndColor" class="info-content flex-center">
      <ChipColor :code="colorData?.color_number" :hex="colorData?.hex"></ChipColor>
    </div>
    <template v-if="!!$slots['default']">
      <slot></slot>
    </template>
  </div>
</template>
<script setup lang="ts">
  import { computed, toRef } from 'vue'
  import ImageLoader from '@/components/media/ImageLoader.vue'
  import ChipColor from '@/components/partials/chip/ChipColor.vue'
  import { ProductColor } from '@/interface/messages/ProductColor.interface'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { ProductTag } from '@/interface/messages/Product.interface'
  import ChipState from '@/components/partials/chip/ChipState.vue'
  import { getProductTagStyle } from '@/utils/productTagStyles'

  const props = withDefaults(
    defineProps<{
      src: string
      active?: boolean
      variant?: string
      color?: ProductColor
      tags?: ProductTag[]
      counter?: number
      showCounter?: boolean
      rounded?: number
    }>(),
    {
      rounded: 3,
    },
  )
  const colorData = toRef(props, 'color')
  const hasInfoAndColor = computed(
    () => props.variant === 'info' && (colorData.value?.color_number || colorData.value?.hex),
  )

  const tagStyle = computed(() => getProductTagStyle({ tags: props.tags || [] }))
  const productImg = computed(() => buildAssetPath({ src: props.src, thumb: 800 }))

  const genClass = computed(() => {
    return {
      [`variant-${props.variant}`]: true,
      'is-active': props.active,
      [`rounded-${props.rounded}`]: true,
    }
  })
</script>

<style scoped lang="scss">
  .content-color {
    position: relative;

    &.is-active {
      &:before {
        content: '';
        position: absolute;
        width: get-vw(24px);
        height: get-vw(4px);
        border-radius: 100vmax;
        background-color: $gray-400;
        bottom: get-vw(-12px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .image-content {
    overflow: hidden;
  }

  .image-inner {
    width: 100%;
    line-height: 0;
  }

  .info-content {
    padding: 0 get-vw($g-2) get-vw($g-2) get-vw($g-2);
  }

  .variant-image {
    aspect-ratio: 59 / 67;
    display: grid;
    place-content: center;

    .image-content {
      padding: get-vw($g-1) get-vw($g-2);
    }
  }

  .variant-info {
    aspect-ratio: 59 / 77;
    .image-content {
      padding: get-vw($g-3) get-vw($g-2);
    }

    .image-inner {
      aspect-ratio: 1 / 1;
      display: grid;
      place-content: center;
    }
  }

  .counter-content {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(get-vw(10), get-vw(-10));
    border-radius: 100vmax;
    border: get-vw($border-lg) solid $white;
  }
</style>
