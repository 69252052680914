<template>
  <div class="tab" :class="[isActiveClass, sizeClass]">
    <IconTag v-if="icon" :icon="icon" />
    <span v-if="$slots.default" class="text-truncate-1">
      <slot />
    </span>
  </div>
</template>

<script lang="ts" setup>
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { computed, defineProps } from 'vue'

  const props = defineProps<{
    size: 'small' | 'medium' | 'large'
    icon?: string
    isActive: boolean
  }>()

  const isActiveClass = computed(() => (props.isActive ? 'is-active' : ''))
  const sizeClass = computed(() => (props.size ? `size-${props.size}` : ''))
</script>

<style lang="scss" scoped>
  .tab {
    display: inline-flex;
    padding: get-vw($g-3) get-vw($g-4);
    align-items: center;
    gap: get-vw($g-3);
    border-radius: get-vw($r-3);
    color: $gray-400;
    background: $white;

    &.is-active {
      color: $black;
      background: $gray-100;
    }
    // sizes
    // Mixin for size variations
    @mixin tab-size($text-body, $max-width, $icon-size) {
      @include text-body($text-body, $font-weight-600);
      max-width: get-vw($max-width);

      .v-icon {
        font-size: get-vw($icon-size);
      }
    }

    &.size-small {
      @include tab-size($text-body-3, 120px, 12px);
    }

    &.size-medium {
      @include tab-size($text-body-4, 148px, 16px);
    }

    &.size-large {
      @include tab-size($text-body-5, 180px, 20px);
    }
  }
</style>
