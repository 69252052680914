<template>
  <div class="g-3 pt-12 pr-20 pb-12 pl-20">
    <TransitionGroup tag="div" class="feature-list d-flex flex flex-col g-3" name="list-slide-right" appear>
      <ContentFeature
        v-for="(item, index) in props.items"
        :key="item.title"
        :headline="item.title"
        :caption="item.subtitle"
        :src="item.image"
        :style="{ transitionDelay: `${index * 50}ms` }"
      ></ContentFeature>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
  import ContentFeature from '@/components/content/ContentFeature.vue'

  const props = defineProps({
    scrollPercent: {
      type: Number,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
  })
</script>
