import { computed } from 'vue'
import { useSizesGridOptions } from '@/composables/sizes/useSizesGridOptions'

export function useDetailSizeGridOptions(sizes) {

  const { getOption } = useSizesGridOptions()

  const sizesLength = computed(() => Object.keys(sizes?.value).length)
  const sizeItemOption = computed(() => getOption(sizesLength.value))

  return {
    sizeItemOption
  }
}
