import buildAssetPath from '@/utils/buildAssetsPath'
import { ProductDetailMessage } from '@/views/product-detail/ProductDetail.interface'
import { computed, ref, watch } from 'vue'

export function useProductDetailItem(props: { message: ProductDetailMessage }) {
  const mainImageData = ref()
  const imageTransition = ref<string | undefined>(undefined)

  const content = computed(() => props.message?.content)
  const item = computed(() => content?.value.item)
  const imageGallery = computed(() =>
    typeof item.value?.image_gallery === 'string'
      ? (JSON.parse(item.value.image_gallery) as string[])
      : item.value?.image_gallery,
  )

  const mainImage = computed(() => item.value.image)
  const activeId = computed(() => item.value.id)
  const siblings = computed(() => content.value?.siblings)

  const medias = computed(() => {
    const gallery = imageGallery.value ? [...imageGallery.value] : []
    const [top = '', bottom = '', right = ''] = gallery

    /*
    // Add the main image at the beginning if it's not already in the gallery
    if (mainImage.value && !gallery.includes(mainImage.value)) {
      gallery.unshift(mainImage.value)
    }

    // Remove the current main image
    gallery = gallery.filter((image) => image !== mainImageData.value)
    */

    return {
      video: buildAssetPath({ src: item.value?.mood_video }),
      right: buildAssetPath({ src: right, thumb: 800 }),
      top: buildAssetPath({ src: top, thumb: 800 }),
      bottom: buildAssetPath({ src: bottom, thumb: 800 }),
    }
  })

  watch(
    mainImage,
    (value) => {
      imageTransition.value = 'main-image-rotate'
      mainImageData.value = value
    },
    {
      immediate: true,
    },
  )

  return {
    activeId,
    item,
    mainImageData,
    imageTransition,
    medias,
    siblings,
  }
}
