import { ref } from 'vue'

export default function useProductDetailSocket() {
  const showProductsDetail = ref(false)
  const productData = ref({
    state: undefined,
    content: undefined,
  })

  const setProductDetailData = (args) => {
    productData.value = {
      state: args.state,
      content: args.content,
    }
    showProductsDetail.value = true
  }

  const closeProductDetail = () => {
    productData.value = { state: undefined, content: undefined }
    showProductsDetail.value = false
  }

  return {
    showProductsDetail,
    productData,
    setProductDetailData,
    closeProductDetail,
  }
}
