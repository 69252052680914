import { useViewportCalculations } from '@/composables/useViewport'
import { ref, watch } from 'vue'

export function useDynamicFontSize(props: { text: string; fontSize: number; isDynamicText?: boolean }) {
  const bgTextRef = ref<HTMLDivElement | null>(null)

  const { pxToVW } = useViewportCalculations()
  const getSizeVW = (fontSize: number) => pxToVW(fontSize) + 'vw'

  watch(
    () => [props.text, props.fontSize, props.isDynamicText],
    () => adjustFontSize(),
  )

  const adjustFontSize = () => {
    const textFit = bgTextRef.value
    if (!textFit || !textFit.parentElement) return

    const container = textFit.parentElement
    const containerWidth = container.offsetWidth

    // Temporarily set the font size to a base pixel value for accurate measurement
    const initialFontSize = props.fontSize
    textFit.style.fontSize = getSizeVW(initialFontSize)

    const textWidth = textFit.scrollWidth
    const scale = containerWidth / textWidth

    // Calculate the new font size in pixels
    const isDynamicTextActive = props.isDynamicText && scale < 1
    const newFontSize = isDynamicTextActive ? initialFontSize * scale : initialFontSize

    // Convert the new font size to vw
    textFit.style.fontSize = getSizeVW(newFontSize)
  }

  return {
    bgTextRef,
    adjustFontSize,
  }
}
