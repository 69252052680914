import { reactive } from 'vue'

let currentViewportType = ''

function updateViewportVariables() {
  const storybook = document.querySelector('#storybook-root')
  if (!storybook && currentViewportType) return

  const viewport = reactive({
    type: '' as 'landscape' | 'portrait',
    width: 0,
    height: 0,
    widthLandscape: '1920',
    widthPortrait: '600',
    heightDefault: '1080',
  })

  // If currentViewportType is already set, no further updates are required
  // if (currentViewportType) return
  viewport.width = window.innerWidth
  viewport.height = window.innerHeight

  viewport.type = viewport.width > Number(viewport.widthPortrait) ? 'landscape' : 'portrait'

  if (viewport.type !== currentViewportType) {
    const { type, widthLandscape, widthPortrait, heightDefault } = viewport

    currentViewportType = type
    const element = document.documentElement
    element.style.setProperty('--viewport', type)
    element.style.setProperty('--page-width', type === 'landscape' ? widthLandscape : widthPortrait)
    element.style.setProperty('--page-height', heightDefault)
  }
}

updateViewportVariables()
window.addEventListener('resize', updateViewportVariables)
