import { useCatalogStore } from '@/stores/catalog'

export function useProductCounter() {
  const catalogStore = useCatalogStore()

  const getUsedID = (productID: number) => catalogStore.usedIdsMap[productID]
  const showCounter = (productID: number) => !!getUsedID(productID)

  function getCounter(productID: number) {
    const usedId = catalogStore.usedIdsMap[productID]

    if (usedId === true) return 0
    if (usedId === undefined || usedId === false) return -1
    return usedId
  }

  return {
    showCounter,
    getCounter,
  }
}
