<template>
<div class="cart-text">
  <div class="subtitle font-weight-600 text-body-4 color-gray-400">
    {{ props.subtitle }}
  </div>
  <div class="action fit-content d-flex text-truncate-1">
    <slot name="action"></slot>
  </div>
  <div class="title  text-truncate-1 font-weight-600 heading-2" :class="genTitleClass">
    {{ props.title }}
  </div>
</div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  subtitle: {
    type: String
  },
  title: {
    type: [String, Number]
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const genTitleClass = computed(() => {
  return {
    'color-black': !props.disabled,
    'color-gray-200': props.disabled
  }
})
</script>

<style scoped lang="scss">
.cart-text {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr fit-content(get-vw(120px));
  grid-template-rows: 1fr 1fr;
  gap: 0;
  grid-template-areas:
    "subtitle action"
    "title title";
  padding-left: get-vw($g-7);
  padding-right: get-vw($g-7);
}
.subtitle { grid-area: subtitle; }
.action { grid-area: action; }
.title { grid-area: title; }
</style>
