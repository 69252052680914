import { useGeneralStore } from '@/stores/general'

/**
 *
 * @returns {string}
 * @param options
 */
export default function buildAssetPath(options: { src?: string; thumb?: number }) {
  const { src, thumb = null } = options
  if (!src) return ''
  if (src.startsWith('https://') || src.includes('/img-storybook/')) return src

  const { config } = useGeneralStore()

  const prefix = config.host ? config.host + '/media' : ''

  // filename
  let filename = src.replaceAll('\\', '/').split('/').reverse()[0]
  // delete thumb prefix
  filename = filename.replace(/_thumb_\d+x\d+/, '')

  if (null !== thumb && !filename.endsWith('.svg')) {
    filename = 'thumb/thumb_width' + thumb + '_height' + thumb + '_' + filename
  }

  return `${prefix}/${filename}`
}
