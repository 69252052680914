import { useSettingsStore } from '@/stores/settings'
import i18n from '@/plugins/i18n'

export const randomID = 'id-' + Math.random().toString()
export const generateRandomID = () => 'id-' + Math.random().toString()
export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj))
export const t = (v: string, values?: Record<string, unknown> | unknown[]): string => {
  if (Array.isArray(values)) {
    return i18n.global.t(v, values as unknown[])
  } else {
    return i18n.global.t(v, values as Record<string, unknown>)
  }
}

export function getScreenIdFromUrl(): number | null {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const screenIdParam = urlParams.get('screen_id')
  return screenIdParam ? parseInt(screenIdParam, 10) : null
}

export function priceFormatter(value?: number | string | null) {
  const defaultLang = { format: 'en', currency: 'USD' }
  const { priceFormat, currency, language } = useSettingsStore()
  const languageFormat = priceFormat || language || defaultLang.format

  let newValue = 0

  if (typeof value === 'number') {
    newValue = value
  } else {
    if (!value) {
      newValue = 0
    } else {
      // Remove non-numeric characters except for ',' and '.'
      const cleanedValue = value.replace(/[^0-9.,]/g, '').replace(',', '.')
      newValue = parseFloat(cleanedValue)
    }

    // If the conversion to float fails, default to 0
    if (isNaN(newValue)) {
      newValue = 0
    }
  }

  return new Intl.NumberFormat(languageFormat, {
    style: 'currency',
    currency: currency || defaultLang.currency,
    minimumFractionDigits: 2,
  }).format(newValue)
}

export function handleScrollPercent(container: HTMLElement | null, percent = 0) {
  if (!container) return
  container.style.scrollBehavior = 'smooth'
  container.scrollTop = (container.scrollHeight - container.getBoundingClientRect().height) * (percent / 100)
}

export function handleScrollToVirtualScrollItem(container: HTMLElement | null, value: number) {
  if (!container) return
  container.scrollTop = value
}

export function handleJumpToVirtualScrollItem(container: HTMLElement | null, value: number) {
  if (!container) return
  container.style.scrollBehavior = 'initial'
  container.scrollTop = value
  setTimeout(() => (container.style.scrollBehavior = 'smooth'), 50)
}
export function handleJumpToPercent(container: HTMLElement | null, percent = 0) {
  if (!container) return
  container.style.scrollBehavior = 'initial'
  container.scrollTop = (container.scrollHeight - container.getBoundingClientRect().height) * (percent / 100)
  setTimeout(() => (container.style.scrollBehavior = 'smooth'), 50)
}

export function spliceItems<T>(data: T[], count = 50): T[] {
  return data.length > count ? data.slice(0, count) : data
}

export function debounce<T extends (...args: any[]) => any>(cb: T, wait: number) {
  let h: any
  const callable = (...args: any) => {
    clearTimeout(h)
    h = setTimeout(() => cb(...args), wait)
  }
  return <T>(<any>callable)
}
