import { useGeneralStore } from '@/stores/general'

export const loadConfig = async () => {
  const generalStore = useGeneralStore()
  return fetch('/assets/config.json')
    .then((res) => res.json())
    .then((data) => {
      generalStore.setConfig(data)

      const bodyData = { apikey: data.apiKey }

      return fetch(`${data.host}/app/en/remote-app/showroom`, {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(bodyData),
      })
    })
}
