import { Component, computed, ref, watch } from 'vue'
import { useGeneralStore } from '@/stores/general'
import Intro from '@/views/intro/Intro.vue'
import FullscreenVideo from '@/views/fullscreen-video/FullscreenVideo.vue'
import OverviewGrid from '@/views/collections/overview-grid/OverviewGrid.vue'
import OverviewSlider from '@/views/collections/overview-slider/OverviewSlider.vue'
import DetailHero from '@/views/collections/detail-hero/DetailHero.vue'
import DetailGrid from '@/views/collections/detail-grid/DetailGrid.vue'
import DetailCarousel from '@/views/collections/detail-carousel/DetailCarousel.vue'
import SalesTableCheckoutOverview from '@/views/checkout-overview/CheckoutOverview.vue'
import CheckoutSuccess from '@/views/checkout-success/CheckoutSuccess.vue'
import SalesTableCartOverview from '@/views/cart-overview/CartOverview.vue'
import CartDetail from '@/views/cart-detail/CartDetail.vue'
import ProductCatalogue from '@/views/catalog-overview/CatalogOverview.vue'
import Whiteboard from '@/views/catalog-overview/CatalogOverviewAll.vue'
import WhiteboardFreeDrag from '@/views/whiteboard-canvas/WhiteboardCanvas.vue'
import ProductDetail from '@/views/product-detail/ProductDetail.vue'
import ProductCrossSelling from '@/views/product-cross-selling/ProductCrossSelling.vue'
import StatisticOverview from '@/views/statistic-overview/StatisticOverview.vue'

export function useScreenView() {
  const generalStore = useGeneralStore()
  const componentTransitionName = ref('fade-component')

  const components = {
    Intro,
    FullscreenVideo,
    OverviewGrid,
    OverviewSlider,
    DetailHero,
    DetailGrid,
    DetailCarousel,
    SalesTableCheckoutOverview,
    CheckoutSuccess,
    SalesTableCartOverview,
    CartDetail,
    ProductCatalogue,
    Whiteboard,
    WhiteboardFreeDrag,
    ProductDetail,
    ProductCrossSelling,
    StatisticOverview,
  }

  const screenMessage = computed(() => generalStore.screen)
  const currentView = computed<Component>(() => {
    const screenView = generalStore.screen.view
    if (!components?.[screenView]) {
      console.error(`[screenView]: ${screenView} was not found`)
    }
    return components?.[screenView] || Intro
  })

  watch(() => currentView.value, updateTransitionName)

  function updateTransitionName(newView: Component, oldView: Component) {
    if (!newView || !oldView) return

    // Disable fade animation between slider and grid
    const isTransitionInactive =
      (oldView === OverviewGrid && newView === OverviewSlider) ||
      (oldView === OverviewSlider && newView === OverviewGrid)

    componentTransitionName.value = isTransitionInactive ? '' : 'fade-component'
  }

  return {
    screenMessage,
    currentView,
    componentTransitionName,
  }
}
