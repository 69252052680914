import { useMultipleSelection } from '@/composables/socket/useMultipleSelection'
import { useActionMessageWatcher } from '@/composables/storybook/useActionMessageWatcher'
import { useViewportCalculations } from '@/composables/useViewport'
import {
  handleJumpToPercent,
  handleJumpToVirtualScrollItem,
  handleScrollPercent,
  handleScrollToVirtualScrollItem,
} from '@/utils/helpers'
import { catalogActions } from '@/views/catalog-overview/actions/CatalogOverview.actions'
import { useCatalogProductSize } from '@/views/catalog-overview/composables/useCatalogProductSize'
import {
  CatalogImagePerspective,
  CatalogProduct,
  CatalogProductMessage,
  CatalogSidebar,
  CatalogZoomType,
} from '@/views/catalog-overview/interface/CatalogOverview.interface'
import { computed, Ref, ref } from 'vue'

export function useCatalogMessages(
  props: { message: CatalogProductMessage; actionMessage?: string },
  items: Ref<CatalogProduct[]>,
  viewportWidth: Ref<number>,
  zoom: Ref<CatalogZoomType>,
  perspective: Ref<CatalogImagePerspective>,
  catalogSidebar: Ref<CatalogSidebar>,
  pinnedData: Ref<any>,
) {
  const screenID = 'ProductCatalogue'
  const recycleScroller = ref()
  const isRecycleScrollerActive = ref(false)
  const isSidebarScrollerActive = ref(false)
  const selectedFilterData = ref({ isVisible: false, filters: [] as string[] })

  const { rowSize, aspectRatio, productSize } = useCatalogProductSize(zoom)
  const { selections, setMultipleSelection, checkItemIsSelected } = useMultipleSelection()
  const { pxToRelative } = useViewportCalculations()
  const rowPadding = computed(() => pxToRelative(12))

  /** Storybook actions helper  */
  const actionMessage = computed(() => props.actionMessage)
  useActionMessageWatcher({ actionMessage, actions: catalogActions, onMessage })
  /* ---------------------------------------------------------------------------------- */

  const itemRows = computed(() => {
    const arr = [...items.value]
    const rows: { id: number; items: CatalogProduct[] }[] = []
    let count = 0
    while (arr.length) {
      rows.push({ id: count, items: arr.splice(0, rowSize.value) })
      count++
    }
    return rows
  })

  const itemHeight = computed(() => {
    const recycleScrollerEl = recycleScroller.value?.$el as HTMLElement
    if (!viewportWidth.value || !recycleScrollerEl) return 0
    const innerWidth = viewportWidth.value - pxToRelative(20) * 2
    // set row padding
    const gapPadding = (rowSize.value - 1) * rowPadding.value
    const width = (innerWidth - gapPadding) / rowSize.value
    const aspect = aspectRatio.value
    const [w, h] = aspect.split('/').map(Number)
    const height = (width * h) / w
    return Math.round(height)
  })

  const itemSize = computed(() => itemHeight.value + rowPadding.value)

  function onMessage(message) {
    if (message.event === 'update' && message.data.view === screenID) {
      scrollerMounted()
      setTimeout(() => scrollPercentSidebar(props.message.content.sidebar.scrollPosition), 50)
      return
    }
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      setPerspective: () => (perspective.value = args),
      scrollToVirtualScrollItem: () => scrollToVirtualScrollItem(args),
      scrollToBottom: () => scrollToBottom(),
      scrollPercent: () => setTimeout(() => handleScrollPercent(recycleScroller.value.$el, args), 100),
      jumpToPercent: () => handleJumpToPercent(recycleScroller.value?.$el, args),
      jumpToVirtualScrollItem: () => handleJumpToVirtualScrollItem(recycleScroller.value.$el, itemHeight.value * args),
      scrollPercentSidebar: () => scrollPercentSidebar(args),
      setMultipleSelection: () => setMultipleSelection(args),
      updateWhiteboardSidebar: () => (catalogSidebar.value = args),
      catalogSelectedFilter: () => updateCatalogSelectedFilter(args),
      setPinnedContainerData: () => {
        scrollPercentSidebar(0)
        pinnedData.value = args?.content
      },
      closePinnedContainer: () => {
        scrollPercentSidebar(0)
        pinnedData.value = undefined
      },
    }
    actions[method]?.()
  }

  function scrollerMounted(newScrollPosition?: number) {
    const scrollPosition = newScrollPosition || props.message.content.scrollPosition
    const value = (itemHeight.value + rowPadding.value) * scrollPosition
    handleJumpToVirtualScrollItem(recycleScroller.value?.$el, value)
  }

  let scrollTimeout: number | null = null
  function handleScroll() {
    isRecycleScrollerActive.value = true
    if (scrollTimeout) clearTimeout(scrollTimeout)
    scrollTimeout = window.setTimeout(() => (isRecycleScrollerActive.value = false), 800)
  }

  function scrollToVirtualScrollItem(v: number) {
    if (!itemHeight.value) return
    const result = (itemHeight.value + rowPadding.value) * v
    handleScrollToVirtualScrollItem(recycleScroller.value.$el, result)
    handleScroll()
  }

  function scrollToBottom() {
    const scroller = recycleScroller.value.$el
    const paddingBottom = parseFloat(window.getComputedStyle(scroller).paddingBottom) || 0
    scroller.scrollTop = scroller.scrollHeight + paddingBottom
  }

  function scrollPercentSidebar(percent = 0) {
    const container = document.querySelector('#right-side') as HTMLElement
    isSidebarScrollerActive.value = percent > 0
    handleScrollPercent(container, percent)
  }

  function updateCatalogSelectedFilter(newFilter: { isVisible: boolean; filters: string[] }) {
    selectedFilterData.value = newFilter
  }

  return {
    screenID,
    onMessage,
    scrollerMounted,
    recycleScroller,
    selections,
    checkItemIsSelected,
    rowSize,
    itemHeight,
    itemRows,
    itemSize,
    productSize,
    isRecycleScrollerActive,
    isSidebarScrollerActive,
    selectedFilterData,
    scrollToVirtualScrollItem,
  }
}
