<template>
  <section class="overview-slider">
    <layout-text-gradient :text="message.state.title" type="dark">
      <div class="mod-topics mod-topics-slider">
        <div
          :key="items.length"
          ref="scrollContainerRef"
          class="mod-topics-container"
          style="--capsule-font-size-name: 1.6vw"
          :class="{ 'drag--is-active': state.dragIsActive }"
        >
          <template v-for="(item, index) in items" :key="item.id">
            <Transition name="overview-slide-fade" mode="out-in" appear>
              <CardMedia
                :key="item.id"
                size="medium"
                :image-src="item.image"
                :title="getTitle(item)"
                :tag="getCustomTitle(item)"
                :style="getTransitionDelayStyle(index)"
              />
            </Transition>
          </template>
        </div>
      </div>
      <layout-logo
        class="overview-slider-logo"
        :title="title"
        :category="category"
        :logo="buildAssetPath({ src: logo })"
      />
    </layout-text-gradient>
  </section>
</template>

<script setup lang="ts">
  import CardMedia from '@/components/card/CardMedia.vue'
  import LayoutLogo from '@/components/layout/logo/LayoutLogo.vue'
  import LayoutTextGradient from '@/components/layout/text-gradient/LayoutTextGradient.vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { useOverviewSliderHandler } from '@/views/collections/overview-slider/composables/useOverviewSliderHandler'
  import { useOverviewSliderMessages } from '@/views/collections/overview-slider/composables/useOverviewSliderMessages'
  import {
    OverviewSliderItem,
    OverviewSliderMessage,
  } from '@/views/collections/overview-slider/OverviewSlider.interface'
  import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'

  const props = defineProps<{ message: OverviewSliderMessage; actionMessage?: string }>()

  const screenID = 'OverviewSlider'
  const state = reactive({
    sliderPosition: 0,
    containerWidth: 0,
    dragIsActive: false,
  })

  const title = computed(() => props.message.state?.title)
  const category = computed(() => props.message.state?.category)
  const logo = computed(() => props.message.state?.logo)
  const items = computed(() => props.message.content?.items)
  const hideTitle = computed(() => props.message.state?.hideTitle)
  const useCustomTitle = computed(() => props.message.state?.useCustomTitle)

  const getTransitionDelayStyle = (index: number) => {
    const scrollPosition = props.message.state?.position || 0
    const transitionDelay = `${index * 100 + 300}ms`
    return { transitionDelay: Math.abs(scrollPosition) > 1000 ? 0 : transitionDelay }
  }

  const getTitle = (item: OverviewSliderItem) => (!hideTitle.value && !useCustomTitle.value ? item.title : '')
  const getCustomTitle = (item: OverviewSliderItem) =>
    !hideTitle.value && useCustomTitle.value ? item.custom_title : ''

  const scrollContainerRef = ref<HTMLElement | null>(null)
  const { onMessage, dragProgress } = useOverviewSliderMessages(props, state)
  const { resetSliderPosition, updateContainerWidth, updateContainerTransformStyle } = useOverviewSliderHandler(
    scrollContainerRef,
    state,
  )

  watch(() => props.message.content, resetSliderPosition)
  watch(() => state.sliderPosition, updateContainerTransformStyle)

  // initLoad
  onMounted(async () => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })

    await updateContainerWidth()
    const { state } = props.message
    if (state?.position && state?.containerWidth) {
      dragProgress({ value: state.position, containerWidth: state.containerWidth })
    }
  })

  // cleanUp
  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID, onMessage })
  })
</script>

<style lang="scss" scoped>
  .overview-slider {
    background: $black;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;

    .mod-topics-slider {
      display: flex;
      overflow: hidden;
      min-height: 100%;
    }
    .mod-topics-container {
      display: flex;
      flex-flow: row nowrap;
      gap: get-vw($g-15);
      padding: 0 get-vw(160px);

      .visual-card {
        width: get-vw(480px);
        max-width: 50vw;
        position: relative;
        align-self: center;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
    .overview-slider-logo {
      position: absolute;
      bottom: 0;
    }
  }

  // Animation
  .overview-slide-fade {
    &-enter-active,
    &-leave-active {
      transition:
        opacity 3s cubic-bezier(0.25, 0.8, 0.25, 1),
        transform 3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateX(5vw);
    }
    &-enter-to,
    &-leave-from {
      opacity: 1;
      transform: translateX(0);
    }
  }
</style>
