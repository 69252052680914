<template>
  <template v-if="isScreenView">
    <Transition :name="componentTransitionName" mode="out-in">
      <component :is="currentView" :message="screenMessage" />
    </Transition>
  </template>
  <template v-else>
    <component :is="layout">
      <router-view></router-view>
    </component>
  </template>
</template>

<script lang="ts" setup>
  import './assets/scss/layout.scss'
  import { onMounted, ref, computed, onUnmounted, nextTick } from 'vue'
  import { useScreenView } from '@/composables/useScreenView'
  import { useRemote } from '@/composables/socket/useRemote'
  import { useRoute } from 'vue-router'
  import i18n from '@/plugins/i18n'
  const route = useRoute()
  const isLoading = ref(true)
  const { screenMessage, currentView, componentTransitionName } = useScreenView()
  const { isScreenView, loadRemote } = useRemote()
  import DefaultLayout from '@/layouts/DefaultLayout.vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { EmitMessage } from '@/interface/WebSocket'
  const SCREEN_ID = 'App'

  const layout = computed(() => route.meta.layout || DefaultLayout)

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method } = message.data.emit
    const actions: Record<string, () => void> = {
      // reload: () => reload(),
    }
    actions[method]?.()
  }

  onMounted(() => {
    ShowroomScreen.addObserver({ id: SCREEN_ID, onMessage })

    // TODO: remove setTimeout
    setTimeout(() => {
      i18n.global.locale = route.params.lang || i18n.global.fallbackLocale
      loadRemote().then(() => (isLoading.value = false))
    }, 100)
  })

  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: SCREEN_ID, onMessage })
  })
</script>

<style lang="scss" scoped>
  .loading {
    background-color: $gray-800;
    position: fixed;
    inset: 0;
    color: #fff;
    display: grid;
    place-items: center;
  }
</style>
