<template>
  <div class="stats-box-icon d-flex g-5" :class="classes">
    <div class="stats-icon rounded-4 place-center flex-shrink-0" :class="iconClass" :style="iconStyle">
      <icon-tag :icon="icon"></icon-tag>
    </div>
    <div class="stats-content d-flex align-start flex-col justify-center flex-fill g-1 overflow-hidden">
      <div class="caption text-body-4 font-weight-600 color-gray-400">
        <div class="caption_area">
          <slot name="caption">{{ caption }}</slot>
        </div>
        <div class="action_area">
          <slot name="action"></slot>
        </div>
      </div>
      <div class="headline heading-1 font-weight-600 color-black d-block max-w-100 text-truncate-1">
        <slot name="headline">{{ headline }}</slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { computed, reactive, watch } from 'vue'
  import { useColor } from '@/composables/useColorUtils'

  const props = defineProps({
    icon: {
      type: String,
      default: 'icon-interface-placeholder',
    },
    headline: {
      type: [String, Number],
      default: '',
    },
    caption: {
      type: [String, Number],
      default: '',
    },
    bgColor: {
      type: String,
      default: 'gray-100',
    },
    color: {
      type: String,
      default: 'black',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  })

  const bgColors = reactive(useColor(props.bgColor, 'background'))
  const colors = reactive(useColor(props.color, 'text'))

  watch(
    () => props.bgColor,
    (newValue) => {
      Object.assign(bgColors, useColor(newValue, 'background'))
    },
  )

  watch(
    () => props.color,
    (newValue) => {
      Object.assign(colors, useColor(newValue, 'text'))
    },
  )

  const iconClass = computed(() => {
    const bgClassObj = {}
    bgColors.colorClasses.forEach((className) => {
      bgClassObj[className] = true
    })

    const colorClassObj = {}
    colors.colorClasses.forEach((className) => {
      colorClassObj[className] = true
    })

    return {
      ...bgClassObj,
      ...colorClassObj,
    }
    //
    // return {
    //   ...bgColors.colorClasses.reduce((obj, className) => ({ ...obj, [className]: true }), {}),
    //   ...colors.colorClasses.reduce((obj, className) => ({ ...obj, [className]: true }), {}),
    // }
  })

  const iconStyle = computed(() => {
    return {
      ...bgColors.colorStyles,
      ...colors.colorStyles,
    }
  })
  const classes = computed(() => {
    return {
      'is-disabled': props.disabled,
    }
  })
</script>
<style scoped lang="scss">
  .stats-icon {
    width: get-vw(60px);
    height: get-vw(60px);
  }

  :deep(.v-icon) {
    font-size: get-vw(18px);
  }
  .is-disabled {
    .caption,
    .headline {
      color: $gray-300 !important;
    }
    :deep(.v-icon) {
      color: $gray-300 !important;
    }
  }
  .caption {
    display: grid;
    grid-template-areas: 'caption action';
    grid-template-columns: 1fr fit-content(get-vw(120px));
    width: 100%;
    align-items: center;
  }
  .caption_area {
    grid-area: caption;
  }
  .action_area {
    grid-area: action;
  }
</style>
