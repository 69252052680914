<template>
  <section class="detail-carousel">
    <SalesTableContainer class="detail-carousel-container">
      <template v-slot:left>
        <LayoutSide
          :caption="item.caption"
          :title="title"
          :subtitle="item.subtitle"
          :images="item.images"
          :tag="customTitle"
          :show-content="!!(title || customTitle)"
        />
      </template>
      <Carousel
        v-if="products.length"
        v-model="productSelected"
        class="detail-carousel-wrapper"
        :products="products"
        @ready="updateCarouselOptions"
      >
        <template v-slot:info>
          <TransitionGroup name="slide-box" appear>
            <ProductHeader
              v-for="product in products"
              :id="`product-${product.id}`"
              :key="product.id"
              class="product-info"
              :category="product.category"
              :title="product.name"
              :subtitle="product.sku"
              :tags="product.tags"
              :color="product.color"
              :whs="product.whs"
              :rrp="product.rrp"
              :counter="getCounter(product.id)"
            />
          </TransitionGroup>
        </template>
      </Carousel>
      <LayoutState v-else :image-src="item.images[0]" :text="$t('no_results.capsule')" />
    </SalesTableContainer>
  </section>
</template>

<script lang="ts" setup>
  import SalesTableContainer from '@/components/container/SalesTableContainer.vue'
  import ProductHeader from '@/components/content/product/ProductHeader.vue'
  import LayoutState from '@/components/layout/state/LayoutState.vue'
  import LayoutSide from '@/components/layout/side/LayoutSide.vue'
  import Carousel from '@/components/webgl-carousel/Carousel.vue'
  import { useProductCounter } from '@/composables/product/useProductCounter'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import eventHub from '@/utils/EventHub'
  import {
    DetailCarouselItem,
    DetailCarouselMessage,
  } from '@/views/collections/detail-carousel/DetailCarousel.interface'
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

  const screenID = 'DetailCarousel'
  const props = defineProps<{ message: DetailCarouselMessage }>()

  const productSelected = ref<DetailCarouselItem | null>(null)
  const item = computed(() => props.message.content?.item)
  const products = computed(() => props.message.content.items)

  const title = computed(() => (!item.value?.hideTitle && !item.value?.useCustomTitle ? item.value.title : ''))
  const customTitle = computed(() =>
    !item.value?.hideTitle && item.value?.useCustomTitle ? item.value.custom_title : '',
  )

  const { getCounter } = useProductCounter()
  const onMessage = (message) => {
    if (message.event === 'update') {
      const resetOptions = { target: 0, width: 0, index: 0, direction: 'up', isResize: true }
      updateCarouselOptions(resetOptions)
    }
    if (message.event === 'emit' && message.data.emit) {
      const { method, args } = message.data.emit

      const actions: Record<string, () => void> = {
        setProgress: () => updateCarouselOptions(args),
      }
      actions[method]?.()
    }
  }

  function updateCarouselOptions(options?: DetailCarouselMessage['content']['sliderOptions']) {
    const newOptions = options || props.message.content?.sliderOptions
    if (!newOptions) return
    eventHub.emit('topic-slider-update', newOptions)
  }

  onMounted(() => ShowroomScreen.addObserver({ id: screenID, onMessage }))
  onBeforeUnmount(() => ShowroomScreen.removeObserver({ id: screenID, onMessage }))
</script>

<style lang="scss" scoped>
  .detail-carousel {
    position: relative;
    background: $white;

    .detail-carousel-container {
      gap: get-vw($g-2);
    }

    :deep(.scroll-container) {
      overflow: hidden;
    }

    .detail-carousel-wrapper {
      background-image: url('@/assets/img/detail_bg.jpg');
      background-position: center;
      background-size: cover;
    }

    .product-info {
      position: absolute;
      opacity: 0;
      width: get-vw(640px);
      right: get-vw(50px);
      bottom: get-vw(50px);
    }
  }
  .slide-box {
    &-enter-active,
    &-leave-active {
      transition:
        opacity 1s ease-in-out,
        transform 1.5s ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateX(100px);
    }

    &-enter-to,
    &-leave-from {
      opacity: 1;
      transform: translateX(0);
    }
  }
</style>
