import { send } from '@/utils/graphql/graphQL'
class Logger {
  async log(type: string, info: { error; message }) {
    try {
      const online = window.navigator.onLine
      if (!online) return

      const variables = {
        logData: [
          {
            type,
            salesapp_user_id: 9, // TODO v5: get user id from store
            salesapp_version: `showroom-${__APP_VERSION__}`,
            message: typeof info.message === 'string' ? info.message : JSON.stringify(info.message),
            service: window.location.href,
            stack: JSON.stringify(info.error, this.replaceErrors),
          },
        ],
      }

      const query = `mutation add($logData: [SalesAppLogInputType]! ) {
            addSalesAppLog(logData: $logData) {
                id
                salesAppUserId
                salesAppVersion
                type
                message
                createdAt(format: "Y-m-d")
                errors
            }
        }`
      console.error(info.message, info.error)
      await send(query, variables)
    } catch (e) {
      //
    }
  }
  replaceErrors(key, value) {
    try {
      if (value instanceof Error) {
        let error = {}

        Object.getOwnPropertyNames(value).forEach(function (propName) {
          error[propName] = value[propName]
        })

        return error
      }
    } catch (e) {
      console.error('Error while replacing error properties.')
    }

    return value
  }
}

export default new Logger()
