import { useSettingsStore } from '@/stores/settings'

interface formatDatePayload {
  str: string
  format?: string
  time?: boolean
  convertFromUTC?: boolean
  yearShort?: false
}
export function formatDate(payload: formatDatePayload) {
  const {
    str,
    format = null,
    time = false,
    convertFromUTC = true,
    yearShort = false,
  } = payload

  if (!str) {
    return '-'
  }

  const { dateFormat, language } = useSettingsStore()

  const date = convertFromUTC ? new Date(`${str} UTC`) : new Date(str)

  const outputFormat = format || dateFormat

  let year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  // Shortens the year if yearShort is true
  if (yearShort) {
    year = parseInt(year.toString().slice(-2), 10)
  }

  let formattedStr

  switch (outputFormat) {
    case 'd.m.Y':
      formattedStr = `${day.toString().padStart(2, '0')}.${month
        .toString()
        .padStart(2, '0')}.${year}`
      break
    case 'Y-m-d':
      formattedStr = `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`
      break
    case 'full':
      const locale = language === 'de' ? 'de-DE' : 'en-GB'

      formattedStr = date
        .toLocaleString(locale, { dateStyle: 'long', timeStyle: 'short' })
        .toString()
      break
    default:
      formattedStr = `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`
  }

  if (time) {
    const time = `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
    formattedStr = `${formattedStr}, ${time}`
  }

  return formattedStr
}
