import {
  CatalogImagePerspective,
  CatalogProduct,
  CatalogProductMessage,
} from '@/views/catalog-overview/interface/CatalogOverview.interface'
import { WhiteboardMessage } from '@/views/catalog-overview/interface/CatalogOverviewAll.interface'
import { computed } from 'vue'

export function useCatalogProductImage(props: { message: CatalogProductMessage | WhiteboardMessage }) {
  const perspective = computed<CatalogImagePerspective>({
    get() {
      return props.message.state?.perspective || 'front'
    },
    set(newPerspective: CatalogImagePerspective) {
      props.message.state.perspective = newPerspective
    },
  })

  function getImagePerspective(item: CatalogProduct) {
    return perspective.value === 'front' ? item?.image : item?.images[perspective.value] || item?.image
  }

  return {
    perspective,
    getImagePerspective,
  }
}
