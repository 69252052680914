import { CartDetailMessage } from '@/views/cart-detail/CartDetail.interface'
import { computed } from 'vue'
import i18n from '@/plugins/i18n'

export function useCartDetail(props: { message: CartDetailMessage }) {
  const position = computed(() => props.message?.content?.position)
  const itemData = computed(() => props.message?.content?.item)
  const itemSizes = computed(() => props.message?.content?.item?.sizes)
  const activeSizeGridId = computed(() => props.message?.content?.activeSizeGridId)
  const groupName = computed(() => props.message?.content?.groupName)

  const selectedDeliveryDate = computed<string>(() => {
    const selectedDate = itemData.value?.dates.find((date) => date.value === position.value.deliveryDate)
    if (selectedDate) {
      return selectedDate.text
    }
    return '-'
  })
  const stats = computed(() => props.message?.content?.stats)
  const statsData = computed(() => {
    return [
      {
        key: 'quantity',
        label: i18n.global.t('common.quantity'),
        value: stats?.value?.quantity,
        icon: 'icon-interface-stacks',
        animateUp: true,
      },
      {
        key: 'delivery_date',
        label: i18n.global.t('common.delivery_date'),
        value: selectedDeliveryDate.value,
        icon: 'icon-interface-date',
        animateUp: false,
      },
      {
        key: 'whs',
        label: i18n.global.t('common.whs'),
        value: stats?.value?.whs_total,
        icon: 'icon-interface-basket',
        animateUp: 'currency',
      },
      {
        key: 'rrp',
        label: i18n.global.t('common.rrp'),
        value: stats?.value?.rrp_total,
        icon: 'icon-interface-price',
        animateUp: 'currency',
      },
    ]
  })

  return {
    activeSizeGridId,
    itemSizes,
    position,
    itemData,
    statsData,
    groupName,
  }
}
