import { onBeforeUnmount, onMounted, ref } from 'vue'
export function useFullscreen() {
  const isFullscreen = ref(false)

  function toggleFullscreen() {
    if (!isFullscreen.value) {
      enterFullscreen()
    } else {
      exitFullscreen()
    }
  }

  function enterFullscreen() {
    const elem = document.documentElement
    if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen()
    }
    isFullscreen.value = true
  }
  function exitFullscreen() {
    if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
    isFullscreen.value = false
  }

  function onFullscreenChange() {
    isFullscreen.value = !!document.webkitFullscreenElement
  }

  onMounted(() => {
    document.addEventListener('webkitfullscreenchange', onFullscreenChange)
  })
  onBeforeUnmount(() => {
    document.removeEventListener('webkitfullscreenchange', onFullscreenChange)
  })

  return {
    isFullscreen,
    toggleFullscreen,
    enterFullscreen,
  }
}
