import useProductDetailSocket from '@/composables/useProductDetailSocket'
import { iFreeDragState } from '@/views/whiteboard-canvas/interface/FreeDragState.i'
import { useKonvaCreate } from '@/views/whiteboard-canvas/use/useKonvaCreate'
import { useKonvaItemGroup } from '@/views/whiteboard-canvas/use/useKonvaItemGroup'
import { useKonvaUpdate } from '@/views/whiteboard-canvas/use/useKonvaUpdate'
import { useElementSize } from '@vueuse/core'
import { Ref, watch } from 'vue'

export function useKonvaMessage(state: iFreeDragState, screenID: string, containerRef: Ref<HTMLElement | null>) {
  const { createWhiteboard, updateWhiteboard } = useKonvaCreate(state)
  const { updateWhiteboardConfig } = useKonvaUpdate(state)
  const { updateItemGroup } = useKonvaItemGroup(state)
  const { setProductDetailData, closeProductDetail } = useProductDetailSocket()
  const { width: containerRefWidth } = useElementSize(containerRef)

  // Queue for methods
  const actionQueue: Array<{ method: string; args: any }> = []
  let isProcessingQueue = false

  // Helper function to execute the methods
  const executeMethod = async (method: string, args: any) => {
    if (method === 'updateWhiteboard') {
      await updateWhiteboard(args)
    } else if (method === 'updateWhiteboardConfig') {
      await updateWhiteboardConfig(args)
    } else if (method === 'createWhiteboard') {
      await createWhiteboard(args)
    }
  }

  // Function to update an existing method in the queue or add a new one if not present
  const updateOrAddToQueue = (method: string, args: any) => {
    const existingIndex = actionQueue.findIndex((item) => item.method === method)

    if (existingIndex !== -1) {
      // If method already exists, update the args
      actionQueue[existingIndex].args = args
    } else {
      // If method doesn't exist, add a new entry
      actionQueue.push({ method, args })
    }
  }

  // Function to enqueue updates, ensuring no duplicate methods in the queue
  const enqueueUpdate = async (method: string, args: any) => {
    // Update or add the method in the queue
    updateOrAddToQueue(method, args)

    // If the queue is already processing, return early
    if (isProcessingQueue) return
    isProcessingQueue = true

    // Process the queue
    while (actionQueue.length > 0) {
      const { method, args } = actionQueue.shift()!
      await executeMethod(method, args)
    }

    isProcessingQueue = false
  }

  // Watcher for containerRefWidth changes
  watch(containerRefWidth, () => {
    if (!state.isPrepare) {
      state.isPrepare = true
      enqueueUpdate('createWhiteboard', state.jsonString)
    }
  })

  // Handling incoming messages
  const onMessage = async (message) => {
    if (message.event === 'update' && message.data.view === screenID) {
      await prepareData(message.data.content?.jsonStructure)
      return
    }
    if (message.event === 'emit' && message.data?.emit) {
      const { method, args } = message.data.emit
      const actions: Record<string, () => void> = {
        setProductDetailData: () => setProductDetailData(args),
        closeProductDetail: () => closeProductDetail(),
        updateWhiteboard: () => enqueueUpdate('updateWhiteboard', args),
        updateWhiteboardConfig: () => enqueueUpdate('updateWhiteboardConfig', args),
        updateItemGroup: () => updateItemGroup(args),
      }

      actions[method]?.()
    }
  }

  // Preparing data based on message
  const prepareData = async (json: string | Record<string, any>) => {
    const jsonString = typeof json === 'string' ? json : JSON.stringify(json)
    const jsonStructure = typeof json === 'string' ? JSON.parse(json) : json
    const type = jsonStructure?.type as 'create' | 'update' | 'prepare'

    switch (type) {
      case 'prepare':
        state.isPrepare = true
        break
      case 'create':
        enqueueUpdate('createWhiteboard', jsonStructure)
        state.isPrepare = false
        break
      case 'update':
        enqueueUpdate('updateWhiteboard', jsonString)
        break
      default:
        console.warn(`Unknown type: ${type}`)
        break
    }
  }

  return {
    onMessage,
    prepareData,
  }
}
