<template>
  <div class="pt-8 pr-20 pb-20 pl-20">
    <div class="size-grids d-flex flex g-2 flex-col">
      <template v-for="sizeGrid in genItems">
        <Transition name="list-slide-right" mode="out-in" appear>
          <ProductDetailSizeHeadline
            v-if="gridsLength > 1"
            :headline="sizeGrid.sizeGridTitle"
          ></ProductDetailSizeHeadline>
        </Transition>
        <div class="size-grid-container" :style="{ '--sizes-grid-columns': sizeGrid.sizeGridOptions?.grid }">
          <TransitionGroup
            tag="div"
            class="content-sizes-grid"
            :class="[`g-${sizeGrid.sizeGridOptions.gap}`]"
            name="list-slide-right"
            appear
          >
            <template v-for="(size, index) in sizeGrid.items" :key="size.id">
              <SizeItem
                :title="size.name"
                :size="sizeGrid.sizeGridOptions.size"
                :style="{ transitionDelay: `${index * 10}ms` }"
              >
              </SizeItem>
            </template>
          </TransitionGroup>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import ProductDetailSizeHeadline from '@/components/_common/product/ProductDetailSizeHeadline.vue'
  import { useSizesGridOptions } from '@/composables/sizes/useSizesGridOptions'
  import SizeItem from '@/components/content/SizeItem.vue'

  const props = defineProps({
    scrollPercent: {
      type: Number,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
  })

  const { getOption, getMultiGridsDefault } = useSizesGridOptions()
  const gridsLength = computed(() => props.items.length)

  const genItems = computed(() => {
    return props.items.map((value) => {
      return {
        ...value,
        sizeGridOptions: gridsLength.value === 1 ? getOption(value?.items?.length) : getMultiGridsDefault(),
      }
    })
  })
</script>

<style scoped lang="scss">
  .content-sizes-grid {
    display: grid;
    grid-template-columns: repeat(var(--sizes-grid-columns), minmax(0, 1fr));
  }
</style>
