<template>
  <div v-if="showContent" class="product-info" :class="sizeClass">
    <div v-if="showInfo && (title || subtitle)" class="info-title">
      <div v-if="title" class="title text-truncate-1">{{ title }}</div>
      <div v-if="subtitle" class="subtitle text-truncate-1">{{ subtitle }}</div>
    </div>
    <div v-if="showColor && color?.hex && !colors.length" class="info-color">
      <ChipColor :size="size" :hex="color.hex" :code="color.color_number">
        {{ color.title }}
      </ChipColor>
    </div>
    <div v-if="(showInfo && hasPrice) || (showColor && colors.length)" class="info-bottom">
      <div v-if="showInfo && hasPrice" class="price">
        <div class="price-header">
          <span>{{ $t('price.whs') }}</span>
          <span> / </span>
          <span class="rrp">{{ $t('price.rrp') }}</span>
        </div>
        <div class="container">
          <span class="whs">{{ whsPrice }}</span>
          <span> / </span>
          <span class="rrp text-truncate-1">{{ rrpPrice }}</span>
        </div>
      </div>
      <div v-if="showColor && colors.length" class="color">
        <ColorDots :size="size" :colors="colors" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import ChipColor from '@/components/partials/chip/ChipColor.vue'
  import ColorDots from '@/components/content/product/ProductDots.vue'
  import { GeneralSize } from '@/interface/ComponentSizes'
  import { ProductColor } from '@/interface/messages/ProductColor.interface'
  import { priceFormatter } from '@/utils/helpers'
  import { computed, defineProps } from 'vue'

  const props = withDefaults(
    defineProps<{
      showInfo?: boolean
      showColor?: boolean
      size?: GeneralSize
      title?: string
      subtitle?: string
      whs?: number
      rrp?: number
      // eslint-disable-next-line vue/require-default-prop
      color?: ProductColor
      colors?: string[]
    }>(),
    {
      showInfo: true,
      showColor: true,
      size: 'default',
      title: '',
      subtitle: '',
      whs: 0,
      rrp: 0,
      colors: () => [],
    },
  )

  const showContent = computed(() => props.showInfo || props.showColor)
  const sizeClass = computed(() => `size-${props.size}`)
  const hasPrice = computed(() => props.whs || props.rrp)
  const whsPrice = computed(() => (props.whs ? priceFormatter(props.whs) : '-'))
  const rrpPrice = computed(() => (props.rrp ? priceFormatter(props.rrp) : '-'))
</script>

<style lang="scss" scoped>
  @mixin size-styles($headline, $sku, $gap, $gap-info, $priceGap, $containerGap, $priceHeader) {
    gap: get-vw($gap);

    .info-title {
      gap: get-vw($gap-info);

      .title {
        @include text-body($headline, $font-weight-600);
      }
      .subtitle {
        @include text-body($sku, $font-weight-600);
        color: $gray-600;
      }
    }
    .price {
      gap: get-vw($priceGap);

      .price-header {
        @include text-body($priceHeader, $font-weight-400);
      }
      .container {
        color: $gray-500;
        gap: get-vw($containerGap);
        @include text-body($headline, $font-weight-600);

        .whs {
          color: $black;
        }
      }
    }
  }

  .product-info {
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .info-title {
      display: flex;
      flex-direction: column;
      align-self: stretch;
    }
    .info-color {
      display: flex;
      align-items: center;
      gap: get-vw($g-3);
      align-self: stretch;
    }
    .info-bottom {
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: get-vw($g-3);
      align-self: stretch;
      justify-content: space-between;

      .price {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        width: 0;

        .container {
          display: flex;
          max-width: 100%;
        }
      }

      .color {
        display: flex;
        padding: get-vw($g-1) 0;
        align-items: center;
        gap: get-vw(-4px);
      }
    }
    // sizes
    &.size-large {
      padding: 0 get-vw($g-8) get-vw($g-8) get-vw($g-8);
      @include size-styles($heading-3, $heading-2, $g-4, $g-2, $g-2, 6px, $text-body-3);
    }

    &.size-medium {
      padding: 0 get-vw($g-8) get-vw($g-8) get-vw($g-8);
      @include size-styles($heading-2, $heading-1, $g-3, $g-2, $g-2, 6px, $text-body-3);
    }

    &.size-default {
      padding: 0 get-vw($g-6) get-vw($g-6) get-vw($g-6);
      @include size-styles($text-body-5, $text-body-4, $g-2, $g-1, $g-1, $g-1, $text-body-1);
    }

    &.size-small {
      padding: 0 get-vw($g-5) get-vw($g-5) get-vw($g-5);
      @include size-styles($text-body-4, $text-body-3, $g-2, $g-1, $g-1, $g-1, $text-body-1);
    }
  }
</style>
