<template>
  <section class="overview-grid">
    <layout-text-gradient :text="message.state?.title" type="dark">
      <div ref="scrollContainerRef" class="mod-topics scrollable">
        <div
          class="grid-content"
          :style="computedStyles"
          :class="{
            'per-row-3': items.length <= 3,
            'per-row-4': items.length == 4,
          }"
        >
          <div v-for="(row, index) in gridItems" :key="index" class="mod-topics-row" :class="[`position-${row.type}`]">
            <template v-for="(item, itemIndex) in row.items" :key="item.id">
              <Transition name="overview-slide-up" mode="out-in" appear>
                <CardMedia
                  :key="item.id"
                  class="grid-content-item"
                  :image-src="item.image"
                  :title="getTitle(item)"
                  :tag="getCustomTitle(item)"
                  :style="getTransitionDelayStyle(index, itemIndex)"
                />
              </Transition>
            </template>
          </div>
          <!-- grid bottom helper -->
          <div class="grid-content-bottom">&nbsp;</div>
        </div>
      </div>
      <layout-logo
        class="overview-grid-logo"
        :title="message.state?.title"
        :category="message.state?.category"
        :logo="logo"
      />
    </layout-text-gradient>
  </section>
</template>

<script lang="ts" setup>
  import CardMedia from '@/components/card/CardMedia.vue'
  import LayoutLogo from '@/components/layout/logo/LayoutLogo.vue'
  import LayoutTextGradient from '@/components/layout/text-gradient/LayoutTextGradient.vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { handleJumpToPercent } from '@/utils/helpers'
  import { useOverviewGridMessages } from '@/views/collections/overview-grid/composables/useOverviewGridMessages'
  import OverviewGridHelper from '@/views/collections/overview-grid/OverviewGrid.helper'
  import {
    GridItems,
    OverviewGridMessage,
    OverviewItem,
  } from '@/views/collections/overview-grid/OverviewGrid.interface'
  import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'

  const screenID = 'OverviewGrid'
  const props = defineProps<{ message: OverviewGridMessage }>()

  const scrollContainerRef = ref<HTMLElement | null>(null)
  const gridItems = ref<GridItems>([])

  const { onMessage } = useOverviewGridMessages(props, scrollContainerRef)

  const logo = computed(() => buildAssetPath({ src: props.message.state?.logo }))
  const items = computed(() => props.message.content?.items)
  const scrollPercentY = computed(() => props.message.content?.scrollPercentY || 0)
  const hideTitle = computed(() => props.message.state?.hideTitle)
  const useCustomTitle = computed(() => props.message.state?.useCustomTitle)
  const computedStyles = computed(() => {
    const isFewItems = items.value.length <= 4

    return {
      '--capsule-font-size-name': '1.2vw',
      ...(isFewItems && { 'justify-content': 'center', gap: '0' }),
    }
  })
  const getTitle = (item: OverviewItem) => (!hideTitle.value && !useCustomTitle.value ? item.title : '')
  const getCustomTitle = (item: OverviewItem) => (!hideTitle.value && useCustomTitle.value ? item.custom_title : '')

  const getTransitionDelayStyle = (index: number, itemIndex: number) => {
    const transitionDelayMultiplier = `${index * 200 + itemIndex * 50 + 300}ms`
    return { transitionDelay: Math.abs(scrollPercentY.value) > 30 ? 0 : transitionDelayMultiplier }
  }

  const initGrid = async () => {
    const gridHelper = new OverviewGridHelper()

    gridHelper.setItems(items.value)
    gridItems.value = await gridHelper.initGrid()
    await nextTick()

    scrollContainerRef.value?.classList.add('scroll-smooth')
    handleJumpToPercent(scrollContainerRef.value, scrollPercentY.value)
  }

  watch(
    () => props.message,
    () => initGrid(),
  )

  onMounted(() => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })
    initGrid()
  })

  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID, onMessage })
  })
</script>

<style lang="scss" scoped>
  $item-min-width: 14vw;

  .overview-grid {
    background: $black;
    aspect-ratio: 16/9;

    .mod-topics {
      display: flex;
      justify-content: center;
      height: 100%;

      &.scroll-smooth {
        scroll-behavior: smooth;
        overflow-y: auto;
      }

      .grid-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: get-vw($g-15);
        height: 100%;
        padding: 3em 0;

        .mod-topics-row:first-child {
          margin-top: 5vh;
        }
        .mod-topics-row:last-child {
          margin-bottom: 5vh;
        }

        &.per-row-3 {
          .mod-topics-row {
            gap: 3vw;
          }

          .grid-content-item {
            max-width: 22vw;
            width: 22vw;
          }
        }

        &.per-row-4 {
          .mod-topics-row {
            gap: 4vw;
          }

          .grid-content-item {
            max-width: 20vw;
            width: 20vw;
          }
        }

        &.per-row-3,
        &.per-row-4 {
          .mod-topics-row {
            flex: 0;
            margin: 0 10vw;
          }
        }
      }

      .grid-content-item {
        position: relative;
        min-width: $item-min-width;
        flex-grow: 0;
        flex-shrink: 0;
        align-self: center;

        &:nth-child(even) {
          margin-top: 5vh;
        }

        &:nth-child(odd) {
          margin-bottom: 5vh;
        }
      }
    }

    .mod-topics-row {
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      margin: 0 20vw;
      gap: get-vw($g-15);

      &.position-left {
        margin-left: 15vw;
      }

      &.position-right {
        margin-right: 15vw;
      }
    }

    .overview-grid-logo {
      position: absolute;
      bottom: 0;
    }
  }

  // Animation
  .overview-slide-up {
    &-enter-active,
    &-leave-active {
      transition:
        opacity 3s cubic-bezier(0.25, 0.8, 0.25, 1),
        transform 3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(5vw);
    }
    &-enter-to,
    &-leave-from {
      opacity: 1;
      transform: translateX(0);
    }
  }
</style>
