<template>
  <div class="checkout-group-item rounded-6 bg-white">
    <div class="checkout-group-header pt-7 pr-7 pb-7 pl-10">
      <div class="header-headline d-flex align-center g-8">
        <Transition name="scroll-left" mode="out-in">
            <IconTag v-if="props.selected" icon="icon-filled-arrow-circle-left"></IconTag>
            <IconTag v-else icon="icon-interface-shop"></IconTag>
        </Transition>
        <span class="heading-2 font-weight-600 text-truncate-1">{{ groupData?.name || '' }}</span>
        <ChipTag v-if="hasChipTag" :icon="hasChipTag?.icon">{{ hasChipTag.text }}</ChipTag>
      </div>
      <div class="header-gallery">
        <GridContainer :columns="medias.length ? medias.length : 1" :width="44" class="justify-end">
          <template v-if="medias.length">
            <ProductColor v-for="(media, index) in medias" :rounded="2" :src="media" variant="image" class="overflow-hidden">
              <template v-if="medias.length - 1 === index && productImages.length > props.maxImages">
                <OverlayContent>
                  <span class="color-white text-body-4 font-weight-600">+{{ productImages.length - medias.length }}</span>
                </OverlayContent>
              </template>
            </ProductColor>
          </template>
          <template v-else>
            <div class="variant-image-empty"></div>
          </template>
        </GridContainer>
      </div>
    </div>
    <div class="checkout-group-stats bg-gray-100 p-6 d-flex g-4">
      <template v-for="stats in statsData">
        <ContentStats
          :disabled="isDisabled"
          class="flex-fill"
          bg-color="white"
          :icon="stats.icon"
          :caption="stats.label"
          :headline="stats.value"
        >
        </ContentStats>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue'
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import ContentStats from '@/components/content/ContentStats.vue'
  import ChipTag from '@/components/partials/chip/ChipTag.vue'
  import GridContainer from '@/components/grid/GridContainer.vue'
  import ProductColor from '@/components/content/product/ProductColor.vue'
  import OverlayContent from '@/components/partials/overlay/OverlayContent.vue'
  import { priceFormatter, t } from '@/utils/helpers'

  const props = defineProps({
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxImages: {
      type: Number,
      default: 10,
    },
    group: {
      type: Object,
    },
  })
  const groupData = computed(() => props.group)
  const productImages = computed(() => groupData.value?.images)

  const medias = computed(() => {
    if (productImages?.value?.length > props.maxImages) {
      return productImages.value.slice(0, props.maxImages)
    }

    return productImages.value || []
  })

  const isDisabled = computed(() => (groupData?.value?.articles <= 0 && groupData?.value?.quantity <= 0) || props.disabled)

  const hasChipTag = computed(() => {
    if (groupData?.value?.articles <= 0 && groupData?.value?.quantity <= 0) {
      return {
        icon: 'icon-interface-empty',
        text: t('position.empty_group')
      }
    }
    if (groupData?.value?.missingCount) {
      return {
        icon: 'icon-interface-loading',
        text: t('position.open_positions')
      }
    }

    return false
  })

  const statsData = computed(() => {
    return [
      { key: 'items', label: t('common.item'), value: groupData?.value?.articles, icon: 'icon-interface-grid-plus' },
      { key: 'quantity', label: t('common.quantity'), value: groupData?.value?.quantity, icon: 'icon-interface-stacks' },
      { key: 'whs', label: t('common.whs'), value: priceFormatter(groupData?.value?.whs), icon: 'icon-interface-basket' },
      { key: 'rrp', label: t('common.rrp'), value: priceFormatter(groupData?.value?.rrp), icon: 'icon-interface-price' },
    ]
  })
</script>

<style scoped lang="scss">
  .checkout-group-item {
    border: solid 6px $gray-100;
    overflow: hidden;
  }

  .checkout-group-header {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: 1fr;
    gap: get-vw($g-6);
    grid-template-areas: 'header_left header_right';
  }

  .header-headline {
    grid-area: header_left;
    :deep(.v-icon) {
      font-size: get-vw(24);
    }
  }

  .header-gallery {
    grid-area: header_right;
  }

  :deep(.v-icon) {
    font-size: get-vw(18);
  }

  .item-preview {
    min-width: get-vw(44px);
    aspect-ratio: 44 / 52;
  }
  .variant-image-empty {
    aspect-ratio: 59 / 67;
  }
</style>
