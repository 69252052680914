import { reactive, ref } from 'vue'

export function useModel3D() {
  const is3dView = ref(false)
  const modelViewerRef = ref<any>(null)

  const model3d = reactive({
    src: '',
    cameraTarget: null,
    cameraOrbit: null,
    fieldOfView: null,
    turntableRotation: null,
  })

  const resetCamera = () => {
    model3d.cameraTarget = null
    model3d.cameraOrbit = null
    model3d.fieldOfView = null
    model3d.turntableRotation = null
  }

  const update3dModel = (data) => {
    if (modelViewerRef.value) {
      modelViewerRef.value.disableAutoRotate()
    }

    model3d.cameraOrbit = data.data.cameraOrbit
    model3d.cameraTarget = data.data.cameraTarget
    model3d.fieldOfView = data.data.fieldOfView
    model3d.turntableRotation = data.data.turntableRotation
  }

  return {
    is3dView,
    modelViewerRef,
    model3d,
    resetCamera,
    update3dModel,
  }
}
