<template>
  <div class="price" :class="genClasses">
    <span>{{ priceFormatter(props.whs) }}</span>
    <span class="mx-1">/</span>
    <span class="color-gray-500">{{ priceFormatter(props.rrp) }}</span>
  </div>
</template>

<script lang="ts" setup>
  import { computed, defineProps } from 'vue'
  import { priceFormatter } from '@/utils/helpers'

  const props = withDefaults(
    defineProps<{
      whs: number
      rrp: number
      size?: string
      dense?: boolean
    }>(),
    {
      size: 'normal',
    },
  )
  const genClasses = computed(() => [{ 'price-dense': props.dense }, props.size])
</script>

<style lang="scss" scoped></style>
