import { POSITION } from 'vue-toastification'
import i18n from './i18n'
import pinia from './pinia'

export const toastOptions = {
  position: POSITION.TOP_CENTER,
  transition: 'Vue-Toastification__fade',
  icon: false,
  closeButton: false,
  hideProgressBar: true,
  timeout: 3000,
  shareAppContext: true,
  onMounted: (_, toastApp) => {
    toastApp.use(i18n)
    toastApp.use(pinia)
  },
}
