<template>
  <div class="language-switcher">
    <a
      v-for="lang in languages"
      :key="lang"
      href=""
      class="text-body font-weight-600 p-2 text-uppercase"
      :class="{ 'is-active': lang.active }"
      @click.prevent="setLanguage(lang.label)"
    >
      {{ lang.label }}
    </a>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { supportedLanguages } from '@/utils/languageUtils'
  import { useRoute, useRouter } from 'vue-router'
  import i18n from '@/plugins/i18n'

  const route = useRoute()
  const router = useRouter()

  const languages = computed(() => {
    const supportedLocales = supportedLanguages
    if (supportedLocales.length) {
      return supportedLocales.map((locale) => {
        return {
          label: locale,
          active: locale === route.params.lang,
        }
      })
    }
    return []
  })

  async function setLanguage(language) {
    await router.replace({
      params: { ...route.params, lang: language },
      query: { ...route.query },
    })

    i18n.global.locale = language
  }
</script>

<style scoped lang="scss">
  a {
    color: rgba-percent($white, $opacity-500);
    text-decoration: none;
    border-radius: 100vmax;
    transition: background-color 0.3s ease-in-out;
    &:not(.is-active):hover {
      background-color: rgba-percent($white, $opacity-100);
    }
    &.is-active {
      color: $white;
      text-decoration: underline;
    }
  }
</style>
