<template>
  <div class="sales-table-container flex h-100" :class="genClass" :style="{ '--aspect-ratio': props.aspectRatio }">
    <div class="left-side">
      <slot name="left"></slot>
    </div>
    <div class="right-side overflow-hidden flex-fill">
      <div ref="scrollContainer" class="scroll-container overflow-scroll-y h-100 scroll-smooth">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, withDefaults, computed } from 'vue'
  import { handleJumpToPercent, handleScrollPercent, handleScrollToVirtualScrollItem } from '@/utils/helpers'
  import { ThemeType } from '@/utils/themeHelper'

  const props = withDefaults(
    defineProps<{
      theme?: ThemeType
      aspectRatio?: string | number
    }>(),
    {
      theme: 'light' as ThemeType,
      aspectRatio: 9 / 16,
    },
  )

  const scrollContainer = ref(null)
  const setContentScroll = (percentage) => {
    if (scrollContainer.value) {
      handleScrollPercent(scrollContainer.value, percentage)
    }
  }
  const setJumpContentScroll = (percentage) => {
    if (scrollContainer.value) {
      handleJumpToPercent(scrollContainer.value, percentage)
    }
  }

  const setTargetScroll = (value: number) => {
    handleScrollToVirtualScrollItem(scrollContainer.value, value)
  }

  const genClass = computed(() => {
    return {
      [`theme-${props.theme}`]: true,
    }
  })

  defineExpose({
    setContentScroll,
    setJumpContentScroll,
    setTargetScroll,
  })
</script>

<style lang="scss" scoped>
  .sales-table-container {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
  }
  .left-side {
    aspect-ratio: var(--aspect-ratio, 9/16);
  }

  .theme-dark {
    background-color: $black;
  }
  .theme-light {
    background-color: $white;
  }
</style>
