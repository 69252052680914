<template>
  <div class="product-header-combo">
    <!-- header-scroll -->
    <Transition name="scroll-down-header" mode="out-in">
      <div v-if="isScrollActive" class="header-scroll">
        <ProductHeaderScroll
          :title="title"
          :subtitle="subtitle"
          :counter="counter"
          :whs="whs"
          :rrp="rrp"
          :color="color"
        />
        <div class="header-divider" />
      </div>
    </Transition>
    <div ref="scrollContainerRef" class="scrollable scroll-smooth">
      <ProductHeader
        :class="isEmpty ? 'h-100' : ''"
        :category="category"
        :title="title"
        :subtitle="subtitle"
        :whs="whs"
        :rrp="rrp"
        :color="color"
        :tags="tags"
        :counter="counter"
      >
        <slot></slot>
      </ProductHeader>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useProductHeaderScroll } from '@/components/content/product/composables/useProductHeaderScroll'
  import ProductHeader from '@/components/content/product/ProductHeader.vue'
  import ProductHeaderScroll from '@/components/content/product/ProductHeaderScroll.vue'
  import { ProductTag } from '@/interface/messages/Product.interface'
  import { ProductColor } from '@/interface/messages/ProductColor.interface'

  const props = withDefaults(
    defineProps<{
      category?: string
      title: string
      subtitle: string
      counter?: number
      whs: number | null
      rrp: number | null
      color: ProductColor
      tags?: ProductTag[]
      scrollPercent?: number
      isEmpty?: boolean
    }>(),
    {
      category: '',
      counter: undefined,
      tags: () => [],
      scrollPercent: 0,
    },
  )
  const { isScrollActive, scrollContainerRef } = useProductHeaderScroll(props)
</script>

<style lang="scss" scoped>
  .product-header-combo {
    position: relative;
    overflow: hidden;
    background: $white;
    border-radius: get-vw($r-6);
    width: 100%;
    height: auto;

    .header-scroll {
      width: 100%;
      position: absolute;
      z-index: 10;
    }

    .content-box {
      overflow: hidden;
      padding: get-vw($g-9);
      max-width: 100%;
      flex: 1 0 0;

      .product {
        align-self: stretch;

        .product-top {
          align-self: stretch;
          min-height: get-vw(36);

          .category {
            width: 0;
            max-width: 100%;
            color: $gray-400;
            @include text-body($heading-1, $font-weight-600);
            flex: 1 0 0;
            align-self: stretch;
          }

          .product-tags-check-container {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: end;
            align-items: center;
            gap: get-vw($g-3);

            &:has(> *:only-child) {
              gap: 0;
            }

            .product-tags {
              transform: translateX(get-vw(50));
              transition: all 0.6s ease-in-out;
            }

            .product-check {
              min-width: 0;
              opacity: 0;
              transform: translateX(get-vw(50));
              transition: all 0.6s ease-in-out;
            }

            &.show-check {
              .product-tags {
                transform: translateX(0);
              }
              .product-check {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }
        }
        .product-headline {
          align-self: stretch;

          .title {
            color: $black;
            align-self: stretch;
            @include text-body($heading-4, $font-weight-600);
          }
          .subtitle {
            color: $gray-500;
            align-self: stretch;
            @include text-body($heading-1, $font-weight-600);
          }
        }
      }

      .detail {
        align-self: stretch;
        gap: get-vw($g-4);

        .color {
          flex: 1 0 0;

          .color-tag {
            padding: get-vw($g-1) 0;
          }
        }

        .price {
          width: 80%;
          flex: 1 0 0;

          .price-header {
            color: $black;
            text-align: right;
            @include text-body($text-body-2);
          }
          .price-content {
            max-width: 100%;
            @include text-body($heading-3, $font-weight-600);

            .rrp {
              color: $gray-500;
            }
          }
        }
      }
    }

    :deep(.header-divider) {
      width: 100%;
      height: get-vw(0.6);
      background: $gray-200;
    }

    .default-slot {
      overflow: hidden;
    }
  }

  .scroll-down-header {
    &-enter-active,
    &-leave-active {
      transition: transform 2s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    &-enter-from {
      transform: translateY(-100%);
    }
    &-leave-to {
      transform: translateY(-100%);
    }
    &-enter-to,
    &-leave-from {
      transform: translateY(0);
    }
  }
</style>
