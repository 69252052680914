import { useGeneralStore } from '@/stores/general'
import { useIntroStore } from '@/stores/intro'
import { queryCheckDeleted, queryCheckIntros, queryIntros, queryShopClients } from '@/utils/graphql/querys'

export async function getAllIntros() {
  const introStore = useIntroStore()

  let items = []

  // set variables for load
  const variables = {
    limit: -1,
    offset: 0,
    dateValueStart: '',
    salesAppId: 3,
  }

  if (!introStore.lastUpdate) {
    const data = (await send(queryIntros(), variables)) as any
    items = data?.showroomIntros?.results || []
    introStore.setItems(items)
  } else {
    // check for deleted intros
    items = introStore.items
    const deletedVariables = {
      limit: -1,
      offset: 0,
      dataModel: 'INTRO',
      dateValueStart: introStore.lastUpdate,
    }
    const deletedData = (await send(queryCheckDeleted(), deletedVariables)) as any
    const deletedCount = deletedData?.removedData?.totalCount || 0

    // check for new intros
    variables.dateValueStart = introStore.lastUpdate
    const data = (await send(queryCheckIntros(), variables)) as any
    const totalCount = data?.showroomIntros?.totalCount || 0
    if (totalCount > 0 || deletedCount > 0) {
      variables.dateValueStart = ''
      const introData = (await send(queryIntros(), variables)) as any
      items = introData?.showroomIntros?.results || []
      introStore.setItems(items)
    }
  }
  // update lastUpdate
  introStore.setLastUpdate()
  return items
}

export async function getAllShopClients() {
  const variables = {
    limit: -1,
    offset: 0,
    dateValueStart: null,
    salesAppId: 3,
  }

  const data = (await send(queryShopClients(), variables)) as any

  return data.clients.results || []
}

export function send(query: string, variables = {}) {
  const generalStore = useGeneralStore()
  const lang = 'de'
  const url = generalStore.config?.graphQL
  const apiKey = generalStore.config?.apiKey

  return new Promise((resolve, reject) => {
    try {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'fg-data-request': '1',
          'salesapp-user-authorization-key': apiKey,
        },
        body: JSON.stringify({
          query: query,
          variables: variables,
          locale: lang,
        }),
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.data) {
            resolve(data.data)
          }
        })
        .catch((e) => {
          reject(e)
        })
    } catch (e) {
      console.log('Error while fetching', e)
    }
  })
}
