import { ref, watch } from 'vue'

export function useProductHeaderColorWidth(props) {
  const detailRef = ref<HTMLElement | null>(null)
  const colorRef = ref<HTMLElement | null>(null)
  const showColorName = ref(true)

  const checkColorWidth = () => {
    setTimeout(() => {
      if (!detailRef.value || !colorRef.value) return
      const detailRect = detailRef.value.getBoundingClientRect()
      const colorRect = colorRef.value.getBoundingClientRect()
      // check colorName if it is at least 20% wide
      showColorName.value = colorRect.width / detailRect.width >= 0.2
    }, 200)
  }

  watch(
    () => [props.whs, props.rrp, props.color.title],
    () => checkColorWidth(),
  )

  return {
    detailRef,
    colorRef,
    showColorName,
    checkColorWidth,
  }
}
