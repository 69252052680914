<template>
  <div class="absolute inset-0 d-flex overflow-hidden">
    <Transition :name="props.transition" mode="out-in" appear>
      <div
        v-if="!is3dView"
        ref="imageContainerRef"
        :key="src"
        class="main-image mt-6 ml-6 mb-6 rounded-7 overflow-hidden"
      >
        <ImageLoader :src="buildAssetPath({ src: props.src, thumb: 2000 })" />
      </div>
      <model-viewer3d
        v-else
        ref="modelViewerRef"
        class="image-center"
        :src="buildAssetPath({ src: model3d.src })"
        :camera-orbit="model3d.cameraOrbit"
        :camera-target="model3d.cameraTarget"
        :field-of-view="model3d.fieldOfView"
      ></model-viewer3d>
    </Transition>
  </div>
</template>

<script setup lang="ts">
  import buildAssetPath from '@/utils/buildAssetsPath'
  import ImageLoader from '@/components/media/ImageLoader.vue'
  import { useImageZoom } from '@/views/product-detail/composables/useImageZoom'
  import { useModel3D } from '@/views/product-detail/composables/useModel3D'
  import { onMounted, onUnmounted } from 'vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { EmitMessage } from '@/interface/WebSocket'
  import ModelViewer3d from '@/components/model-viewer-3d/ModelViewer3d.vue'

  const screenID = 'ProductDetailImage'

  const props = defineProps({
    src: {
      type: String,
      default: '',
    },
    transition: {
      type: String,
      default: 'main-image-rotate',
    },
  })

  const { is3dView, modelViewerRef, model3d, resetCamera, update3dModel } = useModel3D()
  const { imageContainerRef, zoomImg, dragImg, zoomDragReset } = useImageZoom()

  const toggle3dView = (data) => {
    is3dView.value = data.state
    if (is3dView.value) {
      resetCamera()
    } else {
      zoomDragReset()
    }
    model3d.src = data.src
  }

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      dragImg: () => dragImg(args),
      zoomImg: () => zoomImg(args),
      zoomDragReset: () => zoomDragReset(),
      toggle3dView: () => toggle3dView(args),
      update3dModel: () => update3dModel(args),
    }
    actions[method]?.()
  }

  onMounted(() => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })
  })
  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID })
  })
</script>

<style scoped lang="scss">
  .main-image {
    z-index: -1;
    display: flex;
    align-items: center;

    img {
      transition: all 0.3s ease-in-out;
      transform: scale(var(--zoom), var(--zoom)) translate(var(--moveX), var(--moveY));
    }
  }
  .main-image-rotate {
    &-enter-active,
    &-leave-active {
      transition: all 0.5s ease-in-out;
    }
    &-enter-from {
      transform: translate(0, 100%) rotate(0deg);
      filter: blur(40px);
    }
    &-enter-to,
    &-leave-from {
      transform: translate(0, 0) rotate(0);
      filter: blur(0px);
    }
    &-leave-to {
      transform: translate(-100%, -100%) rotate(-35deg);
      filter: blur(40px);
    }
  }
  .main-image-scroll {
    &-enter-active,
    &-leave-active {
      transition: all 0.5s ease-in-out;
    }
    &-enter-from {
      transform: translateX(-100%);
      filter: blur(40px);
    }
    &-enter-to,
    &-leave-from {
      transform: translateX(0);
      filter: blur(0px);
    }
    &-leave-to {
      transform: translateX(100%);
      filter: blur(40px);
    }
  }
</style>
