<template>
  <div class="product-tile" :class="productItemClass" :style="tagStyle">
    <!-- overlay-select -->

    <Transition name="fade-transition" mode="out-in" appear>
      <div v-if="isSelected" class="overlay">
        <div class="overlay-header" />
      </div>
    </Transition>
    <!-- tags -->
    <div class="product-header">
      <div class="tags-left">
        <ProductTags v-if="showHeader" :size="size" :tags="tags" />
      </div>
      <div class="tags-right">
        <Transition name="fade-transition" mode="out-in" appear>
          <ChipState
            v-if="isSelected || showCounter"
            :class="isSelected ? 'chip-type-selected' : ''"
            :size="getCustomerSizes(size)"
            :counter="isSelected ? 0 : counter"
          />
        </Transition>
      </div>
    </div>
    <!-- image -->
    <div class="product-image">
      <ImageLoaderBg :key="productImage" :src="productImage" background-size="contain" transition="fade-transition" />
    </div>
    <!-- content -->
    <ProductInfo
      class="product-info"
      :show-info="showInfo"
      :show-color="showColor"
      :size="getDefaultSizes(size)"
      :title="title"
      :subtitle="subtitle"
      :rrp="rrp"
      :whs="whs"
      :color="color"
      :colors="colors"
    />
  </div>
</template>

<script lang="ts" setup>
  import ImageLoaderBg from '@/components/media/ImageLoaderBg.vue'
  import ChipState from '@/components/partials/chip/ChipState.vue'
  import { ExtendedSize, GeneralSize } from '@/interface/ComponentSizes'
  import { ProductTag } from '@/interface/messages/Product.interface'
  import ProductInfo from '@/components/content/product/ProductInfo.vue'
  import ProductTags from '@/components/content/product/ProductTags.vue'
  import { ProductColor } from '@/interface/messages/ProductColor.interface'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { getProductTagStyle } from '@/utils/productTagStyles'
  import { computed, defineProps } from 'vue'

  const props = withDefaults(
    defineProps<{
      type?: 'default' | 'absolute'
      size?: ExtendedSize
      imageSrc?: string
      title?: string
      subtitle?: string
      whs?: number
      rrp?: number
      counter?: number
      showHeader?: boolean
      showCounter?: boolean
      showInfo?: boolean
      showColor?: boolean
      isSelected?: boolean
      tags?: ProductTag[]
      // eslint-disable-next-line vue/require-default-prop
      color?: ProductColor
      colors?: string[]
    }>(),
    {
      type: 'default',
      size: 'default',
      imageSrc: '',
      title: '',
      subtitle: '',
      whs: 0,
      rrp: 0,
      counter: 0,
      showHeader: true,
      showInfo: true,
      showColor: true,
      tags: () => [],
      colors: () => [],
    },
  )

  const tagStyle = computed(() => getProductTagStyle({ tags: props.tags }))
  const productImage = computed(() => buildAssetPath({ src: props.imageSrc, thumb: 800 }))

  const productItemClass = computed(() => {
    const typeAbsoluteClass = props.type === 'absolute' ? 'type-absolute' : ''
    const sizeClass = `size-${props.size}`
    const isSelectedClass = props.isSelected ? 'is-selected' : ''
    return [typeAbsoluteClass, sizeClass, isSelectedClass]
  })
  const getCustomerSizes = (size: ExtendedSize) => {
    if (['large', 'extra-large'].includes(size)) return 'large'
    return size as GeneralSize | 'x-small'
  }
  const getDefaultSizes = (size: ExtendedSize) => {
    if (['large', 'extra-large'].includes(size)) return 'large'
    if (['small', 'x-small'].includes(size)) return 'small'
    return size as GeneralSize
  }
</script>

<style lang="scss" scoped>
  $border-radius: get-vw($r-3);

  .product-tile {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    border-radius: $border-radius;
    background-color: $gray-100;

    &.is-selected {
      &::after {
        position: absolute;
        border: get-vw($border-sm) solid rgba-percent($emphasis, $opacity-200);
        border-radius: $border-radius;
        content: '';
        width: 100%;
        height: 100%;
      }

      .overlay {
        top: 0;
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 get-vw($r-1) $gray-100;
        border-radius: $border-radius;
        background: rgba-percent($emphasis, 5);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: get-vw($r-1) solid $emphasis;
          border-radius: $border-radius;
          pointer-events: none;
          box-sizing: border-box;
        }
      }

      .chip-type-selected {
        background: $emphasis;
      }
    }

    .product-header {
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: get-vw($g-5);
      align-self: stretch;
      width: 100%;

      .tags-left {
        display: flex;
        align-items: flex-start;
        gap: get-vw($g-1);
      }

      .tags-right {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .product-image {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    .product-info {
      width: 100%;
    }

    // types
    &.type-absolute {
      .product-header {
        position: absolute;
        top: 0;
      }
      .product-info {
        position: absolute;
        bottom: 0;
      }
    }

    // size mixin
    @mixin common-styles($header-height, $chip-width, $chip-height, $icon-size, $padding) {
      .product-header {
        min-height: get-vw($header-height);
        margin-top: get-vw($padding);
        padding: 0 get-vw($padding) 0 get-vw($padding);
      }
    }

    // sizes
    &.size-extra-large {
      width: 100%;
      height: 100%;
      @include common-styles(36, 56, 24, 12, $g-6);

      .product-info {
        padding: 0 get-vw($g-14) get-vw($g-14) get-vw($g-14);
      }
    }
    &.size-large {
      width: get-vw(560px);
      height: get-vw(785px);
      @include common-styles(36, 56, 24, 12, $g-5);
    }
    &.size-medium {
      width: get-vw(440px);
      height: get-vw(641px);
      @include common-styles(32, 48, 20, 10, $g-4);
    }
    &.size-default {
      width: get-vw(360px);
      height: get-vw(495px);
      bottom: -1;
      @include common-styles(24, 40, 16, 8, $g-3);
    }
    &.size-small {
      width: get-vw(280px);
      height: get-vw(398px);
      @include common-styles(20, 32, 12, 6, $g-3);
    }
    &.size-x-small {
      width: get-vw(280px);
      height: get-vw(398px);
      @include common-styles(16, 32, 12, 6, $g-1);
    }
  }
</style>
