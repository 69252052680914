import { useMultipleSelection } from '@/composables/socket/useMultipleSelection'
import { useCatalogStore } from '@/stores/catalog'
import { CatalogProduct, CatalogProductMessage } from '@/views/catalog-overview/interface/CatalogOverview.interface'
import { computed } from 'vue'

export function useCatalogProductClass(props: { message: CatalogProductMessage }) {
  const catalogStore = useCatalogStore()
  const { checkItemIsSelected } = useMultipleSelection()

  const inUseClass = computed(() => props.message.state?.inUseClass || 'grayscale')
  const getFromUsedIdsMap = (itemId: string) => catalogStore.usedIdsMap[itemId]
  const isItemInUse = (item: CatalogProduct) => !!getFromUsedIdsMap(String(item.id))

  const itemClass = (item: CatalogProduct) => {
    const classes = {
      in_use: isItemInUse(item),
      'is-multiple-selected': checkItemIsSelected(String(item.id)),
    }
    classes[inUseClass.value] = true
    return classes
  }

  return { itemClass }
}
