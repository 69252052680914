import { DetailHeroMessage } from '@/views/collections/detail-hero/DetailHero.interface'

export const exampleDataTopicsDetail: DetailHeroMessage = {
  screen_id: 90001,
  view: 'TopicsDetail',
  state: null,
  content: {
    item: {
      id: 442,
      image: '/assets/img-storybook/capsule/capsule_1.jpeg',
      images: {
        left: '/assets/img-storybook/capsule/capsule_1_left.jpeg',
        main: '/assets/img-storybook/capsule/capsule_1_main.jpeg',
        right: '/assets/img-storybook/capsule/capsule_1_right.jpeg',
      },
      title: 'GLOBAL CAMPAIGN',
      subtitle: 'SS24 CAMPGAIN',
      description:
        'Power-Tailoring for those who pave their own way. Explore the bold and confident looks that have been pre-styled for WI24 campaign.',
    },
  },
}

export const detailHeroContentExample2: DetailHeroMessage['content'] = {
  item: {
    id: 446,
    image: '/assets/img-storybook/topics/topics_2.jpg',
    images: {
      left: '/assets/img-storybook/topics/topics_2.jpg',
      main: '/assets/img-storybook/topics/topics_2.jpg',
      right: '/assets/img-storybook/topics/topics_2.jpg',
    },
    title: 'OLIVE #3',
    subtitle: 'Sonnige Eleganz im Winter',
    description:
      'Die Brand bugatti vereint Leidenschaft, Genuss, soziale Verbundenheit und eine ausgeprägte Ästhetik. Im Fokus stehen hochwertige Materialien, Liebe zum Detail, eine exzellente Passform und ein faires Preis-Leistungs-Verhältnis.',
  },
}
