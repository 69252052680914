import { ref } from 'vue'

export function useColorVariant() {
  const showColorVariant = ref(false)
  const colorVariantData = ref({ state: undefined, content: undefined })

  const setColorVariantData = ({ state, content }) => {
    colorVariantData.value = { state, content }
    showColorVariant.value = true
  }

  const closeColorVariant = () => {
    colorVariantData.value = { state: undefined, content: undefined }
    showColorVariant.value = false
  }

  return {
    showColorVariant,
    colorVariantData,
    setColorVariantData,
    closeColorVariant,
  }
}
