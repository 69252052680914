<template>
  <div class="intro" :class="theme">
    <!-- IMAGE -->
    <image-fullscreen v-if="image" :image-src="image" />

    <div class="overlay" />

    <!-- CONTENT -->
    <intro-content />
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'
  import ImageFullscreen from '@/components/media/ImageFullscreen.vue'
  import { IntroMessage } from '@/views/intro/interface/Intro'
  import IntroContent from '@/views/intro/IntroContent.vue'

  const props = defineProps<{
    message: IntroMessage
  }>()

  const theme = computed(() => 'theme--' + (props.message.state?.theme || 'default'))
  const image = computed(() => props.message.content?.image)
</script>

<style scoped lang="scss">
  $box-container-width: 90vw;
  $box-center-h1: 4vh;
  $box-center-h2: 3vh;

  $box-intro-white-h1: 3.25vh;
  $box-intro-white-h2: 2.75vh;

  $box-intro-white-text: 1.5vh;

  .intro {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #000000;

    h1,
    h2,
    .intro-text {
      color: white;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 1vh;
    }

    h2 {
      font-size: $box-intro-white-h2;
      margin: 0;
    }

    .overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.15) 100%);
      position: absolute;
      inset: 0;
    }
  }
</style>
