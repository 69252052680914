<template>
  <div class="chart-content p-8">
    <canvas id="myChart"></canvas>
  </div>
</template>

<script setup lang="ts">
  import { markRaw, nextTick, onMounted, ref } from 'vue'
  import Chart from 'chart.js/auto'

  interface Datasets {
    datasets: {
      data: number[]
      backgroundColor: string[]
    }
  }

  const props = defineProps<{
    data: Datasets
  }>()

  const chartRef = ref(null)

  onMounted(async () => {
    await nextTick()
    const canvas = document.getElementById('myChart')
    const ctx = canvas.getContext('2d')
    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: props.data,
      options: {
        cutout: '35%',
        borderColor: '#202020',
        borderWidth: '6',
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        // maintainAspectRatio: false
      },
    })

    chartRef.value = markRaw(chart)
  })

  const updateChart = () => {
    chartRef.value.data = props.data
    chartRef.value.update()
  }

  defineExpose({
    updateChart,
  })
</script>

<style scoped lang="scss">
  .chart-content {
    width: 100%;
    aspect-ratio: 1 / 1;
    align-self: center;
  }
</style>
