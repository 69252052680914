import GridConstructor from '@/service/GridConstructor'
import { computed } from 'vue'

export function getGrid(data: { layout: 'landscape' | 'portrait' | 'cross-selling'; length: number }) {
  const { layout, length = 0 } = data

  const grids = computed(() => ({
    portrait: new GridConstructor().getPortrait(),
    landscape: new GridConstructor().getLandscape(),
    'cross-selling': new GridConstructor().getCrossSelling(),
  }))

  const grid = layout || 'portrait'

  if (length > 0) {
    return { 'grid-template-areas': grids.value[grid][length] }
  }
  return {}
}
