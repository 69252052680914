<template>
  <model-viewer
    id="model-viewer"
    ref="refModelViewer"
    touch-action="pan-y"
    shadow-intensity="1"
    shadow-softness="1"
    tone-mapping=""
    environment-image=""
    interaction-prompt="none"
    max-camera-orbit="auto 157.5deg 200%"
    :src="src"
    auto-rotate
    auto-rotate-delay="500"
    rotation-per-second="0.5rad"
    :camera-target="cameraTargetString"
    :camera-orbit="cameraOrbitString"
    :field-of-view="fieldOfViewString"
    :max-field-of-view="maxZoom"
    :min-field-of-view="minZoom"
    @load="handleLoaded()"
  >
    <div v-if="isLoading" class="loader-spinner-container">
      <LoaderSpinner color="black" />
    </div>
  </model-viewer>
</template>
<script setup>
  import '@google/model-viewer'
  import { ref, computed, defineEmits, onMounted } from 'vue'
  import LoaderSpinner from '@/components/feedback/loader/LoaderSpinner.vue'

  const refModelViewer = ref(null)

  const props = defineProps({
    src: {
      type: String,
      default: '',
    },
    cameraTarget: {
      type: Object,
      default: null,
    },
    cameraOrbit: {
      type: Object,
      default: null,
    },
    fieldOfView: {
      type: Number,
      default: null,
    },
    turntableRotation: {
      type: Number,
      default: null,
    },
    minZoom: {
      type: Number,
      default: 12,
    },
    maxZoom: {
      type: Number,
      default: 32,
    },
  })

  const emits = defineEmits(['loaded'])

  const isLoading = ref(true)

  const cameraTargetUnit = 'm'
  const cameraOrbitUnit = 'rad'
  const cameraFovUnit = 'deg'

  const cameraOrbitString = computed(() => {
    if (props.cameraOrbit) {
      return `${props.cameraOrbit.theta}${cameraOrbitUnit} ${props.cameraOrbit.phi}${cameraOrbitUnit} ${props.cameraOrbit.radius}${cameraTargetUnit}`
    } else {
      return null
    }
  })

  const cameraTargetString = computed(() => {
    if (props.cameraTarget) {
      return `${props.cameraTarget.x}${cameraTargetUnit} ${props.cameraTarget.y}${cameraTargetUnit} ${props.cameraTarget.z}${cameraTargetUnit}`
    } else {
      return null
    }
  })

  const fieldOfViewString = computed(() => {
    if (props.fieldOfView) {
      return `${props.fieldOfView}${cameraFovUnit}`
    } else {
      return null
    }
  })

  onMounted(() => {
      // modelCacheSize = 0, prevent <model-viewer> from caching models to prevent potential memory leak
      const modelViewerElement = customElements.get('model-viewer')
      if (modelViewerElement) {
          modelViewerElement.modelCacheSize = 0
      }
  })

  const handleLoaded = () => {
    isLoading.value = false
    emits('loaded')
  }

  const disableAutoRotate = () => {
    refModelViewer.value.autoRotate = false
    refModelViewer.value.resetTurntableRotation()
  }

  // const getCamera = () => {
  //   const camera = {}
  //
  //   camera.cameraOrbit = refModelViewer.value.getCameraOrbit()
  //   camera.cameraTarget = refModelViewer.value.getCameraTarget()
  //   camera.fieldOfView = refModelViewer.value.getFieldOfView()
  //   camera.turntableRotation = refModelViewer.value.turntableRotation
  //
  //   return camera
  // }

  defineExpose({
    disableAutoRotate,
    // getCamera,
  })
</script>
<style lang="scss" scoped>
  #model-viewer {
    --progress-bar-color: none;
    --progress-bar-height: 0px;
    height: 100vh;
    width: 100%;
  }
  .loader-spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
