import { defineStore } from 'pinia'

export const useProductPinnedStore = defineStore('productPinned', {
  state: () => ({
    isProductPinned: false,
    data: {
      state: {},
      content: {},
    },
  }),
  actions: {
    openProductPinned(payload: Record<string, any>) {
      this.isProductPinned = true
      this.data.state = payload.state
      this.data.content = payload.content
    },
    closeProductPinned() {
      this.isProductPinned = false
      this.data.state = {}
      this.data.content = {}
    },
  },
})
