import { EmitMessage } from '@/interface/WebSocket'
import { handleScrollPercent } from '@/utils/helpers'
import { OverviewGridMessage } from '@/views/collections/overview-grid/OverviewGrid.interface'
import { Ref, watch } from 'vue'

export function useOverviewGridMessages(
  props: { message: OverviewGridMessage },
  scrollContainerRef: Ref<HTMLElement | null>,
) {
  watch(
    () => props.message.content.scrollPercentY,
    (percent) => handleScrollPercent(scrollContainerRef.value, percent),
  )

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      scrollPercent: () => handleScrollPercent(scrollContainerRef.value, args),
    }

    actions[method]?.()
  }

  return {
    onMessage,
  }
}
