<template>
  <div class="product-tags" :class="[sizeClass]">
    <img
      v-for="(tag, index) in tagImages"
      :key="index"
      :src="tag"
      class="product-tag"
      alt="product-tag"
      @error="replaceEmpty"
    />
  </div>
</template>

<script lang="ts" setup>
  import { ExtendedSize } from '@/interface/ComponentSizes'
  import { ProductTag } from '@/interface/messages/Product.interface'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { computed, defineProps } from 'vue'

  const props = withDefaults(
    defineProps<{
      size?: ExtendedSize
      tags: ProductTag[]
    }>(),
    {
      size: 'default',
    },
  )

  const sizeClass = computed(() => `size-${props.size}`)
  const tagImages = computed(() => props.tags.map((t) => buildAssetPath({ src: t.logo })))
  const replaceEmpty = (e) => {
    e.target.style.display = 'none'
  }
</script>

<style lang="scss" scoped>
  .product-tags {
    pointer-events: none;
    gap: get-vw($g-1);
    display: flex;

    &.size-extra-large,
    &.size-large {
      .product-tag {
        height: get-vw(36px);
      }
    }
    &.size-medium {
      .product-tag {
        height: get-vw(32px);
      }
    }
    &.size-default {
      .product-tag {
        height: get-vw(24px);
      }
    }
    &.size-small {
      .product-tag {
        height: get-vw(20px);
      }
    }
    &.size-x-small {
      .product-tag {
        height: get-vw(16px);
      }
    }
  }
</style>
