<template>
  <transition name="fade-transition" mode="out-in">
    <div v-if="hasContent" class="content" :class="layout">
      <div class="title-container">
        <transition :name="transitionContentName" mode="out-in">
          <div v-if="introText" class="intro-text">{{ introText }}</div>
        </transition>
        <transition :name="transitionContentName" mode="out-in">
          <div :key="title" class="text-multi-line-truncate">
            <h1>{{ title }}</h1>
          </div>
        </transition>
        <transition :name="transitionContentName" mode="out-in">
          <h2 :key="subtitle" class="font-bold">
            {{ subtitle }}
            <span v-if="subtitle_2">{{ subtitle_2 }}</span>
          </h2>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
  import { useGeneralStore } from '@/stores/general'
  import { IntroMessage } from '@/views/intro/interface/Intro'
  import { computed } from 'vue'

  const generalStore = useGeneralStore()

  const transitionContentName = 'intro-slide-y'
  const screen = computed(() => generalStore.screen as IntroMessage)
  const layout = computed(() => screen.value.state?.layout || 'centered')
  const title = computed(() => screen.value.content?.title)
  const subtitle = computed(() => screen.value.content?.subtitle)
  const subtitle_2 = computed(() => screen.value.content?.subtitle_2)
  const introText = computed(() => screen.value.content?.introtext)
  const hasContent = computed(() => title.value || subtitle.value || subtitle_2.value || introText.value)
</script>
