<template>
  <div class="chip-state" :class="[sizeClass, typeBothClass]">
    <span v-if="isIconActive" class="left">
      <icon-tag icon="icon-filled-checkmark" />
    </span>
    <span v-if="isIconActive && isCounterActive" class="divider"></span>
    <transition :name="transitionName" mode="out-in">
      <span v-if="isCounterActive" :key="formattedCounter" class="right">
        {{ formattedCounter }}
      </span>
    </transition>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch, computed } from 'vue'
  import IconTag from '@/components/partials/icon/IconTag.vue'

  const props = withDefaults(
    defineProps<{
      size?: 'x-small' | 'small' | 'default' | 'medium' | 'large'
      type?: 'icon' | 'counter' | 'both'
      counter?: number
      counterMax?: number
    }>(),
    {
      size: 'default',
      type: 'both',
      counter: 0,
      counterMax: 10,
    },
  )

  const sizeClass = computed(() => (props.size ? `size-${props.size}` : ''))

  const isIconActive = computed(() => props.type === 'both' || props.type === 'icon')
  const isCounterActive = computed(() => (props.type === 'both' || props.type === 'counter') && props.counter > 0)
  const typeBothClass = computed(() => (isIconActive.value && isCounterActive.value ? `type-both` : ''))

  const formattedCounter = computed(() => {
    const counter = Math.round(Number(props.counter))
    return counter >= props.counterMax ? `${props.counterMax - 1}+` : counter
  })

  const previousCounter = ref(props.counter)
  const transitionName = ref('slide-up')

  watch(() => props.counter, setCounterTransitionName)

  function setCounterTransitionName(newVal: number, oldVal: number) {
    transitionName.value = newVal > oldVal ? 'counter-slide-up' : 'counter-slide-down'
    previousCounter.value = newVal
  }
</script>

<style lang="scss" scoped>
  @mixin chip-size($size, $fs-icon, $fs-text) {
    width: get-vw($size);

    .left,
    .right {
      min-width: get-vw($size);
      height: get-vw($size);
    }
    .left {
      font-size: get-vw($fs-icon);
    }
    .right {
      font-size: get-vw($fs-text);
    }
    .divider {
      height: get-vw($size);
    }

    &.type-both {
      width: get-vw(($size * 2));

      .left,
      .right {
        min-width: get-vw($size);
      }
    }
  }

  .chip-state {
    position: relative;
    display: flex;
    flex-shrink: 0;
    gap: get-vw($g-0);
    padding: get-vw($g-0);
    border-radius: get-vw($r-12);
    background: $black;
    color: $white;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    .divider {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: get-vw(1px);
      background: rgba-percent($white, $opacity-200);
    }

    .left,
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
    }

    // sizes
    &.size-large {
      @include chip-size(36, $text-body-4, $text-body-5);
    }
    &.size-medium {
      @include chip-size(28, $text-body-3, $text-body-4);
    }
    &.size-default {
      @include chip-size(24, $text-body-2, $text-body-3);
    }
    &.size-small {
      @include chip-size(20, $text-body-1, $text-body-2);
    }
    &.size-x-small {
      @include chip-size(16, $text-body-1, $text-body-2);
    }
  }
</style>
