import { serializeError } from 'serialize-error'
import { App } from 'vue'
import logger from '@/service/Logger'

export const setupAppErrorHandling = (app: App) => {
  app.config.errorHandler = (err) => {
    logger.log('error', { error: serializeError(err), message: err })
  }

  window.addEventListener('unhandledrejection', (event) => {
    event.preventDefault()

    if (typeof event.reason === 'string') {
      const message = event.reason
      logger.log('error', { message, error: message })
    } else {
      const { message, stack: error } = event.reason
      logger.log('error', { message, error })
    }
  })
}
