import { t } from '@/utils/helpers'
import { computed, ComputedRef, ref, watch } from 'vue'

export function useCartOverviewEmpty(noResults: ComputedRef<{ show: boolean, type: string }>, groupId: ComputedRef<number | undefined>) {

  const isEmpty = ref(false)
  const type = ref()

  const noResultsState = {
    group: {
      message: t('no_results.no_groups')
    },
    items: {
      message: t('no_results.group'),
    },
    positions: {
      message: t('no_results.positions'),
    },
    search: {
      message: t('no_results.search'),
    },
  }

  const getNoResultsMessageType = computed(() => {
    if(!isEmpty.value) {
      return { message: '' }
    }
    return noResultsState[type.value]
  })

  watch(() => noResults?.value?.type, (value) => {
    type.value = value && groupId.value ? value : 'group'
  })

  watch(() => groupId.value, (value) => {
    type.value = value ? noResults.value.type : 'group'
  }, { immediate: true })

  watch(() => noResults?.value?.show, (value) => {
    isEmpty.value = value
  }, { immediate: true })

  return {
    type,
    isEmpty,
    getNoResultsMessageType
  }

}
