<template>
  <div class="statistic-header g-6 d-flex" :class="genHeaderClass">
    <div class="statistic-icon" :class="genIconClass">
      <div v-if="props.color" :style="{ backgroundColor: props.color }" class="icon-color rounded-2"></div>
      <IconTag v-if="props.icon && !props.color" :size="14" class="color-white" :icon="props.icon"></IconTag>
    </div>
    <div class="statistic-headline flex-fill font-weight-600 heading-2" :class="genHeadlineClass">
      {{ props.title }}
    </div>
    <div class="statistic-append">
      <slot name="append">
        <span class="font-weight-600 heading-2" :class="genTextClass">{{ props.text }}</span>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { computed } from 'vue'

  const props = defineProps<{
    title: string
    icon?: string
    text: string
    color?: string
    single?: boolean
    useDefaultColor?: boolean
  }>()

  const genHeaderClass = computed(() => {
    return {
      'pt-4 pr-4 pb-2 pl-4': props.single,
      'p-3': !props.single,
    }
  })

  const genHeadlineClass = computed(() => {
    return {
      'color-gray-500': props.single,
      'color-white': !props.single,
    }
  })
  const genTextClass = computed(() => {
    return {
      'color-gray-500': props.single,
      'color-gray-400': !props.single,
    }
  })

  const genIconClass = computed(() => {
    return {
      'place-center border-sm border-gray-600 rounded-2': !props.color,
    }
  })
</script>

<style scoped lang="scss">
  .statistic {
    &-icon {
      width: get-vw(36);
      height: get-vw(36);
      flex-shrink: 0;
      svg {
        width: auto;
        height: 100%;
      }
    }
  }
  .icon-color {
    width: 100%;
    height: 100%;
  }
</style>
