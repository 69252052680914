import { handleScrollPercent } from '@/utils/helpers'
import { ref, watch } from 'vue'

export function useProductHeaderScroll(props: { scrollPercent: number }) {
  const isScrollActive = ref(false)

  const scrollContainerRef = ref<HTMLElement | null>(null)
  watch(() => props.scrollPercent, updateScrollPercent)

  function checkScrollState(percentage: number) {
    if (!scrollContainerRef.value || percentage < 1) {
      isScrollActive.value = false
      return
    }

    const productHeaderContent = document.getElementById('product-header-content')
    const productHeaderHeight = (productHeaderContent?.clientHeight || 200) / 2
    isScrollActive.value = scrollContainerRef.value.scrollTop > productHeaderHeight
  }

  function updateScrollPercent(percentage = 0) {
    handleScrollPercent(scrollContainerRef.value, percentage)
    setTimeout(() => checkScrollState(percentage), 300)
  }

  return {
    isScrollActive,
    scrollContainerRef,
  }
}
