<template>
  <sales-table-container ref="checkoutContainer">
    <template v-slot:left>
      <LayoutSide
        :show-content="!isEmpty"
        :caption="introData?.caption"
        :title="introData?.title"
        :subtitle="introData?.subtitle"
        :images="introData?.images"
        :type="selectedGroups.length ? 'statistics' : 'default'"
        :quantity="stats?.quantity"
        :items="stats.articles"
        :rrp="stats.rrp"
        :whs="stats.whs"
      />
    </template>
    <LayoutState
      v-if="isEmpty"
      size="default"
      :bg-text="$t('no_results.empty')"
      :text="$t('no_results.no_groups')"
      :image-src="singleIntroImage"
    />
    <div
      v-else
      class="checkout-overview d-flex flex-col g-6"
      :class="{ 'h-100': isLoading, 'overflow-hidden': !isLoading }"
    >
      <div v-if="isLoading" class="place-center" :class="{ 'h-100': isLoading }">
        <loader-chip>Loading</loader-chip>
      </div>
      <template v-for="(group, index) in groups" v-else>
        <Transition name="list-slide-right" appear>
          <CheckoutGroupItem
            :key="group.pos"
            :group="group"
            :selected="group.selected"
            :style="{ transitionDelay: `${index * 100}ms` }"
          ></CheckoutGroupItem>
        </Transition>
      </template>
    </div>
  </sales-table-container>
</template>
<script setup lang="ts">
  import { CheckoutOverviewMessage } from '@/views/checkout-overview/CheckoutOverview.interface'
  import { onMounted, ref, computed, watch, onUnmounted } from 'vue'
  import SalesTableContainer from '@/components/container/SalesTableContainer.vue'
  import CheckoutGroupItem from '@/components/content/checkout/CheckoutItem.vue'
  import LoaderChip from '@/components/feedback/loader/LoaderChip.vue'
  import LayoutSide from '@/components/layout/side/LayoutSide.vue'
  import LayoutState from '@/components/layout/state/LayoutState.vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { EmitMessage } from '@/interface/WebSocket'

  const isLoading = ref(true)

  const props = defineProps<{ message: CheckoutOverviewMessage }>()
  const reactiveContent = computed(() => props.message.content || {})
  const groups = ref(reactiveContent.value.groups)
  const intro = ref(reactiveContent.value.intro)
  const isEmpty = ref(false)

  const selectedGroups = computed(() => groups.value.filter((group) => group.selected))
  const stats = computed(() => {
    if (selectedGroups.value.length === 0) {
      return { articles: 0, whs: 0, rrp: 0, quantity: 0 }
    }

    return selectedGroups.value.reduce(
      (accumulator, item) => {
        accumulator.articles += item.articles
        accumulator.whs += item.whs
        accumulator.rrp += item.rrp
        accumulator.quantity += item.quantity
        return accumulator
      },
      { articles: 0, whs: 0, rrp: 0, quantity: 0 },
    )
  })

  watch(
    reactiveContent,
    (newValue) => {
      if (newValue.groups.length === 0) {
        isEmpty.value = true
      } else {
        isEmpty.value = false
        groups.value = newValue.groups
      }
    },
    { deep: true },
  )

  const introData = ref({
    images: intro?.value?.images,
    title: intro?.value?.title,
    subtitle: intro?.value?.subtitle,
    caption: intro?.value?.caption,
  })
  const singleIntroImage = computed(() => (introData?.value?.images?.length ? introData.value.images[0] : ''))

  const checkoutContainer = ref(null)
  function scrollPercentOverview(percentage: number) {
    if (checkoutContainer.value) {
      checkoutContainer.value.setContentScroll(percentage)
    }
  }
  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      scrollPercent: () => scrollPercentOverview(args),
    }
    actions[method]?.()
  }

  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: 'CheckoutOverview' })
  })

  onMounted(() => {
    ShowroomScreen.addObserver({
      id: 'CheckoutOverview',
      onMessage,
    })
    if (reactiveContent?.value?.groups?.length === 0) {
      isEmpty.value = true
    }
    isLoading.value = false
  })
</script>
<style scoped lang="scss">
  .checkout-overview {
    padding: get-vw($g-10) get-vw($g-15);
    overflow: hidden;
  }
</style>
