import { App } from 'vue'
import router from '@/router'
import i18n from './i18n'
import pinia from './pinia'
import { toastOptions } from './toast'
import Toast from 'vue-toastification'
import VueVirtualScroller from 'vue-virtual-scroller'
import InlineSvg from 'vue-inline-svg'

export function registerPlugins(app: App) {
  app.use(router)
  app.use(i18n)
  app.use(pinia)
  app.use(Toast, toastOptions)
  app.use(VueVirtualScroller)

  app.component('InlineSvg', InlineSvg)
}
