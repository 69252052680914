const createEmitAction = (method, args = {}) => ({
  event: 'emit',
  data: { screen_id: 90001, emit: { method, args } },
})

export const fullscreenVideoActions = {
  setMuteTrue: createEmitAction('setMute', { muted: true }),
  setMuteFalse: createEmitAction('setMute', { muted: false }),
  setVolume50: createEmitAction('setVolume', 50),
  pauseVideo: createEmitAction('pauseVideo'),
  playVideo: createEmitAction('playVideo'),
}
