import { FeedbackNotificationInterface } from '@/components/feedback/notification/FeedbackNotification.interface'
import FeedbackNotification from '@/components/feedback/notification/FeedbackNotification.vue'
import { ToastProductMessage } from '@/interface/ToastProductNotification'
import { WebSocketMessage, UpdateMessage, EmitMessage } from '@/interface/WebSocket'
import { useCatalogStore } from '@/stores/catalog'
import { t } from '@/utils/helpers'
import { ref } from 'vue'
import { useGeneralStore } from '@/stores/general'
import { useProductPinnedStore } from '@/stores/productPinned'
import { useSettingsStore } from '@/stores/settings'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import logger from '@/service/Logger'
import ShowroomScreen from '@/service/ShowroomScreen'
import i18n from '@/plugins/i18n'
import { useRoute, useRouter } from 'vue-router'

export function useWebSocket() {
  const toast = useToast()
  const route = useRoute()
  const router = useRouter()

  const generalStore = useGeneralStore()
  const settingsStore = useSettingsStore()
  const productPinnedStore = useProductPinnedStore()
  const catalogStore = useCatalogStore()

  const isLaunchPad = ref(false)

  const { screen } = storeToRefs(generalStore)

  const handleWebSocketMessage = (message: WebSocketMessage) => {
    if (message.event === 'update') {
      screen.value = message.data as UpdateMessage['data']
    } else if (message.event === 'emit') {
      const { emit } = message.data as EmitMessage['data']
      handleEmit(emit)
    }
  }

  const handleEmit = (emit?: { method: string; args: any }) => {
    if (!emit) return
    const { method, args } = emit

    switch (method) {
      case 'setSettings':
        loadOrUpdateSettings(args)
        break
      case 'notification':
        setTimeout(() => sendNotification(args), 1000)
        break
      case 'updateUsedIdsMap':
        catalogStore.update(args.usedIdsMap)
        break
      case 'setPinnedContainerData':
        const { state, content } = args
        productPinnedStore.openProductPinned({ state, content })
        break
      default:
        if (method.includes('pinnedContainer')) {
          productPinnedStore.closeProductPinned()
        }
        break
    }
  }

  function connectWebSocket(id: number) {
    loadOrUpdateSettings()
    ShowroomScreen.addObserver({ id: 'App', onMessage: handleWebSocketMessage })

    const screenID = id || ShowroomScreen.id
    if (screenID) {
      isLaunchPad.value = screenID.toString().slice(-1) === '4'

      if (isLaunchPad.value) {
        ShowroomScreen.connectWS('launchpad', screenID)
        screen.value.view = 'LaunchPad'
        screen.value.content = {}

        const baseId = screenID.toString().slice(0, -1)
        generalStore.setScreens({
          mainScreenId: parseInt(baseId + '1'),
          leftScreenId: parseInt(baseId + '2'),
          rightScreenId: parseInt(baseId + '3'),
        })
      } else {
        ShowroomScreen.connectWS('showroom_screen', screenID)
      }
    }

    // handle network events
    window.addEventListener('online', () => {
      logger.log('info', {
        message: 'SHOWROOM became online',
        error: 'Kein Error nur eine Info',
      })
    })

    document.addEventListener('sleep', () => {
      console.log('sleep bro')
    })
    document.addEventListener('wake', () => {
      console.log('wake up bro')
    })
  }

  async function loadOrUpdateSettings(newSettings?: Record<string, any>) {
    const settings = newSettings || JSON.parse(localStorage.getItem('settings') || '{}')

    try {
      if (newSettings && newSettings.language != route.params.lang) {
        i18n.global.locale = newSettings.language
        await router.replace({
          params: { ...route.params, lang: newSettings.language },
          query: { ...route.query },
        })
      }
      // if old localStorage settings not same as url language, use url language
      else if (settings.language !== route.params.lang) {
        settings.language = route.params.lang
        localStorage.setItem('settings', JSON.stringify(settings))
        i18n.global.locale = route.params.lang
      } else {
        i18n.global.locale = settings.language
      }
    } catch (e) {
      console.log('use default storage language', e)
      i18n.global.locale = settings.language
    }

    if (newSettings || !settingsStore.screenType) {
      settingsStore.setSettings(settings)
      if (newSettings) {
        localStorage.setItem('settings', JSON.stringify(newSettings))
      }
    }
  }

  function sendNotification(args: ToastProductMessage) {
    const { msgType, product, group, preselection = false, selections, missingProducts } = args
    const isAlreadyAdded = msgType === 'added' && missingProducts && missingProducts.length === 0

    console.log(args)
    const props: FeedbackNotificationInterface = {
      variant: 'default',
      type: 'group',
      groupName: group?.name,
      title: product?.name,
      imageSrc: product?.image,
      msgType: isAlreadyAdded ? 'already-added' : msgType,
    }

    // check type - preselection
    if (preselection) {
      props.type = 'preselection'
      props.groupName = t('notification.preselection')
    }
    // check variant - isStacked
    if (selections && selections?.length > 0) {
      props.variant = 'stacked'
      const count = missingProducts?.length || selections?.length
      props.title = t('common.items_n', { count })
    }

    toast({ component: FeedbackNotification, props })
  }

  return {
    isLaunchPad,
    connectWebSocket,
  }
}
