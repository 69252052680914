import { ExtendedSize } from '@/interface/ComponentSizes'
import { CatalogZoomType } from '@/views/catalog-overview/interface/CatalogOverview.interface'
import { computed, Ref } from 'vue'

export function useCatalogProductSize(zoom: Ref<CatalogZoomType>) {
  const sizeMap = { whiteboard: 8, small: 6, medium: 4, large: 3, xlarge: 2, single: 1 }
  const aspectMap = { small: '23/35', medium: '13/18', large: '472/677', xlarge: '714/947', single: '288/209' }
  const productSizes = { 1: 'extra-large', 2: 'large', 3: 'medium', 4: 'default', 6: 'small', 8: 'whiteboard' }

  const rowSize = computed(() => sizeMap[zoom.value])
  const aspectRatio = computed(() => aspectMap[zoom.value])
  const productSize = computed<ExtendedSize>(() => productSizes[rowSize.value] || 'default')

  return {
    rowSize,
    aspectRatio,
    productSize,
  }
}
