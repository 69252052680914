<template>
  <div class="header--element" :class="{ 'white--text': white }">
    <div class="header--element-title">
      <slot />
    </div>
    <div v-if="!!slots['label']" class="header--element-sublabel">
      <slot name="label" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { defineProps, useSlots } from 'vue'

  defineProps({
    white: {
      type: Boolean,
      default: false,
    },
  })
  const slots = useSlots()
</script>

<style lang="scss" scoped>
  .header--element {
    padding: get-vw($g-10) get-vw($g-4) get-vw($g-8) get-vw($g-4);
    display: flex;
    flex-direction: column;
    gap: get-vw($g-2);

    &.white--text {
      color: $white;
    }
    &-title {
      display: inline-block;
      @include text-body($heading-1, $font-weight-600);
    }
    &-sublabel {
      color: $gray-400;
      @include text-body($text-body-5, $font-weight-600);
    }

    &.dense {
      display: flex;
      flex-direction: row;
      align-items: center;

      .header--element-title {
        text-decoration: underline;
        font-size: 2.2vh;
      }
      .header--element-sublabel {
        margin-left: 1vw;
        background-color: $gray-100;
        border-radius: 2vh;
        width: 5vh;
        height: 3vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.8vh;
        line-height: 1.8vh;
      }
    }
  }
</style>
