<template>
  <div class="pt-9 pr-20 pb-9 pl-20">
    <TransitionGroup tag="div" class="information-grid" name="list-slide-right" appear>
      <ContentInformation
        v-for="(item, index) in props.items"
        :key="index"
        :caption="item.name"
        :headline="item.value"
        :class="[index > 1 ? 'border-top-sm border-gray-300' : '']"
        :style="{ transitionDelay: `${index * 50}ms` }"
      >
      </ContentInformation>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
  import ContentInformation from '@/components/content/ContentInformation.vue'

  const props = defineProps({
    scrollPercent: {
      type: Number,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
  })
</script>

<style scoped lang="scss">
  .information-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0 get-vw($g-8);
  }
</style>
