<template>
  <div ref="productHeaderRef" class="product-header">
    <!-- header-content  -->
    <div
      id="product-header-content"
      ref="headerContentRef"
      class="content-box d-flex flex-col align-start justify-center g-1"
    >
      <!-- product -->
      <div class="product d-flex flex-col align-start g-3">
        <div v-if="category || tags?.length" class="product-top d-flex align-center g-6">
          <div v-if="category" class="category d-flex flex-col justify-center">
            <Transition name="slide-right-text" appear>
              <span class="text-truncate-1">{{ category }}</span>
            </Transition>
          </div>

          <Transition name="slide-right-text" appear>
            <div class="product-tags-check-container" :class="{ 'show-check': isCounterExist }">
              <ProductItemTags v-if="tags?.length" class="product-tags" size="large" :tags="tags" />
              <ChipState class="product-check" size="large" type="both" :counter="counter" />
            </div>
          </Transition>
        </div>
        <div class="product-headline d-flex flex-col align-start g-3">
          <Transition name="slide-right-text" appear>
            <div class="title text-truncate-1" :style="{ 'transition-delay': '100ms' }">{{ title }}</div>
          </Transition>

          <Transition name="slide-right-text" appear>
            <div class="subtitle text-truncate-1" :style="{ 'transition-delay': '200ms' }">{{ subtitle }}</div>
          </Transition>
        </div>
      </div>
      <!-- detail -->
      <div ref="detailRef" class="detail d-flex align-end">
        <Transition name="slide-right-text" appear>
          <div
            ref="colorRef"
            class="color d-flex align-center g-4 text-truncate-1"
            :style="{ 'transition-delay': '300ms' }"
          >
            <ChipColor class="color-tag" size="large" :hex="color.hex" :code="color.color_number">
              <span v-if="showColorName" class="color-name">{{ color.title }}</span>
            </ChipColor>
          </div>
        </Transition>

        <Transition name="slide-right-text" appear>
          <div class="price d-flex flex-col align-end justify-center g-2" :style="{ 'transition-delay': '300ms' }">
            <div class="price-header">
              <span class="whs">{{ $t('price.whs') }}</span>
              <span> / </span>
              <span class="rrp">{{ $t('price.rrp') }}</span>
            </div>
            <div class="price-content flex align-center g-2">
              <span class="whs"> {{ priceFormatter(whs) }}</span>
              <span> / </span>
              <span class="rrp text-truncate-1">{{ priceFormatter(rrp) }}</span>
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <!-- default-slot -->
    <template v-if="$slots.default">
      <div class="divider" />
      <div class="default-slot" :style="defaultHeightStyle">
        <slot />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
  import { useProductHeaderColorWidth } from '@/components/content/product/composables/useProductHeaderColorWidth'
  import ChipColor from '@/components/partials/chip/ChipColor.vue'
  import ChipState from '@/components/partials/chip/ChipState.vue'
  import ProductItemTags from '@/components/content/product/ProductTags.vue'
  import { ProductTag } from '@/interface/messages/Product.interface'
  import { ProductColor } from '@/interface/messages/ProductColor.interface'
  import { priceFormatter } from '@/utils/helpers'
  import { useElementSize } from '@vueuse/core'
  import { computed, onMounted, ref } from 'vue'

  const props = withDefaults(
    defineProps<{
      category?: string
      title: string
      subtitle: string
      counter?: number
      whs?: number | null
      rrp?: number | null
      color: ProductColor
      tags?: ProductTag[]
    }>(),
    {
      category: '',
      counter: undefined,
      whs: 0,
      rrp: 0,
      tags: () => [],
    },
  )

  const headerContentRef = ref<HTMLElement | null>(null)
  const productHeaderRef = ref<HTMLElement | null>(null)
  const { height: totalHeight } = useElementSize(productHeaderRef)

  const { detailRef, colorRef, showColorName, checkColorWidth } = useProductHeaderColorWidth(props)
  const isCounterExist = computed(() => props.counter != null && props.counter >= 0)

  const defaultHeightStyle = computed(() => {
    if (!headerContentRef.value || !productHeaderRef.value) return { height: 'auto' }
    const headerHeight = headerContentRef.value.clientHeight
    const heightPercent = ((totalHeight.value - headerHeight) / totalHeight.value) * 100
    return { height: `${heightPercent}%` }
  })

  onMounted(() => checkColorWidth())
</script>

<style lang="scss" scoped>
  .product-header {
    position: relative;
    overflow: hidden;
    background: $white;
    border-radius: get-vw($r-6);
    width: 100%;

    .header-scroll {
      width: 100%;
      position: absolute;
      z-index: 10;
    }

    .content-box {
      overflow: hidden;
      padding: get-vw($g-9);
      max-width: 100%;
      flex: 1 0 0;

      .product {
        align-self: stretch;

        .product-top {
          align-self: stretch;
          min-height: get-vw(36);

          .category {
            width: 0;
            max-width: 100%;
            color: $gray-400;
            @include text-body($heading-1, $font-weight-600);
            flex: 1 0 0;
            align-self: stretch;
          }

          .product-tags-check-container {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: end;
            align-items: center;
            gap: get-vw($g-3);

            &:has(> *:only-child) {
              gap: 0;
            }

            .product-tags {
              transform: translateX(get-vw(50));
              transition: all 0.6s ease-in-out;
            }

            .product-check {
              min-width: 0;
              opacity: 0;
              transform: translateX(get-vw(50));
              transition: all 0.6s ease-in-out;
            }

            &.show-check {
              .product-tags {
                transform: translateX(0);
              }
              .product-check {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }
        }
        .product-headline {
          align-self: stretch;

          .title {
            color: $black;
            align-self: stretch;
            @include text-body($heading-4, $font-weight-600);
          }
          .subtitle {
            color: $gray-500;
            align-self: stretch;
            @include text-body($heading-1, $font-weight-600);
          }
        }
      }

      .detail {
        align-self: stretch;
        gap: get-vw($g-4);

        .color {
          flex: 1 0 0;

          .color-tag {
            padding: get-vw($g-1) 0;
          }
        }

        .price {
          width: 80%;
          flex: 1 0 0;

          .price-header {
            color: $black;
            text-align: right;
            @include text-body($text-body-2);
          }
          .price-content {
            max-width: 100%;
            @include text-body($heading-3, $font-weight-600);

            .rrp {
              color: $gray-500;
            }
          }
        }
      }
    }

    .divider {
      width: 100%;
      height: get-vw(0.6);
      background: $gray-200;
      margin-bottom: get-vw(0.6);
    }

    .default-slot {
      overflow: hidden;
    }
  }
</style>
