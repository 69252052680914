<template>
  <svg class="checkmark" :style="style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </svg>
</template>

<script setup lang="ts">
  import { useViewportCalculations } from '@/composables/useViewport'
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      size?: number
    }>(),
    {
      size: 25,
    },
  )

  const { pxToVW } = useViewportCalculations()

  const style = computed(() => ({
    '--icon-size': props.size ? `${pxToVW(props.size)}vw` : '',
  }))
</script>

<style lang="scss" scoped>
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke: $black;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: var(--icon-size);
    height: auto;
    stroke-linecap: round;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: $black;
    stroke-miterlimit: 10;
    animation:
      fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0 0 0 get-vw(30) $white;
    }
  }
</style>
