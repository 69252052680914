<template>
<div class="content-information pt-7 pb-7 d-flex flex-col g-2">
  <div class="caption color-gray-500 text-body-6 font-weight-600" v-text="props.caption"></div>
  <div class="headline heading-1 font-weight-600" v-text="props.headline"></div>
</div>
</template>

<script setup lang="ts">
const props = defineProps({
  caption: {
    type: String,
  },
  headline: {
    type: String
  }
})
</script>
