import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useWebSocket } from '@/composables/socket/useWebSocket'

export function useRemote() {
  const route = useRoute()
  const router = useRouter()

  const { connectWebSocket } = useWebSocket()

  const isScreenView = computed(() => route.name === 'screen')
  const isLaunchPadView = computed(() => typeof route.name === 'string' && route.name.includes('launchpad'))

  async function loadRemote() {
    const screenId = route?.query?.screen_id
    // check if we are in screen or launchpad view
    if (isLaunchPadView.value || isScreenView.value) {
      // if we dont have a screen_id, redirect to start connect screen
      if (!screenId) {
        await router.push({ name: 'start' })
      }

      const isLaunchPadID = screenId?.toString().slice(0, -1)
      // connect to showroom if we have at launchpad view last digit 4 or we are in 'screen' route
      if ((isLaunchPadView.value && isLaunchPadID) || isScreenView.value) {
        connectWebSocket(parseInt(route?.query?.screen_id as string, 10))
      }
    }
  }

  return {
    isScreenView,
    isLaunchPadView,
    loadRemote,
  }
}
