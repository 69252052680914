<template>
  <div class="product-detail-container overflow-hidden aspect-16-9" :class="{ 'show-video': showVideo }">
    <div class="product-bg fixed inset-0"></div>
    <div v-if="props.blurred" class="blurry-overlay z-1"></div>
    <div class="video-area">
      <div class="video-wrapper d-grid h-100 relative">
        <slot name="video"></slot>
      </div>
    </div>
    <div class="image-area z-0">
      <slot name="image"></slot>
    </div>
    <div class="content-area z-2 overflow-hidden" :class="[contentClass]">
      <div class="content-inner h-100 d-flex flex-col" :class="genContentInnerClass">
        <div class="content-top d-flex justify-end" :class="genContentTopClass">
          <slot name="medias"></slot>
        </div>
        <div class="content-bottom d-flex align-end mt-auto" :class="genContentBottomClass">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="overlay-container">
      <slot name="overlay"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    showVideo?: boolean
    blurred?: boolean
    fullView?: boolean
    contentClass?: string
  }>()

  const genContentBottomClass = computed(() => ({ 'pr-20 pb-20 pl-14': !props.fullView, 'p-6': props.fullView }))
  const genContentTopClass = computed(() => ({ 'pt-20 pr-20 pl-14': !props.fullView, 'pr-20': props.fullView }))
  const genContentInnerClass = computed(() => ({ 'full-view': props.fullView }))
</script>

<style scoped lang="scss">
  .product-bg {
    background-image: url('@/assets/img/detail_bg.jpg');
    z-index: -1;
    background-position: center;
    background-size: cover;
  }
  .product-detail-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .video-area,
  .image-area,
  .content-area {
    position: absolute;
    top: 0;
    height: 100%;
    transition: transform 1s ease-in-out;
  }

  .video-area {
    left: 0;
    transform: translateX(-100%);

    .show-video & {
      transform: translateX(0);
    }
  }

  .video-wrapper {
    aspect-ratio: 157/270;
    margin: get-vw($g-6);
    background: rgba-percent($black, $opacity-100);
    height: calc(100% - 2 * get-vw($g-6));
    border-radius: get-vw($r-4);
    overflow: hidden;

    :deep(video) {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-area {
    width: 50%;
    transform: translateX(0);

    .show-video & {
      transform: translateX(82%);
    }
  }

  .content-area {
    right: 0;
    width: 50%;
    transform: translateX(0);

    .show-video & {
      transform: translateX(100%);
    }
  }

  .content-top {
    height: 50%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    flex-shrink: 0;
  }

  .content-bottom {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.6s ease-in-out;
    width: 100%;
    height: 100%;
  }

  .full-view {
    :deep(.content-top) {
      height: 0;
    }
    :deep(.content-bottom) {
      flex: 1 1 auto;
    }
  }
</style>
