<template>
  <div class="statistic-stats d-flex flex-col g-2 flex-1 mw-0">
    <span class="text-body-4 font-weight-600 color-gray-500">
      {{ props.subtitle }}
    </span>
    <span class="heading-1 font-weight-600 color-white text-truncate-1">
      {{ props.title }}
    </span>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    title: string | number
    subtitle: string
  }>()
</script>

<style scoped lang="scss"></style>
