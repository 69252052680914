<template>
  <transition :name="transition">
    <img
      :key="imageID"
      :src="resolvedSrc"
      :style="cssStyles"
      alt="image"
      class="image"
      @error="handleError"
      @load="handleLoad"
    />
  </transition>
</template>

<script lang="ts" setup>
  import { computed, ref } from 'vue'

  const emit = defineEmits(['image-loaded', 'image-error'])
  const props = withDefaults(
    defineProps<{
      src: string
      transition?: string
      styles?: object
    }>(),
    {
      transition: 'fade',
      styles: () => ({}),
    },
  )

  const isLoaded = ref(false)
  const hasError = ref(false)

  const imageID = 'id' + Math.random().toString(16).slice(10)
  const resolvedSrc = computed(() => (hasError.value ? '' : props.src))
  const cssStyles = computed(() => ({ ...props.styles, opacity: isLoaded.value ? 1 : 0 }))

  const handleError = () => {
    if (!hasError.value) {
      hasError.value = true
      isLoaded.value = false
    } else {
      emit('image-error')
    }
  }

  const handleLoad = () => {
    isLoaded.value = true
    emit('image-loaded')
  }
</script>

<style lang="scss" scoped>
  .image {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-up-enter-active,
  .fade-up-leave-active {
    transition: all 0.5s ease-in-out;
  }

  .fade-up-enter-from,
  .fade-up-leave-to {
    opacity: 0;
    transform: rotate(20deg) translateY(200px);
  }
</style>
