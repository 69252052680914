<template>
  <section class="detail-hero">
    <div class="container">
      <div class="details--image">
        <div :key="item.id" class="topic--detail--image">
          <image-loader :src="buildAssetPath({ src: item.image, thumb: 2000 })" width="2000" height="2000" />
        </div>
        <div class="detail__section-image-background" />
      </div>

      <div class="details--content">
        <div :key="item.id" class="topic--detail--content">
          <div class="content__image content__image--first">
            <div class="v-responsive__sizer" />
            <div class="content__image--reveal" />
            <image-loader :src="buildAssetPath({ src: item.images.main, thumb: 800 })" class="topic--detail_image" />
          </div>
          <div class="content__image content__image--second">
            <div class="v-responsive__sizer" />
            <image-loader
              :src="buildAssetPath({ src: item.images.left, thumb: 800 })"
              width="600"
              height="600"
              class="topic--detail_image"
            />
          </div>
          <div class="content__image content__image--third">
            <div class="v-responsive__sizer" />
            <image-loader
              :src="buildAssetPath({ src: item.images.right, thumb: 800 })"
              width="600"
              height="600"
              class="topic--detail_image"
            />
          </div>
          <div class="content--title">
            <div class="heading-6 font-weight-600 text-truncate-2">{{ item.title }}</div>
            <div class="content--year text-body-5 font-weight-600 text-truncate-1">
              {{ item.subtitle }}
            </div>
          </div>
          <div class="content--description">
            <div class="heading-2 text-truncate-5">{{ item.description }}</div>
          </div>
        </div>
        <div class="detail__section-content-background" />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { useDetailHeroMessages } from '@/views/collections/detail-hero/composables/useDetailHeroMessages'
  import { onMounted, onUnmounted } from 'vue'
  import ImageLoader from '@/components/media/ImageLoader.vue'
  import buildAssetPath from '@/utils/buildAssetsPath'
  import { DetailHeroMessage } from '@/views/collections/detail-hero/DetailHero.interface'

  const screenID = 'DetailHero'
  const props = defineProps<{ message: DetailHeroMessage; actionMessage?: string }>()

  const { onMessage, animDetailIn, item } = useDetailHeroMessages(props)

  // initLoad
  onMounted(() => {
    ShowroomScreen.addObserver({ id: screenID, onMessage })
    animDetailIn()
  })

  // cleanUp
  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID, onMessage })
  })
</script>

<style scoped lang="scss">
  .detail-hero {
    width: 100%;
    background-color: $black;
    aspect-ratio: 16/9;
  }

  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .details--image {
      position: relative;
      width: 50%;
      height: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      overflow: hidden;

      .details--background {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .topic--detail--image {
        opacity: 0;
        position: absolute;
        top: 0;
        height: 100%;
        max-width: 100%;
        width: 100%;
        z-index: 10;

        .image {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    .details--content {
      position: relative;
      width: 50%;
      height: 100%;
      flex-grow: 0;
      flex-shrink: 0;

      .topic--detail--content {
        position: relative;
        display: flex;

        flex-direction: column;
        align-items: center;

        width: 100%;
        height: 100%;
        padding: get-vw(120px) get-vw(160px);
        gap: get-vw($g-10);

        .content__image {
          opacity: 0;
          position: absolute;
          overflow: hidden;
          top: get-vw(120px);

          &--first {
            width: get-vw(300px);
            height: get-vw(400px);
            z-index: 2;
          }
          &--second {
            width: get-vw(180px);
            height: get-vw(240px);
            z-index: 1;
          }
          &--third {
            width: get-vw(180px);
            height: get-vw(240px);
            z-index: 1;
          }

          .v-responsive__sizer {
            padding-bottom: 133%;
          }

          .image {
            object-fit: cover;
            width: 100%;
            height: inherit;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            will-change: transform;
          }

          .content__image--reveal {
            position: absolute;
            top: 0;
            background: white;
            width: 100%;
            height: 100%;
            z-index: 100;
            transform: translate(0);
            will-change: transform;
          }
        }

        .content--title {
          margin-top: 47vh;
          z-index: 100;
          will-change: transform;
          text-align: center;
          opacity: 0;
          width: 100%;

          span {
            font-weight: 100;
            padding-left: 1vw;
          }
          .content--year {
            text-transform: uppercase;
            padding-top: get-vw($g-6);
            margin: 0 3vw;
            color: $gray-500;
          }
        }
        .content--description {
          text-align: center;
          z-index: 100;
          will-change: transform;
          opacity: 0;
          width: 100%;
        }
      }
    }
  }

  .detail__section-image-background {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 0;
    background: whitesmoke;
  }

  .detail__section-content-background {
    position: absolute;
    background: white;
    width: 100%;
    height: 0;
    right: 0;
    top: 0;
  }
</style>
