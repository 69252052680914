<template>
  <div class="visual-box-caption flex flex-col justify-around g-4" :class="[typeClass]">
    <div class="header flex align-center g-4">
      <Transition name="slide-right-text" appear>
        <span key="caption" class="caption flex align-center">{{ caption }}</span>
      </Transition>

      <Transition name="slide-right-text" appear>
        <ChipTag v-if="tag" class="header-tag" size="large" color="white" :style="{ 'transition-delay': '600ms' }">
          {{ tag }}
        </ChipTag>
      </Transition>
    </div>
    <div v-if="title || subtitle" class="headlines flex flex-col g-2">
      <Transition name="slide-right-text" appear>
        <div class="title text-truncate-2" :style="{ 'transition-delay': '200ms' }">{{ title }}</div>
      </Transition>

      <Transition name="slide-right-text" appear>
        <div v-if="subtitle" class="subtitle text-truncate-2" :style="{ 'transition-delay': '400ms' }">
          {{ subtitle }}
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
  import ChipTag from '@/components/partials/chip/ChipTag.vue'
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      type?: 'solid' | 'transparent'
      caption: string
      title: string
      subtitle?: string
      tag?: string
    }>(),
    {
      type: 'transparent',
      subtitle: '',
      tag: '',
    },
  )

  const typeClass = computed(() => `type-${props.type}`)
</script>

<style lang="scss" scoped>
  .visual-box-caption {
    width: 100%;
    padding: get-vw($g-9) get-vw($g-7);
    border-radius: get-vw($r-4);
    color: $white;
    overflow: hidden;

    .header {
      padding: get-vw($g-0);
      min-height: get-vw(34);

      .caption {
        flex: 1 0 0;
        color: rgba-percent($white, $opacity-500);
        height: get-vw(36px);
        @include text-body($text-body-5, $font-weight-600);
      }

      .header-tag {
        border-color: rgba-percent($white, $opacity-200) !important;
        max-width: 40%;
      }
    }

    .headlines {
      padding: get-vw($g-0);
      min-height: get-vw(50);

      .title {
        @include text-body($heading-3, $font-weight-600);
      }
      .subtitle {
        color: rgba-percent($white, $opacity-500);
        @include text-body($heading-3, $font-weight-600);
      }
    }

    // types
    &.type-solid {
      background: $gray-800;
    }
    &.type-transparent {
      background: rgba-percent($black, $opacity-200);
      backdrop-filter: blur(48px);
    }
  }
</style>
