<template>
  <div v-if="itemsTotal" ref="moodGridRef" class="grid-mood">
    <div v-if="isReady" id="grid" class="grid-mood-content" :style="getGridStyle">
      <template v-for="index in itemsComputed" :key="generateKey(index)">
        <Transition name="overview-slide-up" mode="out-in" appear>
          <div
            :key="generateKey('item', index)"
            class="grid-item d-flex flex-column"
            :style="getStyle(index)"
            :class="['I-' + index]"
          >
            <slot :index="index - 1"></slot>
            <div v-if="index === itemsComputed && remainingItems > 0" class="remaining-items">
              +{{ remainingItems }}
            </div>
          </div>
        </Transition>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { randomID } from '@/utils/helpers'
  import { ref, computed } from 'vue'
  import { getGrid } from '@/components/grid/helpers/grid'

  const props = withDefaults(
    defineProps<{
      gridLayout: 'landscape' | 'portrait' | 'cross-selling'
      itemsTotal?: number
      isReady: boolean
    }>(),
    {
      itemsTotal: 0,
      isReady: false,
    },
  )

  const moodGridRef = ref<HTMLElement | null>(null)

  const maxItems = 50
  const itemsComputed = computed(() => Math.min(props.itemsTotal, maxItems))
  const remainingItems = computed(() => Math.max(props.itemsTotal - maxItems, 0))
  const getGridStyle = computed(() => getGrid({ layout: props.gridLayout, length: itemsComputed.value }))

  const transitionDelayTime = computed(() => {
    if (props.itemsTotal > 30) return 10
    else if (props.itemsTotal > 20) return 20
    else if (props.itemsTotal > 10) return 30
    else if (props.itemsTotal > 5) return 40
    else return 50
  })
  const getStyle = (index: number) => ({
    gridArea: `i-${index}`,
    transitionDelay: `${index * transitionDelayTime.value}ms`,
  })

  function generateKey(prefix: string, index?: number) {
    return `${prefix}-${index !== undefined ? index + '-' : ''}${randomID}`
  }
</script>

<style lang="scss" scoped>
  .grid-mood {
    width: 100%;
    height: 100%;
  }

  :deep(.grid-mood-content) {
    display: grid;
    width: 100%;
    height: 100%;
    grid-gap: get-vw($g-3);
    padding: get-vw($g-3);
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

    .grid-item {
      background-color: $gray-100;
      position: relative;
      border-radius: get-vw($r-3);

      .remaining-items {
        position: absolute;
        bottom: 0;
        right: 0;
        color: $white;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba-percent($black, $opacity-200);
        font-size: get-vw($heading-3);
        border-radius: get-vw($r-3);
      }
    }
  }

  .overview-slide-up {
    &-enter-active,
    &-leave-active {
      transition:
        opacity 3s cubic-bezier(0.25, 0.8, 0.25, 1),
        transform 3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateX(5vw);
    }
    &-enter-to,
    &-leave-from {
      opacity: 1;
      transform: translateX(0);
    }
  }
</style>
