import Konva from 'konva'
import buildAssetPath from '@/utils/buildAssetsPath'
import { iFreeDragState, iItemGroup } from '../interface/FreeDragState.i'
import { useKonvaUtils } from './useKonvaUtils'

export function useKonvaItemGroup(state: iFreeDragState) {
  const { getStageOptions, getItemType, getImageScaleUpdate } = useKonvaUtils(state)

  function updateItemGroup(newItemGroup: string | iItemGroup, isUpdateWithoutScaleNew = false) {
    const itemGroup: iItemGroup = typeof newItemGroup === 'string' ? JSON.parse(newItemGroup) : newItemGroup
    const isValidNode = ['Image', 'Text', 'Group'].includes(itemGroup?.className)
    if (state.isAborted || !isValidNode || !state.stage) return

    updateItemWithAnimation(itemGroup, isUpdateWithoutScaleNew)
    state.stage?.batchDraw()
  }

  const getAnimatedAttrs = (item: iItemGroup, isUpdateWithoutScale: boolean) => {
    const { type } = item.attrs
    const { scaleX, scaleY } = getStageOptions()
    const { isImageMedia } = getItemType(type)

    const applyScale = (key: string, value: number) => value * (key === 'x' ? scaleX : scaleY)
    const handleImageMediaScaling = (key: string, value: number) => {
      return key === 'x' || key === 'y' ? applyScale(key, value) : value
    }

    const defaultAttrs = { x: 0, y: 0, scaleX: 1, scaleY: 1, rotation: 0 }

    return Object.entries(item.attrs).reduce((acc, [key, value]) => {
      if (['x', 'y', 'scaleX', 'scaleY', 'rotation'].includes(key)) {
        if (key === 'rotation') {
          acc[key] = value
        } else if (isImageMedia && !isUpdateWithoutScale) {
          acc[key] = handleImageMediaScaling(key, value)
        } else {
          acc[key] = value // applyScale(key, value)
        }
      }
      return acc
    }, defaultAttrs)
  }

  function updateItemWithAnimation(item: iItemGroup, isUpdateWithoutScaleNew = false) {
    const imageProdId = Number(item.attrs.name)
    let node = state.stage?.findOne((node) => node.getAttr('name') === item.attrs.name)

    if (!node) return

    const { type: itemType } = item.attrs
    const isTextarea = itemType === 'text'
    const isImage = ['image-product', 'image-media'].includes(itemType)
    const isImageProduct = ['image-product'].includes(itemType)
    const isUpdateWithoutScale = isUpdateWithoutScaleNew || node?.attrs.state === 'update-without-scale'
    const isUpdateRotation = Math.round(item.attrs?.rotation) !== Math.round(node.attrs?.rotation)
    const imageInfoGroup = state.stage?.findOne(
      (node) => node.attrs?.type === 'imageInfoGroup' && node.attrs?.prodId === imageProdId,
    )

    if (imageInfoGroup) {
      // update selected node image from imageInfoGroup
      node = imageInfoGroup?.findOne((node) => node.getAttr('name') === item.attrs?.name)
    } else if (isImageProduct) {
      // update image scale on dragging
      const { newScaleX, newScaleY } = getImageScaleUpdate(item, node)
      item.attrs.scaleX = isNaN(newScaleX) ? item.attrs.scaleX : newScaleX
      item.attrs.scaleY = isNaN(newScaleY) ? item.attrs.scaleY : newScaleY
    }

    const wasImagePathChanged = isImage && item.attrs.path !== node.attrs.path
    if (wasImagePathChanged) {
      node.attrs.path = item.attrs.path
      const newImageObj = new Image()
      const src = buildAssetPath({ src: item.attrs.path, thumb: state.imageSize })
      newImageObj.src = src

      newImageObj.onload = () => {
        node.image(newImageObj)
        node.getLayer()?.batchDraw()
      }

      newImageObj.onerror = (error) => {
        console.error('Fehler beim Laden des Bildes:', src, error)
      }
    }

    item.attrs = { scaleX: 1, scaleY: 1, rotation: 0, ...item.attrs } // set missing attrs

    const animateAttrs = getAnimatedAttrs(item, isUpdateWithoutScale)

    if (node && node.parent?.attrs.state === 'update-without-scale') return

    // Update Textarea styles
    if (isTextarea) {
      node.attrs.text = item.attrs?.text
      node.attrs.fontStyle = item.attrs?.fontStyle || 'normal'
      node.attrs.textDecoration = item.attrs?.textDecoration
      node.attrs.fill = item.attrs?.fill
      node.attrs.backgroundFill = item.attrs?.backgroundFill
    }

    if (isUpdateRotation) {
      // without animation
      node.position({ x: animateAttrs.x, y: animateAttrs.y })
      node.scale({ x: animateAttrs.scaleX, y: animateAttrs.scaleY })
      node.rotation(animateAttrs.rotation)
    } else {
      // animation
      const tween = new Konva.Tween({ node, duration: 0.2, onFinish: () => tween.destroy(), ...animateAttrs })
      tween.play()
    }

    node.moveToTop()
  }

  return {
    updateItemGroup,
    updateItemWithAnimation,
  }
}
