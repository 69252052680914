import { computed, ref, Ref } from 'vue'
import { ProductPositionSize } from '@/interface/messages/Product.interface'
import { GroupedItemSizesGridData } from '@/views/cart-detail/CartDetail.interface'
import { t } from '@/utils/helpers'

export function useCartDetailSizes(position) {
  const sizes = computed<ProductPositionSize[]>(() => position.value.sizes)

  const sizesData = ref<ProductPositionSize[]>([])

  function updateSizesData(
    groupedSizeGrid: Ref<GroupedItemSizesGridData[] | undefined>,
    activeSizeGridId: Ref<number>,
  ) {
    let sizeGrid
    if (activeSizeGridId.value) {
      sizeGrid = groupedSizeGrid.value?.find((sizeGrid) => sizeGrid.sizeGridId === activeSizeGridId.value)
    }

    if (!activeSizeGridId.value && groupedSizeGrid.value?.length) {
      sizeGrid = groupedSizeGrid.value[0]
    }

    const tmp: ProductPositionSize[] = []
    if (position.value.hasBlockOrder) {
      tmp.push({
        id: 0,
        name: t('position.quantity'),
        count: position.value.block_order_quantity,
        sortOrder: 0,
      })
      return tmp
    } else {
      if (sizeGrid?.items?.length) {
        for (const item of sizeGrid?.items) {
          if (sizes.value[item.key]) {
            tmp.push({ ...item, ...sizes.value[item.key] })
          }
        }
      }
      return tmp
    }
  }

  return {
    sizesData,
    sizes,
    updateSizesData,
  }
}
