<template>
  <sales-table-container ref="cartContainer">
    <template v-slot:left>
      <visual-side
        :show-content="showContentVisualSide"
        :caption="introData?.caption"
        :title="introData?.title"
        :subtitle="introData?.subtitle"
        :images="introData?.images"
      />
    </template>
    <LayoutState
      v-if="isEmpty"
      size="default"
      :bg-text="$t('no_results.empty')"
      :text="getNoResultsMessageType?.message"
      :image-src="singleIntroImage"
    />
    <div v-else class="cart-overview" :class="{ 'h-100': isLoading }">
      <Transition name="fade" mode="out-in" appear>
        <template v-if="!groupChanging">
          <TransitionGroup
            name="list-slide-right"
            tag="div"
            class="cart-overview-list d-flex flex-col g-6 pb-10"
            appear
          >
            <template v-for="(item, index) in items" :key="item.main.sku">
              <CartItem :data="item" :style="{ transitionDelay: `${index * 10}ms` }"></CartItem>
            </template>
          </TransitionGroup>
        </template>
      </Transition>
    </div>
  </sales-table-container>
</template>
<script setup lang="ts">
  import { useViewportCalculations } from '@/composables/useViewport'
  import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
  import SalesTableContainer from '@/components/container/SalesTableContainer.vue'
  import VisualSide from '@/components/layout/side/LayoutSide.vue'
  import CartItem from '@/components/content/cart/CartItem.vue'
  import LayoutState from '@/components/layout/state/LayoutState.vue'
  import { t } from '@/utils/helpers'
  import { EmitMessage } from '@/interface/WebSocket'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { useCartOverviewEmpty } from '@/composables/views/cart-overview/useCartOverviewEmpty'

  const screenID = 'CartOverview'
  const isLoading = ref(true)

  const props = defineProps({ message: Object })
  const groupId = computed(() => props.message?.content?.groupId)
  // save last groupId because of hide / show completed positions animation
  const items = ref([])
  const itemsData = computed(() => props.message?.content?.items)
  const intro = computed(() => props.message?.content?.intro)
  const initLoaded = ref(false)
  const lastGroupId = ref(undefined)
  const groupChanging = ref(false)
  const noResults = computed(() => props.message?.content?.noResult)
  const initScroll = computed(() => props.message?.content?.scroll)

  const { pxToRelative } = useViewportCalculations()
  const { isEmpty, getNoResultsMessageType } = useCartOverviewEmpty(noResults, groupId)

  const showContentVisualSide = computed(() => {
    return !!groupId.value
  })

  const cartContainer = ref(null)
  function scrollIndexOverview(index = 0) {
    if (cartContainer.value) {
      cartContainer.value?.setJumpContentScroll(index)
    }
  }

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      scrollToVirtualScrollItem: () => scrollToItem(args),
    }
    actions[method]?.()
  }

  const scrollToItem = (index: number) => {
    const itemBox = document.querySelector('.cart-item')?.getBoundingClientRect()
    if (!itemBox) return
    const cartPaddingTop = pxToRelative(20)
    const itemGap = pxToRelative(24)
    const value = (itemBox.height + itemGap) * index + cartPaddingTop
    cartContainer.value?.setTargetScroll(value)
  }

  watch(groupId, () => {
    if (groupId.value != lastGroupId.value) {
      lastGroupId.value = groupId.value
    }
    setGroupChanging()
  })

  watch(
    itemsData,
    (value) => {
      items.value = value
      initLoaded.value = true
    },
    {
      deep: true,
      immediate: true,
    },
  )

  function setGroupChanging() {
    groupChanging.value = true
    setTimeout(() => (groupChanging.value = false), 100)
  }

  const introData = computed(() => {
    return {
      images: intro?.value?.images,
      title: intro?.value?.title,
      subtitle: t('common.items_n', { count: intro?.value?.subtitle }),
      caption: intro?.value?.caption,
    }
  })
  const singleIntroImage = computed(() => (introData?.value?.images?.length ? introData.value.images[0] : ''))

  onMounted(() => {
    lastGroupId.value = groupId.value
    scrollIndexOverview(initScroll.value)
    ShowroomScreen.addObserver({ id: screenID, onMessage })
  })

  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: screenID })
  })
</script>
<style scoped lang="scss">
  .cart-overview {
    padding: get-vw($g-10) get-vw($g-15);
  }
</style>
