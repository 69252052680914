<template>
  <div class="grid-container flex" :class="classes" :style="style">
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps({
    gap: {
      type: Number,
      default: 1
    },
    columns: {
      type: Number,
      default: 10
    },
    width: {
      type: Number,
    }
  })
  const classes = computed(() => {
    return {
      [`g-${props.gap}`]: true
    }
  })
  const style = computed(() => {
    return {
      '--grid-columns': props.columns,
      '--grid-gap': props.gap,
      '--grid-width': props.width ? `calc(${props.width} * 100vw / var(--page-width))` : '1fr'
    }
  })
</script>

<style scoped lang="scss">
  .grid-container {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), minmax(0, var(--grid-width)));
  }
</style>
