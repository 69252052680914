<template>
  <ProductDetailContainer :blurred="showTransferSidebar" full-view>
    <template v-slot:image>
      <div class="p-16 d-grid h-100 relative">
        <ProductDetailMainImage :src="itemData.image"></ProductDetailMainImage>
        <ContentHeading
          icon="icon-interface-sizegrid"
          :caption="$t('common.group')"
          :headline="groupName"
          class="align-self-start"
        ></ContentHeading>

        <Transition name="slide-down" mode="out-in">
          <ProductHeader
            :key="positionId"
            :title="itemData.name"
            :rrp="itemData.rrp"
            :whs="itemData.whs"
            :color="itemData.color"
            :subtitle="itemData.sku"
            :category="itemData.category"
            :tags="itemData.tags"
            class="cart-product-header align-self-end justify-self-start"
          ></ProductHeader>
        </Transition>
      </div>
    </template>
    <div class="content-inner p-6 w-100 h-100">
      <div class="rounded-6 bg-white overflow-hidden h-100 d-flex flex-col h-100">
        <div class="content-stats pt-14 pr-20 pb-3 pl-16 border-bottom-sm border-gray-100">
          <template v-for="(stat, index) in statsData">
            <Transition name="list-slide-right" mode="out-in" appear>
              <ContentStats
                class="p-4"
                :icon="stat.icon"
                :caption="stat.label"
                :headline="stat.value"
                :style="{ transitionDelay: `${index * 50 + 200}ms` }"
              >
                <template v-if="stat.animateUp" v-slot:headline>
                  <CounterUp
                    class="text-truncate-1"
                    :end="stat.value"
                    :duration="500"
                    :currency="stat.animateUp === 'currency'"
                  ></CounterUp>
                </template>
                <template v-slot:action>
                  <template v-if="stat.key === 'delivery_date' && secondDateField && secondDateField.text">
                    <ChipTag variant="tonal" color="warning" :icon="secondDateField.icon">{{
                      formatDate({ str: secondDateField.text })
                    }}</ChipTag>
                  </template>
                </template>
              </ContentStats>
            </Transition>
          </template>
        </div>
        <div class="pt-8 pr-20 pb-20 pl-20 overflow-hidden">
          <div v-if="isSizeTabActive" class="d-flex g-1 mb-6 overflow-hidden sizegrid-scrollinto">
            <Tab
              v-for="sizeGrid in sizeGridNames"
              :key="sizeGrid.id"
              size="large"
              :is-active="sizeGrid.id === selectedSizeGridId"
              icon="icon-interface-sizegrid"
            >
              {{ sizeGrid.name }}
            </Tab>
          </div>
          <div class="overflow-scroll h-100" :style="{ '--sizes-grid-columns': sizeItemOption.grid }">
            <template v-if="!positionChanging">
              <TransitionGroup
                tag="div"
                class="content-sizes-grid"
                :class="[`g-${sizeItemOption.gap}`]"
                :name="transitionName"
                appear
              >
                <template v-for="(size, index) in sizesData" :key="size.id">
                  <SizeItem
                    :title="size.name"
                    :size="sizeItemOption.size"
                    show-counter
                    :value="+size.count"
                    :style="{
                      transitionDelay: `${index * 10}ms`,
                      ...(isSizeTabActive ? { 'aspect-ratio': '180 / 186' } : {}),
                    }"
                  >
                  </SizeItem>
                </template>
              </TransitionGroup>
            </template>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:overlay>
      <Transition name="slide-in-from-left" mode="out-in">
        <SidebarDrawer v-if="showTransferSidebar">
          <template v-slot:header>
            <SidebarDrawerHeader icon="icon-interface-transfer">{{ transferHeadline }}</SidebarDrawerHeader>
          </template>
          <div class="product-transfer-scrollable scroll-smooth">
            <LayoutState
              v-if="transferItems.length === 0"
              size="small"
              image-src=""
              :text="$t('no_results.matching_items')"
              :bg-text="$t('no_results.empty')"
            >
            </LayoutState>
            <template v-else>
              <TransitionGroup name="switch-component" mode="out-in">
                <ProductListItem
                  v-for="item in transferItems"
                  :key="item.id"
                  :content="item"
                  :active="checkProductSelectedById(item.id)"
                >
                  <template v-slot:action>
                    <div
                      v-if="item.positionAlreadySet"
                      class="color-warning d-flex align-center g-2 text-body-3 justify-end"
                    >
                      <IconTag icon="icon-interface-information"></IconTag>
                      <span v-if="transferMode === 'positions'" class="font-weight-600">
                        {{ $t('position.transfer_already_set') }}
                      </span>
                      <span v-else class="font-weight-600">{{ item.alreadySetText }}</span>
                    </div>
                  </template>
                </ProductListItem>
              </TransitionGroup>
            </template>
          </div>
        </SidebarDrawer>
      </Transition>
    </template>
  </ProductDetailContainer>
</template>

<script setup lang="ts">
  import ProductDetailContainer from '@/components/container/ProductDetailContainer.vue'
  import ContentStats from '@/components/content/ContentStats.vue'
  import { CartDetailMessage } from '@/views/cart-detail/CartDetail.interface'
  import { computed, nextTick, onMounted, onUnmounted, Ref, ref, watch, watchEffect } from 'vue'
  import SizeItem from '@/components/content/SizeItem.vue'
  import CounterUp from '@/components/partials/counter/CounterUp.vue'
  import ProductHeader from '@/components/content/product/ProductHeader.vue'
  import ChipTag from '@/components/partials/chip/ChipTag.vue'
  import { formatDate } from '@/utils/dateUtils'
  import SidebarDrawer from '@/components/navigation/sidebar/SidebarDrawer.vue'
  import SidebarDrawerHeader from '@/components/navigation/sidebar/components/SidebarDrawerHeader.vue'
  import ProductListItem from '@/components/content/product/ProductListItem.vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { useTransferProducts } from '@/composables/socket/useTransferProducts'
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { usePositionDates } from '@/composables/position/usePositionDates'
  import { EmitMessage } from '@/interface/WebSocket'
  import { useCartDetail } from '@/composables/views/cart-detail/useCartDetail'
  import { useCartDetailSizes } from '@/composables/views/cart-detail/useCartDetailSizes'
  import { useCartDetailTransition } from '@/composables/views/cart-detail/useCartDetailTransition'
  import { useProductSizes } from '@/composables/sizes/useProductSizes'
  import Tab from '@/components/partials/tabs/Tab.vue'
  import LayoutState from '@/components/layout/state/LayoutState.vue'
  import ProductDetailMainImage from '@/components/_common/product/ProductDetailMainImage.vue'
  import { useDetailSizeGridOptions } from '@/composables/views/cart-detail/useDetailSizeGridOptions'
  import ContentHeading from '@/components/content/ContentHeading.vue'
  import { checkElementVisibilityAndScrollIntoView } from '@/utils/checkElementVisibilityAndScrollIntoView'

  const defaultTransitionName = 'list-slide-right'
  const props = defineProps<{ message: CartDetailMessage }>()

  const { position, statsData, itemData, itemSizes, activeSizeGridId, groupName } = useCartDetail(props)
  const { sizes, sizesData, updateSizesData } = useCartDetailSizes(position)
  const { secondDateField } = usePositionDates(position)
  const { positionChanging, setPositionChanging } = useCartDetailTransition()
  const { sizeItemOption } = useDetailSizeGridOptions(sizesData)
  const { createProductSizesSortedByGroup, groupedSizeGrid, sizeGridNames } = useProductSizes()

  const selectedSizeGridId = ref()

  const positionId = computed(() => position.value.id)
  const isSizeTabActive = computed(() => sizeGridNames.value.length > 1 && selectedSizeGridId.value !== 'block-order')
  const transitionName: Ref<string> = ref(defaultTransitionName)

  const trackLastSizeDataLength = ref(0)

  const {
    showTransferSidebar,
    transferItems,
    transferHeadline,
    transferMode,
    closeTransferSidebar,
    setTransferData,
    checkProductSelectedById,
    scrollTransferContainerPercent,
  } = useTransferProducts()

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      scrollPercent: () => scrollTransferContainerPercent(args),
      setTransferContainerData: () => setTransferData(args),
      closeTransferContainer: () => closeTransferSidebar(),
      setActiveSizeGridId: () => updateActiveSizeGrid(args),
    }
    actions[method]?.()
  }

  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: 'CartDetail' })
  })

  onMounted(() => {
    ShowroomScreen.addObserver({ id: 'CartDetail', onMessage })
  })

  const animationHash = computed(() => `${positionId.value}${selectedSizeGridId.value}`)

  watch(animationHash, () => {
    setPositionChanging()
    sizesData.value = []
    groupedSizeGrid.value = undefined
    generateSizeGrid()
  })

  function updateActiveSizeGrid(sizeGridId: number) {
    selectedSizeGridId.value = sizeGridId
  }

  watch(
    activeSizeGridId,
    () => {
      selectedSizeGridId.value = activeSizeGridId.value
    },
    { immediate: true },
  )

  watchEffect(() => {
    if (selectedSizeGridId.value) {
      nextTick(() => {
        const container = document.querySelector('.sizegrid-scrollinto')
        const element = document.querySelector('.tab.is-active')
        checkElementVisibilityAndScrollIntoView(container, element)
      })
    }
  })

  function generateSizeGrid() {
    // generate cached sizeGrid arr with already sorted grids and sizes if not exists
    if (!groupedSizeGrid.value) {
      createProductSizesSortedByGroup(itemSizes)
    }
    const genSizeData = updateSizesData(groupedSizeGrid, selectedSizeGridId)
    if (trackLastSizeDataLength.value && trackLastSizeDataLength.value !== genSizeData.length) {
      setPositionChanging()
    }
    trackLastSizeDataLength.value = genSizeData.length
    sizesData.value = genSizeData
  }
  watch(
    sizes,
    () => {
      generateSizeGrid()
    },
    {
      immediate: true,
      deep: true,
    },
  )
</script>

<style scoped lang="scss">
  .cart-product-header {
    width: 100%;
    max-width: get-vw(572px);
  }
  .content-stats {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .content-sizes-grid {
    display: grid;
    grid-template-columns: repeat(var(--sizes-grid-columns), minmax(0, 1fr));
  }

  .product-transfer-scrollable {
    overflow: scroll;
    height: 100%;
  }
</style>
