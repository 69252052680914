export function checkElementVisibilityAndScrollIntoView(container: HTMLElement, element: HTMLElement): void {
  if (!container || !element) return

  const containerRect = container.getBoundingClientRect()
  const elementRect = element.getBoundingClientRect()

  const isOutside =
    elementRect.left + elementRect.width >= containerRect.left + containerRect.width ||
    elementRect.left - elementRect.width < containerRect.left
  if (isOutside) {
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
  }
}
