<template>
  <SalesTableContainer ref="statisticContainer" aspect-ratio="7/16" theme="dark">
    <template v-slot:left>
      <TransitionGroup
        key="statistics"
        name="list-slide-right"
        tag="div"
        class="content-statistics pt-3 pb-3 pl-3 h-100 d-flex flex-col g-3 overflow-hidden"
        appear
      >
        <CardStats
          v-for="(item, index) of statistics"
          :key="index"
          type="solid"
          :label="item.label"
          :icon="item.icon"
          :count="item.count"
          :currency="item.currency"
          :style="{ transitionDelay: `${index * 100}ms` }"
        />
      </TransitionGroup>
    </template>

    <div class="p-3 h-100">
      <div class="bg-gray-800 rounded-4 d-flex h-100">
        <div class="chart-container flex-1 p-7">
          <div class="relative h-100">
            <div class="chart-grid absolute inset-0 overflow-hidden rounded-4">
              <div v-for="n in 91" :key="n" class="chart-grid-item bg-gray-800"></div>
            </div>
            <div class="absolute inset-0 d-flex place-center">
              <ChipTag
                v-if="hasNoQuantity"
                icon="icon-interface-information"
                size="large"
                color="gray-700"
                class="align-self-center"
                >{{ $t('no_results.enter_quantities') }}</ChipTag
              >
              <Doughnut v-else ref="chart" :data="chartData"></Doughnut>
            </div>
          </div>
        </div>
        <div ref="scrollContainer" class="data-container overflow-scroll flex-1 p-7">
          <Transition name="fade" mode="out-in">
            <TransitionGroup v-if="!isTransition" name="list-slide-right" tag="div" class="d-flex flex-col g-3" appear>
              <template v-for="statData in statsData" :key="statData.header.title">
                <StatisticHeadline
                  single
                  :title="statData.header.title"
                  :icon="statData.header.icon"
                  :text="statData.header.percentage"
                ></StatisticHeadline>
                <StatisticItem
                  v-for="(item, itemIndex) in statData.items"
                  :key="item.category"
                  :data="item"
                  :style="{ transitionDelay: `${(itemIndex + 1) * 100}ms` }"
                ></StatisticItem>
              </template>
            </TransitionGroup>
          </Transition>
        </div>
      </div>
    </div>
  </SalesTableContainer>
</template>

<script setup lang="ts">
  import { computed, watch, ref, onMounted, onUnmounted } from 'vue'
  import { StatisticOverviewMessage } from '@/views/statistic-overview/StatisticOverview.interface'
  import SalesTableContainer from '@/components/container/SalesTableContainer.vue'
  import StatisticHeadline from '@/components/content/statistic/StatisticHeader.vue'
  import StatisticItem from '@/components/content/statistic/StatisticItem.vue'
  import { chartColors } from '@/utils/chartColors'
  import Doughnut from '@/components/_common/charts/Doughnut.vue'
  import { handleScrollPercent, t } from '@/utils/helpers'
  import CardStats from '@/components/card/CardStats.vue'
  import { EmitMessage } from '@/interface/WebSocket'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import ChipTag from '@/components/partials/chip/ChipTag.vue'

  const ScreenID = 'StatisticOverview'

  const props = defineProps<{ message: StatisticOverviewMessage }>()

  const scrollContainer = ref(null)

  const level = computed(() => props.message?.content?.level)
  const isTransition = ref(false)
  const statsData = ref([])
  const chart = ref(undefined)

  watch(level, () => {
    isTransition.value = true
    statsData.value = []
    setTimeout(() => {
      generateStatsData(true)
    }, 50)
  })

  const stats = computed(() => props.message?.content?.stats)
  const totalWHS = computed(() => stats.value?.whs_total)

  const hasNoQuantity = computed(() => !stats.value?.quantity)

  const statistics = computed(() => [
    { label: t('common.item'), icon: 'icon-interface-grid-plus', count: stats.value.positions },
    { label: t('common.quantity'), icon: 'icon-interface-stacks', count: stats.value.quantity },
    { label: t('common.whs'), icon: 'icon-interface-basket', count: stats.value.whs_total, currency: true },
    { label: t('common.rrp'), icon: 'icon-interface-price', count: stats.value.rrp_total, currency: true },
  ])

  const topItems = computed(() => {
    if (props.message?.content?.top?.length) {
      return addMoreDataToItem(props.message?.content?.top, { total: totalWHS.value })
    }
    return []
  })

  const otherItems = computed(() => {
    if (props.message?.content?.others?.length) {
      return addMoreDataToItem(props.message?.content?.others, { total: totalWHS.value, useDefaultColor: true })
    }
    return []
  })

  function generateStatsData(update = false) {
    const topItemsData = topItems.value
    const otherItemsData = otherItems.value

    let topPercentage = 0

    const arrData = []
    if (topItemsData.length) {
      const totalWhsFromTopItems = topItemsData.reduce((acc, item) => acc + item.whs, 0)
      topPercentage =
        totalWHS.value > 0 ? parseFormattedNumberPercentage((totalWhsFromTopItems / totalWHS.value) * 100) : 0
      arrData.push({
        header: {
          title: t('stats.top_n', { count: topItemsData.length > 5 ? 5 : topItemsData.length }),
          icon: 'icon-interface-trophy',
          percentageUnformatted: topPercentage,
          percentage: `${topPercentage}%`,
        },
        items: topItemsData,
      })
    }

    if (otherItemsData.length) {
      const othersPercentage = topPercentage > 0 ? parseFormattedNumberPercentage(100 - topPercentage) : 0
      arrData.push({
        header: {
          title: t('stats.others'),
          icon: 'icon-interface-chart',
          percentageUnformatted: othersPercentage,
          percentage: `${othersPercentage}%`,
        },
        items: otherItemsData,
      })
    }
    statsData.value = arrData
    isTransition.value = false
    if (update) {
      setTimeout(() => {
        chart.value.updateChart()
      }, 200)
    }
  }

  const chartData = computed(() => {
    const topData = statsData.value?.[0]
    const percentages = topData?.items?.map((item) => item.percentage)
    if (statsData.value?.[1]) {
      percentages.push(statsData.value[1]?.header.percentageUnformatted)
    }
    return {
      datasets: [
        {
          data: percentages,
          backgroundColor: chartColors,
        },
      ],
    }
  })

  interface MoreDataOptions {
    total: number
    useDefaultColor: boolean
  }
  function addMoreDataToItem(items, options: MoreDataOptions) {
    return items.map((item, index) => ({
      ...item,
      color: chartColors[index],
      defaultColor: options.useDefaultColor,
      percentage: options.total > 0 ? parseFormattedNumberPercentage((item.whs / options.total) * 100) : 0,
    }))
  }

  function parseFormattedNumberPercentage(number) {
    return (Math.round(number * 100) / 100).toFixed(1)
  }

  function scrollPercent(percentage) {
    console.log('args', percentage)
    if (scrollContainer.value) {
      handleScrollPercent(scrollContainer.value, percentage)
    }
  }

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      scrollPercent: () => scrollPercent(args),
    }
    actions[method]?.()
  }

  onUnmounted(() => {
    ShowroomScreen.removeObserver({ id: ScreenID })
  })

  onMounted(() => {
    ShowroomScreen.addObserver({
      id: ScreenID,
      onMessage,
    })

    generateStatsData()
  })
</script>

<style scoped lang="scss">
  .chart-container {
    max-width: 40%;
  }
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(13, 1fr);
    gap: get-vw(1);
    background-color: rgba-percent($white, $opacity-100);
    border: 1px solid transparent;
  }
</style>
