import { ref } from 'vue'

export function useMultipleSelection() {
  const selections = ref<(number | string)[]>([])

  const setMultipleSelection = (args: (number | string)[]) => {
    selections.value = args
  }

  const checkItemIsSelected = (itemId: number | string) => selections.value.includes(itemId)

  return {
    selections,
    setMultipleSelection,
    checkItemIsSelected,
  }
}
