<template>
  <ProductDetailContainer :show-video="showVideoOverlay" :content-class="`view-${viewType}`" :full-view="isFullView">
    <template v-slot:video>
      <video ref="playerRef" :key="medias.video" width="1080" height="1920" muted autoplay loop>
        <source :key="medias.video" :src="medias.video" type="video/webm" />
      </video>
    </template>
    <template v-slot:image>
      <div class="p-16 d-grid h-100 relative">
        <ProductDetailMainImage :src="mainImageData" :transition="imageTransition" />
      </div>
    </template>

    <template v-slot:medias>
      <MediaGrid
        :class="{
          'single-top-image': medias.top && !medias.bottom,
          'single-bottom-image': !medias.top && medias.bottom,
        }"
      >
        <template v-if="medias.top" v-slot:top-image>
          <ImageLoaderBg :key="medias.top" :src="medias.top" />
        </template>
        <template v-if="medias.bottom" v-slot:bottom-image>
          <ImageLoaderBg :key="medias.bottom" :src="medias.bottom" />
        </template>
        <template v-if="medias.right || medias.video" v-slot:video>
          <video v-if="medias.video" :key="medias.video" width="1080" height="1920" muted autoplay loop>
            <source :key="medias.video" :src="medias.video" type="video/webm" />
          </video>
          <ImageLoaderBg v-else-if="medias.right" :key="medias.right" :src="medias.right" />
        </template>
      </MediaGrid>
    </template>

    <ProductHeaderCombo
      :category="item.category"
      :title="item.name"
      :subtitle="item.customSku"
      :whs="item.whs"
      :rrp="item.rrp"
      :color="item.color"
      :tags="item.tags"
      :counter="getCounter(item.id)"
      :scroll-percent="scrollPercent"
      :is-empty="isEmpty"
    >
      <LayoutState
        v-if="isEmpty"
        size="small"
        :bg-text="$t('no_results.empty')"
        :text="$t('no_results.data')"
        :is-loading="false"
      />
      <TransitionGroup name="variants-fade-in" appear>
        <div v-if="viewType === 'product'" class="color-variants pt-7 pb-14 pl-10 pr-10">
          <ProductColor
            v-for="sibling in siblings"
            :key="sibling.id"
            :src="sibling.image"
            :active="sibling.id === activeId"
            variant="info"
            :color="{ id: sibling.id, title: sibling.name, hex: sibling.hex, color_number: sibling.colorNumber }"
            :rounded="3"
            :counter="getCounter(sibling.id)"
            :tags="sibling.tags"
            show-counter
          />
        </div>
        <ProductDetailInformation v-if="viewType === 'information'" :items="viewData" />
        <ProductDetailFeature v-if="viewType === 'feature'" :items="viewData" />
        <ProductDetailSize v-if="viewType === 'size'" :items="viewData" />
        <ProductDetailRelated v-if="viewType === 'related' && !isProductChanging" :key="activeId" :data="viewData" />
      </TransitionGroup>
    </ProductHeaderCombo>
  </ProductDetailContainer>
</template>

<script setup lang="ts">
  import ProductHeaderCombo from '@/components/_common/product/ProductHeaderCombo.vue'
  import ProductDetailContainer from '@/components/container/ProductDetailContainer.vue'
  import { ProductDetailMessage } from '@/views/product-detail/ProductDetail.interface'
  import { useProductDetailItem } from '@/composables/product/useProductDetailItem'
  import ProductDetailMainImage from '@/components/_common/product/ProductDetailMainImage.vue'
  import { nextTick, onUnmounted, onMounted, computed, watchEffect, ref, watch } from 'vue'
  import ShowroomScreen from '@/service/ShowroomScreen'
  import { EmitMessage } from '@/interface/WebSocket'
  import { useProductDetail } from '@/composables/product/useProductDetail'
  import MediaGrid from '@/components/_common/MediaGrid.vue'
  import ImageLoaderBg from '@/components/media/ImageLoaderBg.vue'
  import ProductColor from '@/components/content/product/ProductColor.vue'
  import ProductDetailInformation from '@/components/_common/product/ProductDetailInformation.vue'
  import ProductDetailFeature from '@/components/_common/product/ProductDetailFeature.vue'
  import ProductDetailSize from '@/components/_common/product/ProductDetailSize.vue'
  import ProductDetailRelated from '@/components/_common/product/ProductDetailRelated.vue'
  import { useProductCounter } from '@/composables/product/useProductCounter'
  import LayoutState from '@/components/layout/state/LayoutState.vue'

  const screenID = 'ProductDetail'
  const props = defineProps<{ message: ProductDetailMessage }>()

  const playerRef = ref()
  const showVideoOverlay = ref(false)
  const isProductChanging = ref(false)

  const { getCounter } = useProductCounter()
  const { item, mainImageData, imageTransition, medias, siblings, activeId } = useProductDetailItem(props)
  const { viewType, viewData, updateView, isEmpty, scrollPercent } = useProductDetail(props)

  const isFullView = computed(() => ['information', 'feature', 'size', 'related'].includes(viewType.value))

  watchEffect(() => {
    if (viewType.value === 'product' && activeId.value) {
      // TODO: try checkElementVisibilityAndScrollIntoView()
      nextTick(() => checkElementVisibility())
    }
  })
  watch(activeId, (value) => {
    if (value) {
      isProductChanging.value = true
      setTimeout(() => (isProductChanging.value = false), 100)
    }
  })
  watchEffect(() => {
    if (activeId.value) {
      resetImagePositionAndZoom()
    }
  })

  onMounted(() => ShowroomScreen.addObserver({ id: screenID, onMessage }))
  onUnmounted(() => ShowroomScreen.removeObserver({ id: screenID }))

  function resetImagePositionAndZoom() {
    document.documentElement.style.setProperty('--zoom', '1')
    document.documentElement.style.setProperty('--moveX', '0px')
    document.documentElement.style.setProperty('--moveY', '0px')
  }

  function onMessage(message: EmitMessage) {
    if (message.event !== 'emit' || !message.data?.emit) return
    const { method, args } = message.data.emit
    const actions: Record<string, () => void> = {
      updateView: () => updateView(args),
      updateMainImg: () => updateMainImg(args),
      showVideoOverlay: () => updateVideoOverlay(args),
      scrollPercent: () => (scrollPercent.value = args),
    }
    actions[method]?.()
  }

  async function updateMainImg(args) {
    imageTransition.value = 'main-image-scroll'
    mainImageData.value = args.image?.file
  }

  function updateVideoOverlay(args: {
    showVideo: boolean
    videoState?: { isPaused: boolean; currentTime: number; volume: number }
  }) {
    const { showVideo, videoState } = args
    showVideoOverlay.value = showVideo

    // set video play / pause
    if (videoState?.isPaused) {
      playerRef.value?.pause()
    } else {
      playerRef.value?.play()
      playerRef.value.currentTime = videoState?.currentTime
    }
  }

  function checkElementVisibility() {
    const container = document.querySelector('.default-slot')
    const activeElement = document.querySelector('.content-color.is-active')
    if (!container || !activeElement) return

    const containerRect = container.getBoundingClientRect()
    const elementRect = activeElement.getBoundingClientRect()

    const isOutside =
      elementRect.left + elementRect.width >= containerRect.left + containerRect.width ||
      elementRect.left - elementRect.width < containerRect.left
    if (isOutside) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }
</script>

<style scoped lang="scss">
  .color-variants {
    display: flex;
    flex-wrap: nowrap;
    align-self: flex-start;
    gap: get-vw($g-2);

    :deep(.content-color) {
      width: get-vw(76);
      flex-shrink: 0;
    }
  }

  :deep(.product-header-combo) {
    margin-top: auto;
    width: 100%;
    flex: 1 1 auto;
    & > .content {
      height: 100%;
    }
  }
</style>

<style lang="scss">
  .product-detail-container {
    .product-header-combo .content-box {
      transition: padding 0.6s ease-in-out;
    }
    .full-view {
      .media-grid {
        visibility: hidden;
      }

      .product-header-combo .content-box {
        padding: get-vw($g-20) get-vw($g-20) get-vw($g-14) get-vw($g-20);
      }
    }

    .view-product {
      .default-slot {
        display: flex;
      }
    }
  }
</style>
