<template>
  <div class="cart-item rounded-6 bg-white">
    <div class="cart-header align-center">
      <div class="image_area">
        <ProductTile
          class="cart-product-tile"
          type="absolute"
          size="x-small"
          :show-info="false"
          :image-src="mainProductData.image"
          :tags="mainProductData.tags"
        ></ProductTile>
      </div>
      <div class="title_area">
        <CartText :subtitle="mainProductData.sku" :title="mainProductData.name" class="pl-0"></CartText>
      </div>
      <div class="quantity_area">
        <CartText :subtitle="$t('common.quantity')" :title="getQuantity" :disabled="isDisabled"> </CartText>
      </div>
      <div class="whs_area">
        <CartText
          :subtitle="$t('common.whs')"
          :title="getWHS"
          :disabled="isDisabled"
          class="border-left-sm border-gray-100"
        >
          <template v-slot:action>
            <IconTag icon="icon-interface-basket"></IconTag>
            <span class="ml-2 text-body-4 text-truncate-1 font-weight-600 color-gray-600">{{ getSingleWHS }}</span>
          </template>
        </CartText>
      </div>
      <div class="rrp_area">
        <CartText
          :subtitle="$t('common.rrp')"
          :title="getRRP"
          :disabled="isDisabled"
          class="border-left-sm border-gray-100"
        >
          <template v-slot:action>
            <IconTag icon="icon-interface-price"></IconTag>
            <span class="ml-2 text-body-4 text-truncate-1 font-weight-600 color-gray-600">{{ getSingleRRP }}</span>
          </template>
        </CartText>
      </div>
    </div>
    <div class="cart-footer bg-gray-100">
      <div class="empty_area"></div>
      <div class="color_area">
        <ChipColor :hex="mainProductData?.color?.hex" :code="mainProductData?.color?.color_number">
          {{ mainProductData?.color?.title }}
        </ChipColor>
      </div>
      <div class="sizes_area">
        <CartTextIcon :title="getSizesCountLabel">
          <template v-slot:icon>
            <IconTag :icon="getIconSizeCount"></IconTag>
          </template>
        </CartTextIcon>
      </div>
      <div class="date_area">
        <CartTextIcon :title="date">
          <template v-slot:icon>
            <IconTag icon="icon-interface-date"></IconTag>
          </template>
        </CartTextIcon>
      </div>
      <div class="exception_area">
        <CartTextIcon
          :title="secondDateField && secondDateField.text ? formatDate({ str: secondDateField.text }) : '-'"
        >
          <template v-slot:icon>
            <IconTag :icon="secondDateField ? secondDateField.icon : 'icon-interface-exception-date'"></IconTag>
          </template>
        </CartTextIcon>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import CartText from '@/components/content/cart/CartText.vue'
  import CartTextIcon from '@/components/content/cart/CartTextIcon.vue'
  import IconTag from '@/components/partials/icon/IconTag.vue'
  import { CartOverviewItem } from '@/views/cart-overview/CartOverview.interface'
  import { computed, ref, toRef } from 'vue'
  import { priceFormatter, t } from '@/utils/helpers'
  import ChipColor from '@/components/partials/chip/ChipColor.vue'
  import ProductTile from '@/components/content/product/ProductTile.vue'
  import { usePositionDates } from '@/composables/position/usePositionDates'
  import { formatDate } from '@/utils/dateUtils'

  interface CartItemProps {
    data: CartOverviewItem
    disabled?: boolean
  }

  const props = defineProps<CartItemProps>()

  const mainProductData = toRef(props.data, 'main')
  const positionData = computed(() => props.data.position)

  const date = computed(() => {
    const selectedData = mainProductData.value.dates.find((date) => date.value === positionData.value.deliveryDate)
    if (selectedData) {
      return selectedData.text
    }
    return '-'
  })

  const { secondDateField } = usePositionDates(positionData)

  const isBlockOrder = computed(() => positionData.value.hasBlockOrder)
  const isOneSize = computed(() => positionData.value?.isOneSize)

  const sizes = ref(positionData.value.sizes)
  const positionStats = computed(() => {
    if (isBlockOrder.value) {
      return {
        count: 1,
        whs:
          parseInt(positionData.value.block_order_quantity, 10) *
          (positionData.value.whs > 0 ? positionData.value.whs : positionData.value.parent_whs),
        rrp:
          parseInt(positionData.value.block_order_quantity, 10) *
          (positionData.value.rrp > 0 ? positionData.value.rrp : positionData.value.parent_rrp),
      }
    }
    return sizes.value.reduce(
      (acc, size) => {
        const count = parseInt(size.count, 10)
        if (count > 0) {
          acc.count += count
        }
        acc.whs += size.whs
        acc.rrp += size.rrp
        return acc
      },
      { count: 0, whs: 0, rrp: 0 },
    )
  })

  const getSingleWHS = computed(() => priceFormatter(mainProductData.value.whs))
  const getSingleRRP = computed(() => priceFormatter(mainProductData.value.rrp))

  // const getWHS = computed(() => {
  //   if (isBlockOrder.value) {
  //     return priceFormatter(parseInt(positionData.value.block_order_quantity, 10) * mainProductData.value.whs)
  //   } else {
  //     return priceFormatter(positionStats.value.whs)
  //   }
  // })
  // const getRRP = computed(() => {
  //   if (isBlockOrder.value) {
  //     return priceFormatter(parseInt(positionData.value.block_order_quantity, 10) * mainProductData.value.rrp)
  //   } else {
  //     return priceFormatter(positionStats.value.rrp)
  //   }
  // })

  const getWHS = computed(() => priceFormatter(positionStats.value.whs))
  const getRRP = computed(() => priceFormatter(positionStats.value.rrp))

  const getQuantity = computed(() => {
    if (isBlockOrder.value) {
      return positionData.value?.block_order_quantity || 0
    }
    return positionStats.value.count + ''
  })
  const getSizesCountLabel = computed(() => {
    if (isBlockOrder.value) {
      return t('position.block_position')
    }
    if (isOneSize.value) {
      return t('position.one_size')
    }
    return t('common.sizes_n', { count: sizes.value.filter((size) => size.count > 0).length })
  })

  const isDisabled = computed(() => props.disabled || (positionStats.value.count <= 0 && !isBlockOrder.value))

  const getIconSizeCount = computed(() => {
    if (isBlockOrder.value) {
      return 'icon-interface-block-position'
    }
    if (isOneSize.value) {
      return 'icon-interface-one-size'
    }
    return 'icon-interface-sizegrid'
  })
</script>

<style scoped lang="scss">
  $grid-template-columns: minmax(0, get-vw(128px)) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  .cart-item {
    border: solid get-vw($border-xl) $gray-100;
    overflow: hidden;
  }
  .cart-header {
    display: grid;
    grid-template-columns: $grid-template-columns;
    grid-template-areas: 'image title quantity whs rrp';
    padding: get-vw($g-4);
  }
  .cart-footer {
    display: grid;
    grid-template-columns: $grid-template-columns;
    grid-template-areas: 'empty color sizes date exception';
    padding: get-vw($g-4);
  }
  // header areas
  .image_area {
    grid-area: image;
  }
  .title_area {
    grid-area: title;
  }
  .quantity_area {
    grid-area: quantity;
  }
  .whs_area {
    grid-area: whs;
  }
  .rrp_area {
    grid-area: rrp;
  }

  // footer areas
  .empty_area {
    grid-area: empty;
    margin-right: get-vw($g-7);
  }
  .color_area {
    grid-area: color;
  }
  .sizes_area {
    grid-area: sizes;
  }
  .date_area {
    grid-area: date;
  }
  .exception_area {
    grid-area: exception;
  }

  .image-placeholder {
    width: get-vw(100px);
    height: get-vw(100px);
    aspect-ratio: 1/1;
  }
  .cart-product-tile {
    aspect-ratio: 1/1;
    max-width: get-vw(100px);
    height: auto !important;
  }
</style>
