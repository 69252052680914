<template>
  <div class="product-list-item flex" :class="[typeClass, { disabled }]">
    <div class="image_area relative">
      <Transition name="fade-state" mode="out-in" appear>
        <div v-if="props.active" class="active-state z-5">
          <ChipState size="small" type="icon"></ChipState>
        </div>
      </Transition>
      <ProductTile v-if="content?.image" :image-src="content.image" type="absolute"></ProductTile>
    </div>
    <div class="content_area d-grid align-self-center g-3">
      <div class="top-content flex flex-col g-1">
        <div class="headline flex g-1 text-truncate-1">
          <span class="title text-truncate-1">{{ content?.name || '' }}</span>
          <PriceHeader class="headline-price-header text-end"></PriceHeader>
        </div>
        <div class="price-container flex g-1">
          <span class="subtitle text-truncate-1">{{ content?.sku || '' }}</span>
          <PriceHelper class="text-truncate-1" :rrp="content?.rrp || 0" :whs="content?.whs || 0"></PriceHelper>
        </div>
      </div>
      <div class="bottom-content">
        <ChipColor
          :size="type === 'item' ? 'default' : 'small'"
          :hex="content?.color?.hex || ''"
          :code="content?.color?.color_number"
        >
          {{ content?.color?.title }}
        </ChipColor>
        <slot name="action"></slot>
      </div>
      <MissingSizesInfo v-if="content?.missingSizes?.length" :data="content.missingSizes"></MissingSizesInfo>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ProductListItem } from '@/interface/messages/ProductTransfer.interface'
  import PriceHelper from '@/components/_common/price-helper/PriceHelper.vue'
  import PriceHeader from '@/components/_common/price-helper/PriceHeader.vue'
  import ProductTile from '@/components/content/product/ProductTile.vue'
  import ChipColor from '@/components/partials/chip/ChipColor.vue'
  import ChipState from '@/components/partials/chip/ChipState.vue'
  import MissingSizesInfo from '@/components/_common/sizes/MissingSizesInfo.vue'
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      type?: 'item' | 'pin'
      content: ProductListItem
      active?: boolean
      disabled?: boolean
    }>(),
    {
      type: 'item',
    },
  )

  const typeClass = computed(() => `type-${props.type}`)
</script>

<style scoped lang="scss">
  .product-list-item {
    width: 100%;
    position: relative;

    &.disabled {
      opacity: 0.4;
    }

    &.type-item {
      padding: get-vw($g-6) get-vw($g-10);
      gap: get-vw($g-5);
      border-color: $gray-100;
      border-width: get-vw(1);
      border-bottom-style: solid;

      .image_area {
        max-width: get-vw(100);
      }

      .content_area {
        max-width: get-vw(368);
      }

      .title {
        @include text-body($text-body-5, $font-weight-600);
      }
      .subtitle,
      .price-container {
        @include text-body($text-body-4, $font-weight-600);
      }
    }
    &.type-pin {
      background: $white;
      padding: get-vw($g-3) get-vw($g-6) get-vw($g-3) get-vw($g-3);
      gap: get-vw($g-6);
      border-radius: get-vw($r-4);

      .image_area {
        max-width: get-vw(96);
      }
      .content_area {
        max-width: get-vw(424);
      }
      .title {
        @include text-body($text-body-4, $font-weight-600);
      }
      .subtitle,
      .price-container {
        @include text-body($text-body-3, $font-weight-600);
      }
    }
  }
  .active-state {
    border: get-vw($border-xl) solid $white;
    border-radius: 100vmax;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(get-vw(-10), get-vw(-10));
  }
  .image_area {
    grid-area: image;
    :deep(.product-tile) {
      aspect-ratio: 1/1;
      max-width: 100%;
      height: auto !important;
    }
  }
  .headline {
    justify-content: space-between;
    align-items: flex-end;
  }
  .headline-price-header {
    min-width: get-vw(45);
  }
  .price-container {
    justify-content: space-between;
    align-items: flex-end;
    :deep(.price) {
      max-width: 100%;
    }
  }
  .content_area {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .bottom-content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: get-vw($g-1);
  }

  .fade-state {
    &-enter-active,
    &-leave-active {
      transition: all 0.3s ease-in-out;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-to,
    &-leave-from {
      opacity: 1 !important;
    }
  }
</style>
