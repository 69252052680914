// Imports
import Item from './Item.js'
import eventHub from '@/utils/EventHub'

// Create Slideshow
export default class Items {
  constructor({ covers, environment, items, scene, infoBoxes, width, setActiveProduct }) {
    this.initEventHub()

    this.covers = covers
    this.environment = environment
    this.items = items
    this.scene = scene
    this.index = 0
    this.infoBoxes = infoBoxes
    this.width = width
    this.setActiveProduct = setActiveProduct

    this.modifier = 0.29

    // Default Values
    this.position = { current: 0, previous: 0 }
    this.mouse = { x: 0, y: 0 }
    this.prevMouse = { x: 0, y: 0 }

    this.scroll = {
      values: { current: 0, target: 0 },
      x: { end: 0, start: 0 },
    }

    this.createItem()
  }

  // *************** Create Item *************** //

  createItem() {
    this.items = this.covers.map((cover, index) => {
      const item = new Item({
        cover,
        environment: this.environment,
        item: this.items[index],
        index,
        infoBox: this.infoBoxes[index],
        sceneWidth: this.width,
        modifier: this.modifier,
      })

      this.scene.add(item)

      return item
    })
  }

  // *************** Mouse *************** //

  getMouseSpeed() {
    this.speed = Math.sqrt((this.prevMouse.x - this.mouse.x) ** 2 + (this.prevMouse.y - this.mouse.y) ** 2)

    this.prevMouse.x = this.mouse.x
    this.prevMouse.y = this.mouse.y
  }

  // *************** Update *************** //

  update(time) {
    const width = this.environment.width * this.modifier

    this.getMouseSpeed()

    // Create and Reset Infinite Index
    this.indexInfinite = Math.round(this.scroll.values.target / width)

    let index = this.indexInfinite % this.covers.length

    if (this.indexInfinite < 0) {
      index = (this.covers.length - Math.abs(this.indexInfinite % this.covers.length)) % this.covers.length
    }

    if (index !== 0) {
      index = this.items.length - index
    }

    if (this.index !== index || (this.index === 0 && index === 0)) {
      this.index = index
      this.setActiveProduct(this.index)
    }

    // Get Current Item
    this.current = this.items[this.index]

    // Snap Items to Position
    this.position.current += (this.scroll.values.target - this.position.current) * 0.05

    this.calculate(time, this.speed, this.mouse)
    this.position.previous = this.position.current
  }

  // *************** Calculate Infinite *************** //

  calculate(time) {
    const width = this.environment.width * this.modifier

    const widthTotal = width * this.covers.length

    if (this.position.current < this.position.previous) {
      this.direction = 'up'
    } else if (this.position.current > this.position.previous) {
      this.direction = 'down'
    } else {
      this.direction = 'down'
    }

    this.items.forEach((child) => {
      // Set Position of Items
      child.isAfter = child.position.x < -width * 2.5
      child.isBefore = child.position.x > width * 2.5

      // Set Loop of Items
      if (this.direction === 'down' && child.isBefore) {
        const position = child.location - widthTotal

        child.isBefore = false
        child.isAfter = true

        child.location = position
      }

      if (this.direction === 'up' && child.isAfter) {
        const position = child.location + widthTotal

        child.isBefore = true
        child.isAfter = false

        child.location = position
      }

      child.update(this.position.current, time, this.speed, this.mouse)
    })
  }

  updateWidth(width) {
    this.width = width
    this.items.forEach((item) => item.updateSceneWidth(width))
  }

  initEventHub() {
    /**
     * @typedef {Object} Payload
     * @property {number} index
     * @property {number} target
     * @property {string} direction
     * @property {number} modifier
     * @property {number} indexInfinite
     * @property {boolean} isResize
     */
    eventHub.on('topic-slider-update', (payload) => {
      if (payload.isResize) {
        this.index = 0
        this.scroll.values.target = 0
        return
      }

      if (this.index === payload.index) return
      this.modifier = payload.modifier
      this.scroll.values.target = this.environment.width * this.modifier * payload.indexInfinite
    })
  }
}
