<template>
  <div class="size-item rounded-3" :class="genClass">
    <div v-if="!!$slots['action']" class="size-item--action">
      <slot name="action"></slot>
    </div>
    <div class="header font-weight-600 overflow-hidden" :class="{'d-flex justify-center align-end': props.showCounter }">
      <span class="text-truncate-1">{{ props.title }}</span>
    </div>
    <div v-if="props.showCounter" class="footer d-flex justify-center align-start mw-0">
      <div class="count-value rounded-7 text-truncate-1 font-weight-600">{{ props.value }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed, toRef } from 'vue'
  import { sizesEnum } from '@/views/cart-detail/cartDetail.enum'

  const props = withDefaults(defineProps<{
    title: string,
    value?: number | undefined,
    showCounter?: boolean,
    size: sizesEnum,
    disabled?: false
  }>(), {
    size: sizesEnum.DEFAULT,
    value: 0
  })

  const itemValue = toRef(props, 'value')

  const gapClass = computed(() => {
    switch (props.size) {
      case sizesEnum.LARGE:
        return 'g-6 heading-4'
      case sizesEnum.MEDIUM:
        return 'g-4 heading-3'
      case sizesEnum.DEFAULT:
        return 'g-3 heading-1'
      default:
        return 'g-2 text-body-5'
    }
  })
  const isActive = computed(() => props.showCounter && itemValue.value > 0)
  const genClass = computed(() => {
    return {
      'is-active': isActive.value,
      'is-single': !props.showCounter,
      'bg-gray-100': !isActive.value,
      'bg-gray-200': isActive.value,
      [props.size]: true,
      [gapClass.value]: true
    }
  })

</script>

<style scoped lang="scss">

  .large {
    --count-width: #{get-vw(76px)};
    --count-font-size: #{get-vw($heading-1)};
    --content-padding: #{get-vw($g-7)};
  }
  .medium {
    --count-width: #{get-vw(68px)};
    --count-font-size: #{get-vw($text-body-6)};
    --content-padding: #{get-vw($g-6)};
  }
  .small {
    --count-width: #{get-vw(52px)};
    --count-font-size: #{get-vw($text-body-4)};
    --content-padding: #{get-vw($g-4)};
  }
  .default {
    --count-width: #{get-vw(60px)};
    --count-font-size: #{get-vw($text-body-5)};
    --content-padding: #{get-vw($g-5)};
  }

  .size-item {
    aspect-ratio: 180 / 205;
    display: grid;
    padding: 0 get-vw($g-2);
    transition: background-color .3s ease-in-out;
    position: relative;
    &:not(.is-single) {
      grid-template-rows: repeat(2, minmax(0, 1fr));
    }

    &.is-single {
      place-content: center;
    }
  }
  .size-item--action {
    position: absolute;
    top: 0;
    right: 0;
  }
  .count-value {
    font-size: var(--count-font-size);
    min-width: var(--count-width);
    border: 1px solid $gray-300;
    padding: get-vw($g-1) get-vw($g-4);
    display: block;
    text-align: center;
    color: $gray-400;
    line-height: 140%;
    transition: color .3s ease-in-out, border .3s ease-in-out, background-color .3s ease-in-out;

    @at-root .is-active & {
      color: $white;
      border-color: $black;
      background-color: $black;
    }
  }
</style>
