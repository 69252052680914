import { ProductSize } from '@/interface/Product.interface'
import { ref, computed, ComputedRef } from 'vue'
import { GroupedItemSizesGridData } from '@/views/cart-detail/CartDetail.interface'


interface SizeGridNames {
  id: number
  name: string
}

export function useProductSizes() {

  const groupedSizeGrid = ref<GroupedItemSizesGridData[] | undefined>(undefined)

  const sizeGridNames: ComputedRef<SizeGridNames[]> = computed(() => groupedSizeGrid.value?.length ? generateTabNames() : [])

  function generateTabNames() {
    const names: SizeGridNames[] = []
    if (!groupedSizeGrid.value) {
      return names
    }
    groupedSizeGrid.value.forEach(sizeGrid => {
      names.push({
        id: sizeGrid?.sizeGridId || 0,
        name: sizeGrid?.sizeGridTitle || ''
      })
    })
    return names
  }

  function createProductSizesSortedByGroup(sizes: ComputedRef<{ [key: string]: ProductSize }>) {
    const arrSizes: (ProductSize & { key: string })[] = Object.entries(sizes.value).map(([key, item]) => ({
      ...item,
      key
    }));

    const groupedData = arrSizes.reduce(
      (acc: Record<number, GroupedItemSizesGridData>, item: ProductSize) => {
        if (!acc[item.sizeGridId]) {
          acc[item.sizeGridId] = {
            sizeGridId: item.sizeGridId,
            sizeGridSortOrder: item.sizeGridSortOrder,
            sizeGridTitle: item.sizeGridTitle,
            items: [],
          }
        }
        acc[item.sizeGridId].items.push(item)
        return acc
      },
      {},
    )

    const sortedGroups = Object.values(groupedData).sort(
      (a, b) => a.sizeGridSortOrder - b.sizeGridSortOrder,
    )
    sortedGroups.forEach((group) => {
      group.items.sort((a, b) => a.sortOrder - b.sortOrder)
    })

    groupedSizeGrid.value = sortedGroups
  }

  return {
    sizeGridNames,
    groupedSizeGrid,
    createProductSizesSortedByGroup
  }
}
